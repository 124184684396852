import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import { store } from "./store";

import routes from "./routes";
import useResizeEffect from "./hooks/useResizeEffect";

const RoutesX = () => {
    useResizeEffect();
    return (
        <Routes>
            {routes.map(({ path, component: Component }, index) => (
                <Route key={index} path={path} element={<Component />} />
            ))}
        </Routes>
    );
};

const App = () => {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <RoutesX />
                <Toaster
                    toastOptions={{
                        className: "mt-5",
                    }}
                />
            </BrowserRouter>
        </Provider>
    );
};

export default App;
