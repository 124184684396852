import { createSlice } from "@reduxjs/toolkit";
import { w2InitActiveStep, w2MaxStep } from "../../configs/w2_config";
import { federalTax, ficaMedicareTex, ficaSocialSecurityTex, stateTax } from "../../logic/texes";
import { formatLocalityName } from "../../logic/w2_logic";
import {
    getW2Step1FromLocalStorage,
    getW2Step2FromLocalStorage,
    getW2Step3FromLocalStorage,
} from "../../memo/w2_memo";
import { capitalizeWordsFirstLetter } from "../../helpers/formatters";

const {
    initYear,
    initMaritalStatus,
    initExemptions,
    initYearlyIncomeBeforeTaxes,
    initStateToBeUsedInTaxCalculations,
    initEmail,
} = getW2Step1FromLocalStorage();

const { initName, initAddress, initCity, initState, initZipCode, initEin, initStateId } =
    getW2Step2FromLocalStorage();

const {
    initEmployeeName,
    initEmployeeLastName,
    initEmployeeSSN,
    initEmployeeAddress,
    initEmployeeCity,
    initEmployeeState,
    initEmployeeZipCode,
} = getW2Step3FromLocalStorage();

const w2Slice = createSlice({
    name: "w2",
    initialState: {
        local: {
            activeStep: w2InitActiveStep,
            lastFilledStep: w2InitActiveStep - 1,
            errors: {},
            showOthersFields: false,
            lockUpdateTaxes: true,
        },
        basic: {
            year: initYear,
            maritalStatus: initMaritalStatus,
            exemptions: initExemptions,
            yearlyIncomeBeforeTaxes: initYearlyIncomeBeforeTaxes,
            stateToBeUsedInTaxCalculations: initStateToBeUsedInTaxCalculations,
            email: initEmail,
        },
        company: {
            name: capitalizeWordsFirstLetter(initName),
            address: capitalizeWordsFirstLetter(initAddress),
            city: capitalizeWordsFirstLetter(initCity),
            state: initState,
            zipCode: initZipCode,
            ein: initEin,
            stateId: initStateId,
        },
        employee: {
            name: capitalizeWordsFirstLetter(initEmployeeName),
            lastName: capitalizeWordsFirstLetter(initEmployeeLastName),
            ssn: initEmployeeSSN,
            address: capitalizeWordsFirstLetter(initEmployeeAddress),
            city: capitalizeWordsFirstLetter(initEmployeeCity),
            state: initEmployeeState,
            zipCode: initEmployeeZipCode,
        },
        earningsAndTexes: {
            controlNumber: "",
            wagesTipsOtherCompensation: initYearlyIncomeBeforeTaxes,
            socialSecurityWages: initYearlyIncomeBeforeTaxes,
            medicareWagesAndTips: initYearlyIncomeBeforeTaxes,
            stateWagesTipsEtc: initYearlyIncomeBeforeTaxes,
            federalIncomeTaxWithHeld: 0,
            socialSecurityTaxWithHeld: 0,
            medicareTaxWithHeld: 0,
            stateIncomeTax: 0,
            othersFields: {
                socialSecurityTips: 0,
                dependentCareBenefits: 0,
                sections: {
                    section12A: {
                        code: "",
                        value: 0,
                    },
                    section12B: {
                        code: "",
                        value: 0,
                    },
                    section12C: {
                        code: "",
                        value: 0,
                    },
                    section12D: {
                        code: "",
                        value: 0,
                    },
                },
                allocatedTips: 0,
                nonQualifiedPlans: 0,
                localIncomeTax: 0,
                localityName: "",
                localWagesTipEtc: 0,
                isStatutoryEmployee: false,
                isRetirementPlan: false,
                isThirdPartySickPay: false,
                other: "",
            },
        },
    },
    reducers: {
        setW2ActiveStep: (state, action) => {
            const step = Number(action.payload);
            if (1 <= step && step <= w2MaxStep) state.local.activeStep = step;
        },
        setW2LastFilledStep: (state, action) => {
            state.local.lastFilledStep = Number(action.payload);
        },
        setW2Errors: (state, action) => {
            state.local.errors = action.payload;
        },
        setLockUpdateTaxes: (state, action) => {
            state.local.lockUpdateTaxes = Boolean(action.payload);
        },
        setW2ShowOthersFields: (state, action) => {
            state.local.showOthersFields = action.payload;
        },
        setW2BasicField: (state, action) => {
            const { field, value } = action.payload;

            if (field === "yearlyIncomeBeforeTaxes") {
                state.earningsAndTexes.wagesTipsOtherCompensation = value;
                state.earningsAndTexes.socialSecurityWages = value;
                state.earningsAndTexes.medicareWagesAndTips = value;
                state.earningsAndTexes.stateWagesTipsEtc = value;
            }

            state.basic[field] = value;
        },
        setW2CompanyField: (state, action) => {
            const { field, value } = action.payload;
            if (field === "name" || field === "address" || field === "city") {
                state.company[field] = capitalizeWordsFirstLetter(value);
            } else {
                state.company[field] = value;
            }
        },
        setW2EmployeeField: (state, action) => {
            const { field, value } = action.payload;
            if (field === "name" || field === "address" || field === "city" || field === "lastName") {
                state.employee[field] = capitalizeWordsFirstLetter(value);
            } else {
                state.employee[field] = value;
            }
        },
        setW2EarningsAndTexesField: (state, action) => {
            const { field, value } = action.payload;
            state.earningsAndTexes[field] = value;
        },
        setW2EarningsAndTexesOthersFields: (state, action) => {
            const { field, value } = action.payload;

            if (field === "localityName") {
                state.earningsAndTexes.othersFields[field] = formatLocalityName(value);
                return;
            }

            state.earningsAndTexes.othersFields[field] = value;
        },
        setW2EarningsAndTexesOthersFieldsSectionField: (state, action) => {
            const { section, field, value } = action.payload;
            state.earningsAndTexes.othersFields.sections[section][field] = value;
        },
        startUp: state => {
            const maritalStatus = state.basic.maritalStatus;
            const year = state.basic.year;

            state.earningsAndTexes.federalIncomeTaxWithHeld = federalTax(
                state.earningsAndTexes.wagesTipsOtherCompensation,
                maritalStatus,
                1,
                year
            );
            state.earningsAndTexes.socialSecurityTaxWithHeld = ficaSocialSecurityTex(
                state.earningsAndTexes.socialSecurityWages
            );
            state.earningsAndTexes.medicareTaxWithHeld = ficaMedicareTex(
                state.earningsAndTexes.medicareWagesAndTips,
                maritalStatus
            );
            state.earningsAndTexes.stateIncomeTax = stateTax(
                state.earningsAndTexes.stateWagesTipsEtc,
                state.basic.stateToBeUsedInTaxCalculations,
                maritalStatus,
                1,
                year
            );
        },
    },
});

export const {
    setW2ActiveStep,
    setW2LastFilledStep,
    setW2Errors,
    setLockUpdateTaxes,
    setW2ShowOthersFields,
    setW2BasicField,
    setYear,
    setMaritalStatus,
    setW2CompanyField,
    setW2EmployeeField,
    setW2EarningsAndTexesField,
    setW2EarningsAndTexesOthersFields,
    setW2EarningsAndTexesOthersFieldsSectionField,
    startUp,
} = w2Slice.actions;

export default w2Slice;
