import { createSlice } from "@reduxjs/toolkit";
import { getBillingAddressFromLocalStorage } from "../../memo/etc";

const { initEmail, initFirstName, initLastName, initPhone } = getBillingAddressFromLocalStorage();

const globalSlice = createSlice({
    name: "global",
    initialState: {
        isDesktop: false,
        isUserLoggedIn: false,
        windowWeight: window.innerWidth,
        optionalLayoutShow: false,
        billingAddress: {
            email: initEmail,
            firstName: initFirstName,
            lastName: initLastName,
            phone: initPhone,
        },
        checkout: {
            isPrivacyPolicyAndTermsAndConditions: true,
            isSubscribeToMonthlyUpdatesPromotions: true,
            amount: 0,
            isCheckoutPageLoading: false,
        },
        coupon: {
            finalAmount: 0,
            type: "",
            name: "",
            percentage: 0,
            fixedAmount: 0,
            usageLimit: 0,
            userUsage: 0,
        },
    },
    reducers: {
        setWindowHeight: (state, action) => {
            state.windowWeight = action.payload;
        },
        setIsDesktop: (state, action) => {
            state.isDesktop = action.payload;
        },
        setIsUserLoggedIn: (state, action) => {
            state.isUserLoggedIn = action.payload;
        },
        setOptionalLayoutShow: (state, action) => {
            state.optionalLayoutShow = Boolean(action.payload);
        },
        setBillingAddress: (state, action) => {
            state.billingAddress = action.payload;
        },
        setBillingAddressField(state, action) {
            const { field, value } = action.payload;
            state.billingAddress[field] = value;
        },
        setCheckoutField(state, action) {
            const { field, value } = action.payload;
            state.checkout[field] = value;
        },
        setCouponValues: (state, action) => {
            state.coupon = action.payload;
        },
        setCouponField(state, action) {
            const { field, value } = action.payload;
            state.coupon[field] = value;
        },
        resetCouponValues: state => {
            state.coupon = {
                finalAmount: state.checkout.amount,
                type: "",
                name: "",
                percentage: 0,
                fixedAmount: 0,
                usageLimit: 0,
                userUsage: 0,
            };
        },
    },
});

export const {
    setWindowHeight,
    setIsDesktop,
    setIsUserLoggedIn,
    setOptionalLayoutShow,
    setBillingAddressField,
    setBillingAddress,
    setCheckoutField,
    setCouponValues,
    resetCouponValues,
    setCouponField,
} = globalSlice.actions;
export default globalSlice;
