import { createSlice } from "@reduxjs/toolkit";

const profileSlice = createSlice({
    name: "profile",
    initialState: {
        errors: {},
        activeProfile: "Profile Details",
        billingAddress: {
            email: "",
            firstName: "",
            lastName: "",
            address: "",
            city: "",
            state: "",
            zipCode: "",
        },
        profileDetails: {
            username: "",
            email: "",
            phoneNumber: "",
            firstName: "",
            lastName: "",
            avatarLink: "",
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
        },
        paystub: {
            page: 1,
            totalPages: 0,
            entrys: [],
        },
        w2: {
            entrys: [],
            page: 1,
            totalPages: 0,
        },
        ten99MISC: {
            entrys: [],
            page: 1,
            totalPages: 0,
        },
        ten99NEC: {
            entrys: [],
            page: 1,
            totalPages: 0,
        },
    },
    reducers: {
        setActiveProfile: (state, action) => {
            state.activeProfile = action.payload;
        },
        setPaystubPage: (state, action) => {
            state.paystub.page = action.payload;
        },
        setPaystubEntrys: (state, action) => {
            state.paystub.page = action.payload.page;
            state.paystub.totalPages = action.payload.totalPages;
            state.paystub.entrys = action.payload.items;
        },
        setW2Page: (state, action) => {
            state.w2.page = action.payload;
        },
        setW2Entrys: (state, action) => {
            state.w2.page = action.payload.page;
            state.w2.totalPages = action.payload.totalPages;
            state.w2.entrys = action.payload.items;
        },
        setTen99MISCPage: (state, action) => {
            state.ten99MISC.page = action.payload;
        },
        setTen99MISCEntrys: (state, action) => {
            state.ten99MISC.page = action.payload.page;
            state.ten99MISC.totalPages = action.payload.totalPages;
            state.ten99MISC.entrys = action.payload.items;
        },
        setTen99NECPage: (state, action) => {
            state.ten99NEC.page = action.payload;
        },
        setTen99NECEntrys: (state, action) => {
            state.ten99NEC.page = action.payload.page;
            state.ten99NEC.totalPages = action.payload.totalPages;
            state.ten99NEC.entrys = action.payload.items;
        },
        setProfileBillingAddress: (state, action) => {
            state.billingAddress = action.payload;
        },
        setProfileBillingAddressField(state, action) {
            const { field, value } = action.payload;
            state.billingAddress[field] = value;
        },
        setProfileDetails: (state, action) => {
            state.profileDetails = action.payload;
        },
        setProfileDetailsField(state, action) {
            const { field, value } = action.payload;
            state.profileDetails[field] = value;
        },
        setProfileErrorsField: (state, action) => {
            const { field, value } = action.payload;
            state.errors[field] = value;
        },
        setProfileErrors: (state, action) => {
            state.errors = action.payload;
        },
    },
});

export const {
    setActiveProfile,
    setPaystubPage,
    setPaystubEntrys,
    setW2Page,
    setW2Entrys,
    setTen99MISCPage,
    setTen99MISCEntrys,
    setTen99NECPage,
    setTen99NECEntrys,
    setProfileBillingAddress,
    setProfileBillingAddressField,
    setProfileDetails,
    setProfileDetailsField,
    setProfileErrorsField,
    setProfileErrors,
} = profileSlice.actions;
export default profileSlice;
