// https://taxfoundation.org/state-income-tax-rates-2022/

export default {
    AL: {
        single: {
            brackets: [
                {
                    limit: 500,
                    rate: 0.02,
                },
                {
                    limit: 3000,
                    rate: 0.04,
                },
                {
                    limit: Infinity,
                    rate: 0.05,
                },
            ],
            standardDeduction: 2500,
        },
        married: {
            brackets: [
                {
                    limit: 1000,
                    rate: 0.02,
                },
                {
                    limit: 6000,
                    rate: 0.04,
                },
                {
                    limit: Infinity,
                    rate: 0.05,
                },
            ],
            standardDeduction: 7500,
        },
    },
    AZ: {
        single: {
            brackets: [
                {
                    limit: 27808,
                    rate: 0.0259,
                },
                {
                    limit: 55615,
                    rate: 0.0334,
                },
                {
                    limit: 166843,
                    rate: 0.0417,
                },
                {
                    limit: Infinity,
                    rate: 0.045,
                },
            ],
            standardDeduction: 12950,
        },
        married: {
            brackets: [
                {
                    limit: 55615,
                    rate: 0.0259,
                },
                {
                    limit: 111229,
                    rate: 0.0334,
                },
                {
                    limit: 333684,
                    rate: 0.0417,
                },
                {
                    limit: Infinity,
                    rate: 0.045,
                },
            ],
            standardDeduction: 25900,
        },
    },
    AR: {
        single: {
            brackets: [
                {
                    limit: 4300,
                    rate: 0.02,
                },
                {
                    limit: 8500,
                    rate: 0.04,
                },
                {
                    limit: Infinity,
                    rate: 0.055,
                },
            ],
            standardDeduction: 2200,
        },
        married: {
            brackets: [
                {
                    limit: 4300,
                    rate: 0.02,
                },
                {
                    limit: 8500,
                    rate: 0.04,
                },
                {
                    limit: Infinity,
                    rate: 0.055,
                },
            ],
            standardDeduction: 4400,
        },
    },
    CA: {
        single: {
            brackets: [
                {
                    limit: 9325,
                    rate: 0.01,
                },
                {
                    limit: 22107,
                    rate: 0.02,
                },
                {
                    limit: 34892,
                    rate: 0.04,
                },
                {
                    limit: 48435,
                    rate: 0.06,
                },
                {
                    limit: 61214,
                    rate: 0.08,
                },
                {
                    limit: 312686,
                    rate: 0.09300000000000001,
                },
                {
                    limit: 375221,
                    rate: 0.10300000000000001,
                },
                {
                    limit: 625369,
                    rate: 0.113,
                },
                {
                    limit: 1000000,
                    rate: 0.12300000000000001,
                },
                {
                    limit: Infinity,
                    rate: 0.133,
                },
            ],
            standardDeduction: 4803,
        },
        married: {
            brackets: [
                {
                    limit: 18650,
                    rate: 0.01,
                },
                {
                    limit: 44214,
                    rate: 0.02,
                },
                {
                    limit: 69784,
                    rate: 0.04,
                },
                {
                    limit: 96870,
                    rate: 0.06,
                },
                {
                    limit: 122428,
                    rate: 0.08,
                },
                {
                    limit: 625372,
                    rate: 0.09300000000000001,
                },
                {
                    limit: 750442,
                    rate: 0.10300000000000001,
                },
                {
                    limit: 1000000,
                    rate: 0.113,
                },
                {
                    limit: 1250738,
                    rate: 0.12300000000000001,
                },
                {
                    limit: Infinity,
                    rate: 0.133,
                },
            ],
            standardDeduction: 9606,
        },
    },
    CO: {
        single: {
            brackets: [
                {
                    limit: Infinity,
                    rate: 0.0455,
                },
            ],
            standardDeduction: 12950,
        },
        married: {
            brackets: [
                {
                    limit: Infinity,
                    rate: 0.0455,
                },
            ],
            standardDeduction: 25900,
        },
    },
    CT: {
        single: {
            brackets: [
                {
                    limit: 10000,
                    rate: 0.03,
                },
                {
                    limit: 50000,
                    rate: 0.05,
                },
                {
                    limit: 100000,
                    rate: 0.055,
                },
                {
                    limit: 200000,
                    rate: 0.06,
                },
                {
                    limit: 250000,
                    rate: 0.065,
                },
                {
                    limit: 500000,
                    rate: 0.069,
                },
                {
                    limit: Infinity,
                    rate: 0.0699,
                },
            ],
            standardDeduction: 0,
        },
        married: {
            brackets: [
                {
                    limit: 20000,
                    rate: 0.03,
                },
                {
                    limit: 100000,
                    rate: 0.05,
                },
                {
                    limit: 200000,
                    rate: 0.055,
                },
                {
                    limit: 400000,
                    rate: 0.06,
                },
                {
                    limit: 500000,
                    rate: 0.065,
                },
                {
                    limit: 1000000,
                    rate: 0.069,
                },
                {
                    limit: Infinity,
                    rate: 0.0699,
                },
            ],
            standardDeduction: 0,
        },
    },
    DE: {
        single: {
            brackets: [
                {
                    limit: 5000,
                    rate: 0.022000000000000002,
                },
                {
                    limit: 10000,
                    rate: 0.039,
                },
                {
                    limit: 20000,
                    rate: 0.048,
                },
                {
                    limit: 25000,
                    rate: 0.052000000000000005,
                },
                {
                    limit: 60000,
                    rate: 0.0555,
                },
                {
                    limit: Infinity,
                    rate: 0.066,
                },
            ],
            standardDeduction: 3250,
        },
        married: {
            brackets: [
                {
                    limit: 5000,
                    rate: 0.022000000000000002,
                },
                {
                    limit: 10000,
                    rate: 0.039,
                },
                {
                    limit: 20000,
                    rate: 0.048,
                },
                {
                    limit: 25000,
                    rate: 0.052000000000000005,
                },
                {
                    limit: 60000,
                    rate: 0.0555,
                },
                {
                    limit: Infinity,
                    rate: 0.066,
                },
            ],
            standardDeduction: 6500,
        },
    },
    GA: {
        single: {
            brackets: [
                {
                    limit: 750,
                    rate: 0.01,
                },
                {
                    limit: 2250,
                    rate: 0.02,
                },
                {
                    limit: 3750,
                    rate: 0.03,
                },
                {
                    limit: 5250,
                    rate: 0.04,
                },
                {
                    limit: 7000,
                    rate: 0.05,
                },
                {
                    limit: Infinity,
                    rate: 0.0575,
                },
            ],
            standardDeduction: 4600,
        },
        married: {
            brackets: [
                {
                    limit: 1000,
                    rate: 0.01,
                },
                {
                    limit: 3000,
                    rate: 0.02,
                },
                {
                    limit: 5000,
                    rate: 0.03,
                },
                {
                    limit: 7000,
                    rate: 0.04,
                },
                {
                    limit: 10000,
                    rate: 0.05,
                },
                {
                    limit: Infinity,
                    rate: 0.0575,
                },
            ],
            standardDeduction: 6000,
        },
    },
    HI: {
        single: {
            brackets: [
                {
                    limit: 2400,
                    rate: 0.013999999999999999,
                },
                {
                    limit: 4800,
                    rate: 0.032,
                },
                {
                    limit: 9600,
                    rate: 0.055,
                },
                {
                    limit: 14400,
                    rate: 0.064,
                },
                {
                    limit: 19200,
                    rate: 0.068,
                },
                {
                    limit: 24000,
                    rate: 0.07200000000000001,
                },
                {
                    limit: 36000,
                    rate: 0.076,
                },
                {
                    limit: 48000,
                    rate: 0.079,
                },
                {
                    limit: 150000,
                    rate: 0.0825,
                },
                {
                    limit: 175000,
                    rate: 0.09,
                },
                {
                    limit: 200000,
                    rate: 0.1,
                },
                {
                    limit: Infinity,
                    rate: 0.11,
                },
            ],
            standardDeduction: 2200,
        },
        married: {
            brackets: [
                {
                    limit: 4800,
                    rate: 0.013999999999999999,
                },
                {
                    limit: 9600,
                    rate: 0.032,
                },
                {
                    limit: 19200,
                    rate: 0.055,
                },
                {
                    limit: 28800,
                    rate: 0.064,
                },
                {
                    limit: 38400,
                    rate: 0.068,
                },
                {
                    limit: 48000,
                    rate: 0.07200000000000001,
                },
                {
                    limit: 72000,
                    rate: 0.076,
                },
                {
                    limit: 96000,
                    rate: 0.079,
                },
                {
                    limit: 300000,
                    rate: 0.0825,
                },
                {
                    limit: 350000,
                    rate: 0.09,
                },
                {
                    limit: 400000,
                    rate: 0.1,
                },
                {
                    limit: Infinity,
                    rate: 0.11,
                },
            ],
            standardDeduction: 4400,
        },
    },
    ID: {
        single: {
            brackets: [
                {
                    limit: 1588,
                    rate: 0.01,
                },
                {
                    limit: 4763,
                    rate: 0.03,
                },
                {
                    limit: 7939,
                    rate: 0.045,
                },
                {
                    limit: Infinity,
                    rate: 0.06,
                },
            ],
            standardDeduction: 12950,
        },
        married: {
            brackets: [
                {
                    limit: 3176,
                    rate: 0.01,
                },
                {
                    limit: 9526,
                    rate: 0.03,
                },
                {
                    limit: 15878,
                    rate: 0.045,
                },
                {
                    limit: Infinity,
                    rate: 0.06,
                },
            ],
            standardDeduction: 25900,
        },
    },
    IL: {
        single: {
            brackets: [
                {
                    limit: Infinity,
                    rate: 0.0495,
                },
            ],
            standardDeduction: 0,
        },
        married: {
            brackets: [
                {
                    limit: Infinity,
                    rate: 0.0495,
                },
            ],
            standardDeduction: 0,
        },
    },
    IN: {
        single: {
            brackets: [
                {
                    limit: Infinity,
                    rate: 0.0323,
                },
            ],
            standardDeduction: 0,
        },
        married: {
            brackets: [
                {
                    limit: Infinity,
                    rate: 0.0323,
                },
            ],
            standardDeduction: 0,
        },
    },
    IA: {
        single: {
            brackets: [
                {
                    limit: 1743,
                    rate: 0.0033,
                },
                {
                    limit: 3486,
                    rate: 0.0067,
                },
                {
                    limit: 6972,
                    rate: 0.0225,
                },
                {
                    limit: 15687,
                    rate: 0.0414,
                },
                {
                    limit: 26145,
                    rate: 0.056299999999999996,
                },
                {
                    limit: 34860,
                    rate: 0.0596,
                },
                {
                    limit: 52290,
                    rate: 0.0625,
                },
                {
                    limit: 78435,
                    rate: 0.07440000000000001,
                },
                {
                    limit: Infinity,
                    rate: 0.08529999999999999,
                },
            ],
            standardDeduction: 2210,
        },
        married: {
            brackets: [
                {
                    limit: 1743,
                    rate: 0.0033,
                },
                {
                    limit: 3486,
                    rate: 0.0067,
                },
                {
                    limit: 6972,
                    rate: 0.0225,
                },
                {
                    limit: 15687,
                    rate: 0.0414,
                },
                {
                    limit: 26145,
                    rate: 0.056299999999999996,
                },
                {
                    limit: 34860,
                    rate: 0.0596,
                },
                {
                    limit: 52290,
                    rate: 0.0625,
                },
                {
                    limit: 78435,
                    rate: 0.07440000000000001,
                },
                {
                    limit: Infinity,
                    rate: 0.08529999999999999,
                },
            ],
            standardDeduction: 5450,
        },
    },
    KS: {
        single: {
            brackets: [
                {
                    limit: 15000,
                    rate: 0.031,
                },
                {
                    limit: 30000,
                    rate: 0.0525,
                },
                {
                    limit: Infinity,
                    rate: 0.057,
                },
            ],
            standardDeduction: 3500,
        },
        married: {
            brackets: [
                {
                    limit: 30000,
                    rate: 0.031,
                },
                {
                    limit: 60000,
                    rate: 0.0525,
                },
                {
                    limit: Infinity,
                    rate: 0.057,
                },
            ],
            standardDeduction: 8000,
        },
    },
    KY: {
        single: {
            brackets: [
                {
                    limit: Infinity,
                    rate: 0.05,
                },
                {
                    limit: Infinity,
                    rate: null,
                },
            ],
            standardDeduction: 2770,
        },
        married: {
            brackets: [
                {
                    limit: Infinity,
                    rate: 0.05,
                },
                {
                    limit: Infinity,
                    rate: null,
                },
            ],
            standardDeduction: 5540,
        },
    },
    LA: {
        single: {
            brackets: [
                {
                    limit: 12500,
                    rate: 0.018500000000000003,
                },
                {
                    limit: 50000,
                    rate: 0.035,
                },
                {
                    limit: Infinity,
                    rate: 0.0425,
                },
            ],
            standardDeduction: 0,
        },
        married: {
            brackets: [
                {
                    limit: 25000,
                    rate: 0.018500000000000003,
                },
                {
                    limit: 100000,
                    rate: 0.035,
                },
                {
                    limit: Infinity,
                    rate: 0.0425,
                },
            ],
            standardDeduction: 0,
        },
    },
    ME: {
        single: {
            brackets: [
                {
                    limit: 23000,
                    rate: 0.057999999999999996,
                },
                {
                    limit: 54450,
                    rate: 0.0675,
                },
                {
                    limit: Infinity,
                    rate: 0.07150000000000001,
                },
            ],
            standardDeduction: 12950,
        },
        married: {
            brackets: [
                {
                    limit: 46000,
                    rate: 0.057999999999999996,
                },
                {
                    limit: 108900,
                    rate: 0.0675,
                },
                {
                    limit: Infinity,
                    rate: 0.07150000000000001,
                },
            ],
            standardDeduction: 25900,
        },
    },
    MD: {
        single: {
            brackets: [
                {
                    limit: 1000,
                    rate: 0.02,
                },
                {
                    limit: 2000,
                    rate: 0.03,
                },
                {
                    limit: 3000,
                    rate: 0.04,
                },
                {
                    limit: 100000,
                    rate: 0.0475,
                },
                {
                    limit: 125000,
                    rate: 0.05,
                },
                {
                    limit: 150000,
                    rate: 0.0525,
                },
                {
                    limit: 250000,
                    rate: 0.055,
                },
                {
                    limit: Infinity,
                    rate: 0.0575,
                },
            ],
            standardDeduction: 2350,
        },
        married: {
            brackets: [
                {
                    limit: 1000,
                    rate: 0.02,
                },
                {
                    limit: 2000,
                    rate: 0.03,
                },
                {
                    limit: 3000,
                    rate: 0.04,
                },
                {
                    limit: 150000,
                    rate: 0.0475,
                },
                {
                    limit: 175000,
                    rate: 0.05,
                },
                {
                    limit: 225000,
                    rate: 0.0525,
                },
                {
                    limit: 300000,
                    rate: 0.055,
                },
                {
                    limit: Infinity,
                    rate: 0.0575,
                },
            ],
            standardDeduction: 4700,
        },
    },
    MA: {
        single: {
            brackets: [
                {
                    limit: Infinity,
                    rate: 0.05,
                },
            ],
            standardDeduction: 0,
        },
        married: {
            brackets: [
                {
                    limit: Infinity,
                    rate: 0.05,
                },
            ],
            standardDeduction: 0,
        },
    },
    MI: {
        single: {
            brackets: [
                {
                    limit: Infinity,
                    rate: 0.0425,
                },
            ],
            standardDeduction: 0,
        },
        married: {
            brackets: [
                {
                    limit: Infinity,
                    rate: 0.0425,
                },
            ],
            standardDeduction: 0,
        },
    },
    MN: {
        single: {
            brackets: [
                {
                    limit: 28080,
                    rate: 0.0535,
                },
                {
                    limit: 92230,
                    rate: 0.068,
                },
                {
                    limit: 171220,
                    rate: 0.0785,
                },
                {
                    limit: Infinity,
                    rate: 0.09849999999999999,
                },
            ],
            standardDeduction: 12900,
        },
        married: {
            brackets: [
                {
                    limit: 41050,
                    rate: 0.0535,
                },
                {
                    limit: 163060,
                    rate: 0.068,
                },
                {
                    limit: 284810,
                    rate: 0.0785,
                },
                {
                    limit: Infinity,
                    rate: 0.09849999999999999,
                },
            ],
            standardDeduction: 25800,
        },
    },
    MS: {
        single: {
            brackets: [
                {
                    rate: 0,
                    limit: 5000,
                },
                {
                    limit: 10000,
                    rate: 0.04,
                },
                {
                    limit: Infinity,
                    rate: 0.05,
                },
            ],
            standardDeduction: 2300,
        },
        married: {
            brackets: [
                {
                    rate: 0,
                    limit: 5000,
                },
                {
                    limit: 10000,
                    rate: 0.04,
                },
                {
                    limit: Infinity,
                    rate: 0.05,
                },
            ],
            standardDeduction: 4600,
        },
    },
    MO: {
        single: {
            brackets: [
                {
                    limit: 1088,
                    rate: 0.015,
                },
                {
                    limit: 2176,
                    rate: 0.02,
                },
                {
                    limit: 3264,
                    rate: 0.025,
                },
                {
                    limit: 4352,
                    rate: 0.03,
                },
                {
                    limit: 5440,
                    rate: 0.035,
                },
                {
                    limit: 6528,
                    rate: 0.04,
                },
                {
                    limit: 7616,
                    rate: 0.045,
                },
                {
                    limit: 8704,
                    rate: 0.05,
                },
                {
                    limit: Infinity,
                    rate: 0.054000000000000006,
                },
            ],
            standardDeduction: 12950,
        },
        married: {
            brackets: [
                {
                    limit: 1088,
                    rate: 0.015,
                },
                {
                    limit: 2176,
                    rate: 0.02,
                },
                {
                    limit: 3264,
                    rate: 0.025,
                },
                {
                    limit: 4352,
                    rate: 0.03,
                },
                {
                    limit: 5440,
                    rate: 0.035,
                },
                {
                    limit: 6528,
                    rate: 0.04,
                },
                {
                    limit: 7616,
                    rate: 0.045,
                },
                {
                    limit: 8704,
                    rate: 0.05,
                },
                {
                    limit: Infinity,
                    rate: 0.054000000000000006,
                },
            ],
            standardDeduction: 25900,
        },
    },
    MT: {
        single: {
            brackets: [
                {
                    limit: 3100,
                    rate: 0.01,
                },
                {
                    limit: 5500,
                    rate: 0.02,
                },
                {
                    limit: 8400,
                    rate: 0.03,
                },
                {
                    limit: 11400,
                    rate: 0.04,
                },
                {
                    limit: 14600,
                    rate: 0.05,
                },
                {
                    limit: 18800,
                    rate: 0.06,
                },
                {
                    limit: Infinity,
                    rate: 0.0675,
                },
            ],
            standardDeduction: 4830,
        },
        married: {
            brackets: [
                {
                    limit: 3100,
                    rate: 0.01,
                },
                {
                    limit: 5500,
                    rate: 0.02,
                },
                {
                    limit: 8400,
                    rate: 0.03,
                },
                {
                    limit: 11400,
                    rate: 0.04,
                },
                {
                    limit: 14600,
                    rate: 0.05,
                },
                {
                    limit: 18800,
                    rate: 0.06,
                },
                {
                    limit: Infinity,
                    rate: 0.0675,
                },
            ],
            standardDeduction: 9660,
        },
    },
    NE: {
        single: {
            brackets: [
                {
                    limit: 3440,
                    rate: 0.0246,
                },
                {
                    limit: 20590,
                    rate: 0.0351,
                },
                {
                    limit: 33180,
                    rate: 0.0501,
                },
                {
                    limit: Infinity,
                    rate: 0.0684,
                },
            ],
            standardDeduction: 7350,
        },
        married: {
            brackets: [
                {
                    limit: 6860,
                    rate: 0.0246,
                },
                {
                    limit: 41190,
                    rate: 0.0351,
                },
                {
                    limit: 66360,
                    rate: 0.0501,
                },
                {
                    limit: Infinity,
                    rate: 0.0684,
                },
            ],
            standardDeduction: 14700,
        },
    },
    NH: {
        single: {
            brackets: [
                {
                    limit: Infinity,
                    rate: 0.05,
                },
            ],
            standardDeduction: 0,
        },
        married: {
            brackets: [
                {
                    limit: Infinity,
                    rate: null,
                },
            ],
            standardDeduction: 0,
        },
    },
    NJ: {
        single: {
            brackets: [
                {
                    limit: 20000,
                    rate: 0.013999999999999999,
                },
                {
                    limit: 35000,
                    rate: 0.0175,
                },
                {
                    limit: 40000,
                    rate: 0.035,
                },
                {
                    limit: 75000,
                    rate: 0.05525,
                },
                {
                    limit: 500000,
                    rate: 0.0637,
                },
                {
                    limit: 1000000,
                    rate: 0.0897,
                },
                {
                    limit: Infinity,
                    rate: 0.1075,
                },
                {
                    limit: Infinity,
                    rate: null,
                },
            ],
            standardDeduction: 0,
        },
        married: {
            brackets: [
                {
                    limit: 20000,
                    rate: 0.013999999999999999,
                },
                {
                    limit: 50000,
                    rate: 0.0175,
                },
                {
                    limit: 70000,
                    rate: 0.0245,
                },
                {
                    limit: 80000,
                    rate: 0.035,
                },
                {
                    limit: 150000,
                    rate: 0.05525,
                },
                {
                    limit: 500000,
                    rate: 0.0637,
                },
                {
                    limit: 1000000,
                    rate: 0.0897,
                },
                {
                    limit: Infinity,
                    rate: 0.1075,
                },
            ],
            standardDeduction: 0,
        },
    },
    NM: {
        single: {
            brackets: [
                {
                    limit: 5500,
                    rate: 0.017,
                },
                {
                    limit: 11000,
                    rate: 0.032,
                },
                {
                    limit: 16000,
                    rate: 0.047,
                },
                {
                    limit: 210000,
                    rate: 0.049,
                },
                {
                    limit: Infinity,
                    rate: 0.059000000000000004,
                },
            ],
            standardDeduction: 12950,
        },
        married: {
            brackets: [
                {
                    limit: 8000,
                    rate: 0.017,
                },
                {
                    limit: 16000,
                    rate: 0.032,
                },
                {
                    limit: 24000,
                    rate: 0.047,
                },
                {
                    limit: 315000,
                    rate: 0.049,
                },
                {
                    limit: Infinity,
                    rate: 0.059000000000000004,
                },
            ],
            standardDeduction: 25900,
        },
    },
    NY: {
        single: {
            brackets: [
                {
                    limit: 8500,
                    rate: 0.04,
                },
                {
                    limit: 11700,
                    rate: 0.045,
                },
                {
                    limit: 13900,
                    rate: 0.0525,
                },
                {
                    limit: 80650,
                    rate: 0.058499999999999996,
                },
                {
                    limit: 215400,
                    rate: 0.0625,
                },
                {
                    limit: 1077550,
                    rate: 0.06849999999999999,
                },
                {
                    limit: 5000000,
                    rate: 0.0965,
                },
                {
                    limit: 25000000,
                    rate: 0.10300000000000001,
                },
                {
                    limit: Infinity,
                    rate: 0.109,
                },
            ],
            standardDeduction: 8000,
        },
        married: {
            brackets: [
                {
                    limit: 17150,
                    rate: 0.04,
                },
                {
                    limit: 23600,
                    rate: 0.045,
                },
                {
                    limit: 27900,
                    rate: 0.0525,
                },
                {
                    limit: 161550,
                    rate: 0.058499999999999996,
                },
                {
                    limit: 323200,
                    rate: 0.0625,
                },
                {
                    limit: 2155350,
                    rate: 0.06849999999999999,
                },
                {
                    limit: 5000000,
                    rate: 0.0965,
                },
                {
                    limit: 25000000,
                    rate: 0.10300000000000001,
                },
                {
                    limit: Infinity,
                    rate: 0.109,
                },
            ],
            standardDeduction: 16050,
        },
    },
    NC: {
        single: {
            brackets: [
                {
                    limit: Infinity,
                    rate: 0.0499,
                },
            ],
            standardDeduction: 12750,
        },
        married: {
            brackets: [
                {
                    limit: Infinity,
                    rate: 0.0499,
                },
            ],
            standardDeduction: 25500,
        },
    },
    ND: {
        single: {
            brackets: [
                {
                    limit: 40525,
                    rate: 0.011000000000000001,
                },
                {
                    limit: 98100,
                    rate: 0.0204,
                },
                {
                    limit: 204675,
                    rate: 0.0227,
                },
                {
                    limit: 445000,
                    rate: 0.0264,
                },
                {
                    limit: Infinity,
                    rate: 0.028999999999999998,
                },
            ],
            standardDeduction: 12950,
        },
        married: {
            brackets: [
                {
                    limit: 67700,
                    rate: 0.011000000000000001,
                },
                {
                    limit: 163550,
                    rate: 0.0204,
                },
                {
                    limit: 249150,
                    rate: 0.0227,
                },
                {
                    limit: 445000,
                    rate: 0.0264,
                },
                {
                    limit: Infinity,
                    rate: 0.028999999999999998,
                },
            ],
            standardDeduction: 25900,
        },
    },
    OH: {
        single: {
            brackets: [
                {
                    limit: 44250,
                    rate: 0.02765,
                },
                {
                    limit: 88450,
                    rate: 0.03226,
                },
                {
                    limit: 110650,
                    rate: 0.03688,
                },
                {
                    limit: Infinity,
                    rate: 0.039900000000000005,
                },
            ],
            standardDeduction: 0,
        },
        married: {
            brackets: [
                {
                    limit: 44250,
                    rate: 0.02765,
                },
                {
                    limit: 88450,
                    rate: 0.03226,
                },
                {
                    limit: 110650,
                    rate: 0.03688,
                },
                {
                    limit: Infinity,
                    rate: 0.039900000000000005,
                },
            ],
            standardDeduction: 0,
        },
    },
    OK: {
        single: {
            brackets: [
                {
                    limit: 1000,
                    rate: 0.0025,
                },
                {
                    limit: 2500,
                    rate: 0.0075,
                },
                {
                    limit: 3750,
                    rate: 0.0175,
                },
                {
                    limit: 4900,
                    rate: 0.0275,
                },
                {
                    limit: 7200,
                    rate: 0.0375,
                },
                {
                    limit: Infinity,
                    rate: 0.0475,
                },
            ],
            standardDeduction: 6350,
        },
        married: {
            brackets: [
                {
                    limit: 2000,
                    rate: 0.0025,
                },
                {
                    limit: 5000,
                    rate: 0.0075,
                },
                {
                    limit: 7500,
                    rate: 0.0175,
                },
                {
                    limit: 9800,
                    rate: 0.0275,
                },
                {
                    limit: 12200,
                    rate: 0.0375,
                },
                {
                    limit: Infinity,
                    rate: 0.0475,
                },
            ],
            standardDeduction: 12700,
        },
    },
    OR: {
        single: {
            brackets: [
                {
                    limit: 3650,
                    rate: 0.0475,
                },
                {
                    limit: 9200,
                    rate: 0.0675,
                },
                {
                    limit: 125000,
                    rate: 0.0875,
                },
                {
                    limit: Infinity,
                    rate: 0.099,
                },
            ],
            standardDeduction: 2420,
        },
        married: {
            brackets: [
                {
                    limit: 7300,
                    rate: 0.0475,
                },
                {
                    limit: 18400,
                    rate: 0.0675,
                },
                {
                    limit: 250000,
                    rate: 0.0875,
                },
                {
                    limit: Infinity,
                    rate: 0.099,
                },
            ],
            standardDeduction: 4840,
        },
    },
    PA: {
        single: {
            brackets: [
                {
                    limit: Infinity,
                    rate: 0.030699999999999998,
                },
            ],
            standardDeduction: 0,
        },
        married: {
            brackets: [
                {
                    limit: Infinity,
                    rate: 0.030699999999999998,
                },
            ],
            standardDeduction: 0,
        },
    },
    RI: {
        single: {
            brackets: [
                {
                    limit: 68200,
                    rate: 0.0375,
                },
                {
                    limit: 155050,
                    rate: 0.0475,
                },
                {
                    limit: Infinity,
                    rate: 0.0599,
                },
            ],
            standardDeduction: 9300,
        },
        married: {
            brackets: [
                {
                    limit: 68200,
                    rate: 0.0375,
                },
                {
                    limit: 155050,
                    rate: 0.0475,
                },
                {
                    limit: Infinity,
                    rate: 0.0599,
                },
            ],
            standardDeduction: 18600,
        },
    },
    SC: {
        single: {
            brackets: [
                {
                    limit: 3200,
                    rate: 0,
                },
                {
                    limit: 6410,
                    rate: 0.03,
                },
                {
                    limit: 9620,
                    rate: 0.04,
                },
                {
                    limit: 12820,
                    rate: 0.05,
                },
                {
                    limit: 16040,
                    rate: 0.06,
                },
                {
                    limit: Infinity,
                    rate: 0.07,
                },
            ],
            standardDeduction: 12950,
        },
        married: {
            brackets: [
                {
                    limit: 3200,
                    rate: 0,
                },
                {
                    limit: 6410,
                    rate: 0.03,
                },
                {
                    limit: 9620,
                    rate: 0.04,
                },
                {
                    limit: 12820,
                    rate: 0.05,
                },
                {
                    limit: 16040,
                    rate: 0.06,
                },
                {
                    limit: Infinity,
                    rate: 0.07,
                },
            ],
            standardDeduction: 25900,
        },
    },
    UT: {
        single: {
            brackets: [
                {
                    limit: Infinity,
                    rate: 0.0495,
                },
            ],
            standardDeduction: 777,
        },
        married: {
            brackets: [
                {
                    limit: Infinity,
                    rate: 0.0495,
                },
            ],
            standardDeduction: 1554,
        },
    },
    VT: {
        single: {
            brackets: [
                {
                    limit: 40950,
                    rate: 0.0335,
                },
                {
                    limit: 99200,
                    rate: 0.066,
                },
                {
                    limit: 206950,
                    rate: 0.076,
                },
                {
                    limit: Infinity,
                    rate: 0.0875,
                },
            ],
            standardDeduction: 6350,
        },
        married: {
            brackets: [
                {
                    limit: 68400,
                    rate: 0.0335,
                },
                {
                    limit: 165350,
                    rate: 0.066,
                },
                {
                    limit: 251950,
                    rate: 0.076,
                },
                {
                    limit: Infinity,
                    rate: 0.0875,
                },
            ],
            standardDeduction: 12700,
        },
    },
    VA: {
        single: {
            brackets: [
                {
                    limit: 3000,
                    rate: 0.02,
                },
                {
                    limit: 5000,
                    rate: 0.03,
                },
                {
                    limit: 17000,
                    rate: 0.05,
                },
                {
                    limit: Infinity,
                    rate: 0.0575,
                },
            ],
            standardDeduction: 4500,
        },
        married: {
            brackets: [
                {
                    limit: 3000,
                    rate: 0.02,
                },
                {
                    limit: 5000,
                    rate: 0.03,
                },
                {
                    limit: 17000,
                    rate: 0.05,
                },
                {
                    limit: Infinity,
                    rate: 0.0575,
                },
            ],
            standardDeduction: 9000,
        },
    },
    WA: {
        single: {
            brackets: [
                {
                    limit: Infinity,
                    rate: 0.07,
                },
            ],
            standardDeduction: 0,
        },
        married: {
            brackets: [
                {
                    limit: Infinity,
                    rate: null,
                },
            ],
            standardDeduction: 0,
        },
    },
    WV: {
        single: {
            brackets: [
                {
                    limit: 10000,
                    rate: 0.03,
                },
                {
                    limit: 25000,
                    rate: 0.04,
                },
                {
                    limit: 40000,
                    rate: 0.045,
                },
                {
                    limit: 60000,
                    rate: 0.06,
                },
                {
                    limit: Infinity,
                    rate: 0.065,
                },
            ],
            standardDeduction: 0,
        },
        married: {
            brackets: [
                {
                    limit: 10000,
                    rate: 0.03,
                },
                {
                    limit: 25000,
                    rate: 0.04,
                },
                {
                    limit: 40000,
                    rate: 0.045,
                },
                {
                    limit: 60000,
                    rate: 0.06,
                },
                {
                    limit: Infinity,
                    rate: 0.065,
                },
            ],
            standardDeduction: 0,
        },
    },
    WI: {
        single: {
            brackets: [
                {
                    limit: 12760,
                    rate: 0.0354,
                },
                {
                    limit: 25520,
                    rate: 0.04650000000000001,
                },
                {
                    limit: 280950,
                    rate: 0.053,
                },
                {
                    limit: Infinity,
                    rate: 0.0765,
                },
            ],
            standardDeduction: 11790,
        },
        married: {
            brackets: [
                {
                    limit: 17010,
                    rate: 0.0354,
                },
                {
                    limit: 34030,
                    rate: 0.04650000000000001,
                },
                {
                    limit: 374600,
                    rate: 0.053,
                },
                {
                    limit: Infinity,
                    rate: 0.0765,
                },
            ],
            standardDeduction: 21820,
        },
    },
    "D.C.": {
        single: {
            brackets: [
                { limit: 10000, rate: 0.04 },
                { limit: 40000, rate: 0.06 },
                { limit: 60000, rate: 0.065 },
                { limit: 250000, rate: 0.085 },
                { limit: 500000, rate: 0.0925 },
                { limit: 1000000, rate: 0.0975 },
                { limit: Infinity, rate: 0.1075 },
            ],
            standardDeduction: 12950,
        },
        married: {
            brackets: [
                { limit: 10000, rate: 0.04 },
                { limit: 40000, rate: 0.06 },
                { limit: 60000, rate: 0.065 },
                { limit: 250000, rate: 0.085 },
                { limit: 500000, rate: 0.0925 },
                { limit: 1000000, rate: 0.0975 },
                { limit: Infinity, rate: 0.1075 },
            ],
            standardDeduction: 25900,
        },
    },
    WY: {
        single: {
            brackets: [{ limit: Infinity, rate: 0 }],
            standardDeduction: 0,
        },
        married: {
            brackets: [{ limit: Infinity, rate: 0 }],
            standardDeduction: 0,
        },
    },
};
