import { PAYSTUB_CONTEXT } from "../configs/consts";

import storage from "../helpers/storage";
import tracker, { viewedProductTrack } from "../helpers/tracker";

const PAYSTUB_STEP1_TEMPLATE_SELECTION_KEY = "paystub_generate_step1_template_selection_v4";
const PAYSTUB_STEP2_PAYSTUB_DETAILS_KEY = "paystub_generate_step2_paystub_details_v2";
export const PAYSTUB_STEP3_COMPANY_INFORMATION_KEY = "paystub_generate_step3_company_information_v2";
export const PAYSTUB_STEP4_EMPLOYEE_INFORMATION_KEY = "paystub_generate_step4_employee_information_v2";
export const PAYSTUB_STEP5_SALARY_INFO_KEY = "paystub_generate_step5_salary_info_v2";

const setStep1ToLocalStorage = (salary, company) => {
    const data = {
        template: salary.template,
        companyLogoUrl: company.companyLogoUrl || "",
    };
    storage.set(PAYSTUB_STEP1_TEMPLATE_SELECTION_KEY, data);
};

const setStep2ToLocalStorage = salary => {
    tracker.identify(salary?.emailAddress);
    viewedProductTrack(PAYSTUB_CONTEXT);

    const data = {
        employmentType: salary.employmentType,
        emailAddress: salary?.emailAddress,
        numberOfPaystubsRequired: salary?.numberOfPaystubsRequired,
        stateToBeUsedInTaxCalculations: salary?.stateToBeUsedInTaxCalculations,
    };
    storage.set(PAYSTUB_STEP2_PAYSTUB_DETAILS_KEY, data);
};

const setStep5ToLocalStorage = (salary, paystub) => {
    const data = {
        paymentFrequency: salary.paymentFrequency,
        paymentType: paystub.earnings[0]?.paymentType,
        payPeriodStart: paystub.payPeriodStart,
    };
    storage.set(PAYSTUB_STEP5_SALARY_INFO_KEY, data);
};

const setStep3ToLocalStorage = company => {
    storage.set(PAYSTUB_STEP3_COMPANY_INFORMATION_KEY, company);
};

const setStep4ToLocalStorage = employee => {
    tracker.addCustomProperties({
        $first_name: employee.employeeName,
        $city: employee.employeeCity,
        $region: employee.employeeState,
        $zip: employee.employeeZipCode,
    });

    storage.set(PAYSTUB_STEP4_EMPLOYEE_INFORMATION_KEY, employee);
};

export const getStep1ToLocalStorage = () => {
    const { template, companyLogoUrl } = storage.get(PAYSTUB_STEP1_TEMPLATE_SELECTION_KEY) || {};
    return {
        iniTemplate: template || 1,
        initCompanyLogoUrl: companyLogoUrl || "",
    };
};

export const getStep2ToLocalStorage = () => {
    const { employmentType, emailAddress, numberOfPaystubsRequired, stateToBeUsedInTaxCalculations } =
        storage.get(PAYSTUB_STEP2_PAYSTUB_DETAILS_KEY) || {};
    return {
        initEmploymentType: employmentType || "employee",
        initEmailAddress: emailAddress || "",
        initNumberOfPaystubsRequired: numberOfPaystubsRequired || 1,
        initStateToBeUsedInTaxCalculations: stateToBeUsedInTaxCalculations || "",
    };
};

export const storePaystubActiveStepToLocalStorage = (step, generatePaystub) => {
    switch (step) {
        case 1:
            return setStep1ToLocalStorage(generatePaystub.salary, generatePaystub.company);
        case 2:
            return setStep2ToLocalStorage(generatePaystub.salary);
        case 3:
            return setStep3ToLocalStorage(generatePaystub.company);
        case 4:
            return setStep4ToLocalStorage(generatePaystub.employee);
        case 5:
            return setStep5ToLocalStorage(generatePaystub.salary, generatePaystub.paystubs[0]);
        case 6:
            tracker.startedCheckoutTrack(PAYSTUB_CONTEXT);
            break;
        default:
            break;
    }
};
