import { createSlice } from "@reduxjs/toolkit";
import { ten99NECInitActiveStep, ten99NECMaxStep } from "../../configs/ten99_nec_config";
import {
    getTen99NECStep1Payer,
    getTen99NECStep2Recipient,
    getTen99NECStep3FillingInformation,
    getTen99NECStep4StateIncomeAndTax,
} from "../../memo/ten99_nec_memo";
import { capitalizeWordsFirstLetter } from "../../helpers/formatters";

const { initName, initAddress, initCity, initState, initZipCode, initPayersTIN } = getTen99NECStep1Payer();
const {
    initRecipientName,
    initRecipientAddress,
    initRecipientCity,
    initRecipientState,
    initRecipientZipCode,
    initRecipientsTIN,
} = getTen99NECStep2Recipient();

const {
    initYear,
    initAccountNumber,
    initSecondTINNot,
    initNonEmployeeCompensation,
    initFederalIncomeTaxWithheld,
    isDirectSalesOrResaleSales,
} = getTen99NECStep3FillingInformation();

const {
    initState1State,
    initState1PayersStateNo,
    initState1StateTaxWithheld,
    initState1StateCome,
    initState2State,
    initState2PayersStateNo,
    initState2StateTaxWithheld,
    initState2StateCome,
} = getTen99NECStep4StateIncomeAndTax();

const ten99NECSlice = createSlice({
    name: "ten99NEC",
    initialState: {
        local: {
            activeStep: ten99NECInitActiveStep,
            lastFilledStep: ten99NECInitActiveStep - 1,
            errors: {},
        },
        payer: {
            name: capitalizeWordsFirstLetter(initName),
            address: capitalizeWordsFirstLetter(initAddress),
            city: capitalizeWordsFirstLetter(initCity),
            state: initState,
            zipCode: initZipCode,
            payersTIN: initPayersTIN,
        },
        recipient: {
            name: capitalizeWordsFirstLetter(initRecipientName),
            address: capitalizeWordsFirstLetter(initRecipientAddress),
            city: capitalizeWordsFirstLetter(initRecipientCity),
            state: initRecipientState,
            zipCode: initRecipientZipCode,
            recipientsTIN: initRecipientsTIN,
        },
        filingInformation: {
            year: initYear,
            accountNumber: initAccountNumber,
            secondTINNot: initSecondTINNot,
            nonEmployeeCompensation: initNonEmployeeCompensation,
            isDirectSalesOrResaleSales: isDirectSalesOrResaleSales,
            federalIncomeTaxWithheld: initFederalIncomeTaxWithheld,
        },
        stateIncomeAndTax: {
            state1: {
                state: initState1State,
                payersStateNo: initState1PayersStateNo,
                stateTaxWithheld: initState1StateTaxWithheld,
                stateIncome: initState1StateCome,
            },
            state2: {
                state: initState2State,
                payersStateNo: initState2PayersStateNo,
                stateTaxWithheld: initState2StateTaxWithheld,
                stateIncome: initState2StateCome,
            },
        },
    },
    reducers: {
        setTen99NECActiveStep: (state, action) => {
            const step = Number(action.payload);
            if (1 <= step && step <= ten99NECMaxStep) state.local.activeStep = step;
        },
        setTen99NECLastFilledStep: (state, action) => {
            state.local.lastFilledStep = Number(action.payload);
        },
        setTen99NECErrors: (state, action) => {
            state.local.errors = action.payload;
        },
        setTen99NECPayerField: (state, action) => {
            const { field, value } = action.payload;
            if (field === "name" || field === "address" || field === "city") {
                state.payer[field] = capitalizeWordsFirstLetter(value);
            } else {
                state.payer[field] = value;
            }
        },
        setTen99NECRecipientField: (state, action) => {
            const { field, value } = action.payload;
            if (field === "name" || field === "address" || field === "city") {
                state.recipient[field] = capitalizeWordsFirstLetter(value);
            } else {
                state.recipient[field] = value;
            }
        },
        setTen99NECFilingInformationField: (state, action) => {
            const { field, value } = action.payload;
            state.filingInformation[field] = value;
        },
        setTen99NECStateIncomeAndTaxState1: (state, action) => {
            const { field, value } = action.payload;
            state.stateIncomeAndTax.state1[field] = value;
        },
        setTen99NECStateIncomeAndTaxState2: (state, action) => {
            const { field, value } = action.payload;
            state.stateIncomeAndTax.state2[field] = value;
        },
    },
});

export const {
    setTen99NECActiveStep,
    setTen99NECLastFilledStep,
    setTen99NECErrors,
    setTen99NECPayerField,
    setTen99NECRecipientField,
    setTen99NECFilingInformationField,
    setTen99NECStateIncomeAndTaxState1,
    setTen99NECStateIncomeAndTaxState2,
} = ten99NECSlice.actions;

export default ten99NECSlice;
