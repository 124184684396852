import { TEN99_MISC_CONTEXT } from "../configs/consts";

import storage from "../helpers/storage";
import tracker from "../helpers/tracker";

const TEN99_MISC_STEP_1_BASIC = "ten99_misc_step1_basic_information";
const TEN99_MISC_STEP_2_PAYER = "ten99_misc_step2_payer";
const TEN99_MISC_STEP_3_RECIPIENT = "ten99_misc_step3_recipient";
const TEN99_MISC_STEP_4_PAYMENTS_AND_TAXES = "ten99_misc_step4_payments_and_taxes";
const TEN99_MISC_STEP_5_STATE_INCOME_AND_TAX = "ten99_misc_step5_state_income_and_tax";

const storeTen99MISCStep1ToLocalStorage = basic => {
    storage.set(TEN99_MISC_STEP_1_BASIC, basic);
};

const storeTen99MISCStep2ToLocalStorage = payer => {
    storage.set(TEN99_MISC_STEP_2_PAYER, payer);
};

const storeTen99MISCStep3ToLocalStorage = recipient => {
    storage.set(TEN99_MISC_STEP_3_RECIPIENT, recipient);
};

const storeTen99MISCStep4ToLocalStorage = paymentsAndTaxes => {
    storage.set(TEN99_MISC_STEP_4_PAYMENTS_AND_TAXES, paymentsAndTaxes);
};

const storeTen99MISCStep5ToLocalStorage = stateIncomeAndTax => {
    storage.set(TEN99_MISC_STEP_5_STATE_INCOME_AND_TAX, stateIncomeAndTax);
};

export const getTen99MISCStep1FromLocalStorage = () => {
    const { year, email, yearlyIncomeBeforeTaxes, accountNumber, isFATCAFillingRequirement } =
        storage.get(TEN99_MISC_STEP_1_BASIC) || {};

    return {
        initYear: Number(year) || 2023,
        initEmail: email,
        initYearlyIncomeBeforeTaxes: Number(yearlyIncomeBeforeTaxes) || 0,
        initAccountNumber: accountNumber || "",
        initIsFATCAFillingRequirement: isFATCAFillingRequirement || false,
    };
};

export const getTen99MISCStep2FromLocalStorage = () => {
    const { name, address, city, state, zipCode, payersTIN } = storage.get(TEN99_MISC_STEP_2_PAYER) || {};

    return {
        initName: name || "",
        initAddress: address || "",
        initCity: city || "",
        initState: state || "",
        initZipCode: zipCode || "",
        initPayersTIN: payersTIN || "",
    };
};

export const getTen99MISCStep3FromLocalStorage = () => {
    const { name, address, city, state, zipCode, recipientsTIN } =
        storage.get(TEN99_MISC_STEP_3_RECIPIENT) || {};

    return {
        initRecipientName: name || "",
        initRecipientAddress: address || "",
        initRecipientCity: city || "",
        initRecipientState: state || "",
        initRecipientZipCode: zipCode || "",
        initRecipientsTIN: recipientsTIN || "",
    };
};

export const getTen99MISCStep4FromLocalStorage = () => {
    const {
        rents,
        royalties,
        fishingBoatProceeds,
        medicalAndHealthCarePayments,
        substitutePaymentsInLieuOfDividendsOrInterest,
        cropInsuranceProceeds,
        fishPurchasedForResale,
        otherIncome,
        federalIncomeTaxWithheld,
        grossProceedsPaidToAnAttorney,
        section409ADeferrals,
        excessGoldenParachutePayments,
        nonQualifiedDeferredCompensation,
        isDirectSalesOrResaleSales,
    } = storage.get(TEN99_MISC_STEP_4_PAYMENTS_AND_TAXES) || {};

    return {
        initRents: rents || 0,
        initRoyalties: royalties || 0,
        initFishingBoatProceeds: fishingBoatProceeds || 0,
        initMedicalAndHealthCarePayments: medicalAndHealthCarePayments || 0,
        initSubstitutePayments: substitutePaymentsInLieuOfDividendsOrInterest || 0,
        initCropInsuranceProceeds: cropInsuranceProceeds || 0,
        initFishPurchasedForResale: fishPurchasedForResale || 0,
        initOtherIncome: otherIncome || 0,
        initFederalIncomeTaxWithheld: federalIncomeTaxWithheld || 0,
        initGrossProceedsPaidToAnAttorney: grossProceedsPaidToAnAttorney || 0,
        initSection409ADeferrals: section409ADeferrals || 0,
        initExcessGoldenParachutePayments: excessGoldenParachutePayments || 0,
        initNonQualifiedDeferredCompensation: nonQualifiedDeferredCompensation || 0,
        isDirectSalesOrResaleSales: isDirectSalesOrResaleSales || false,
    };
};

export const getTen99MISCStep5FromLocalStorage = () => {
    const { state1, state2 } = storage.get(TEN99_MISC_STEP_5_STATE_INCOME_AND_TAX) || {};

    return {
        initState1State: state1?.state || "",
        initState1PayersStateNo: state1?.payersStateNo || "",
        initState1StateTaxWithheld: state1?.stateTaxWithheld || 0,
        initState1StateCome: state1?.stateIncome || 0,
        initState2State: state2?.state || "",
        initState2PayersStateNo: state2?.payersStateNo || "",
        initState2StateTaxWithheld: state2?.stateTaxWithheld || 0,
        initState2StateCome: state2?.stateIncome || 0,
    };
};

export const storeTen99MISCActiveStepToLocalStorage = (step, ten99MISC) => {
    switch (step) {
        case 1:
            return storeTen99MISCStep1ToLocalStorage(ten99MISC.basic);
        case 2:
            return storeTen99MISCStep2ToLocalStorage(ten99MISC.payer);
        case 3:
            return storeTen99MISCStep3ToLocalStorage(ten99MISC.recipient);
        case 4:
            return storeTen99MISCStep4ToLocalStorage(ten99MISC.paymentsAndTaxes);
        case 5:
            return storeTen99MISCStep5ToLocalStorage(ten99MISC.stateIncomeAndTax);
        case 6:
            tracker.startedCheckoutTrack(TEN99_MISC_CONTEXT);
            break;
    }
};
