import { format2DP } from "../helpers/formatters";
import states_tax_brackets from "../configs/states_tax_brackets";

export const ficaMedicareTex = (grossEarnings, maritalStatus) => {
    const taxRate = 0.0145; // 1.45% for Medicare
    const additionalTaxRate = 0.009; // 0.9% for Additional Medicare Tax

    // Thresholds for Additional Medicare Tax based on marital status
    const singleThreshold = 200000;
    const marriedJointThreshold = 200000;

    let ficaMedicareTax = 0;

    // Calculate Medicare tax based on gross earnings
    if (maritalStatus === "single") {
        if (grossEarnings > singleThreshold) {
            // Calculate Additional Medicare Tax for single filers
            const excessEarnings = grossEarnings - singleThreshold;
            const additionalMedicareTax = excessEarnings * additionalTaxRate;
            ficaMedicareTax = grossEarnings * taxRate + additionalMedicareTax;
        } else {
            ficaMedicareTax = grossEarnings * taxRate;
        }
    } else if (maritalStatus === "married") {
        if (grossEarnings > marriedJointThreshold) {
            // Calculate Additional Medicare Tax for married joint filers
            const excessEarnings = grossEarnings - marriedJointThreshold;
            const additionalMedicareTax = excessEarnings * additionalTaxRate;
            ficaMedicareTax = grossEarnings * taxRate + additionalMedicareTax;
        } else {
            ficaMedicareTax = grossEarnings * taxRate;
        }
    }

    return format2DP(ficaMedicareTax);
};

export const ficaSocialSecurityTex = grossIncome => {
    const maximumTaxableIncome = 160200; // Maximum income limit for Social Security tax calculation
    const socialSecurityRate = 6.2; // Social Security tax rate

    // Calculate the tax by multiplying the lower of grossIncome and maximumTaxableIncome by the socialSecurityRate
    let socialSecurityTax = Math.min(grossIncome, maximumTaxableIncome) * (socialSecurityRate / 100);

    return format2DP(socialSecurityTax);
};

// https://taxfoundation.org/2023-tax-brackets/
// https://taxfoundation.org/2022-tax-brackets/
// https://taxfoundation.org/2021-tax-brackets/
export const federalTax = (income, maritalStatus, days = 1, year = 2023) => {
    const taxBrackets = {
        2023: {
            single: [
                { limit: 11000, rate: 0.1 },
                { limit: 44725, rate: 0.12 },
                { limit: 95375, rate: 0.22 },
                { limit: 182100, rate: 0.24 },
                { limit: 231250, rate: 0.32 },
                { limit: 578125, rate: 0.35 },
                { limit: Infinity, rate: 0.37 },
            ],
            married: [
                { limit: 22000, rate: 0.1 },
                { limit: 89450, rate: 0.12 },
                { limit: 190750, rate: 0.22 },
                { limit: 364200, rate: 0.24 },
                { limit: 462500, rate: 0.32 }, // calculation problem is here
                { limit: 693750, rate: 0.35 },
                { limit: Infinity, rate: 0.37 },
            ],
        },
        2022: {
            single: [
                { limit: 10275, rate: 0.1 },
                { limit: 41775, rate: 0.12 },
                { limit: 89075, rate: 0.22 },
                { limit: 170050, rate: 0.24 },
                { limit: 215950, rate: 0.32 },
                { limit: 539900, rate: 0.35 },
                { limit: Infinity, rate: 0.37 },
            ],
            married: [
                { limit: 20550, rate: 0.1 },
                { limit: 83550, rate: 0.12 },
                { limit: 178150, rate: 0.22 },
                { limit: 340100, rate: 0.24 },
                { limit: 431900, rate: 0.32 },
                { limit: 647850, rate: 0.35 },
                { limit: Infinity, rate: 0.37 },
            ],
        },
        2021: {
            single: [
                { limit: 9950, rate: 0.1 },
                { limit: 40525, rate: 0.12 },
                { limit: 86375, rate: 0.22 },
                { limit: 164925, rate: 0.24 },
                { limit: 209425, rate: 0.32 },
                { limit: 523600, rate: 0.35 },
                { limit: Infinity, rate: 0.37 },
            ],
            married: [
                { limit: 19900, rate: 0.1 },
                { limit: 81050, rate: 0.12 },
                { limit: 172750, rate: 0.22 },
                { limit: 329850, rate: 0.24 },
                { limit: 418850, rate: 0.32 },
                { limit: 628300, rate: 0.35 },
                { limit: Infinity, rate: 0.37 },
            ],
        },
    };

    const standardDeduction = {
        2023: {
            single: 13850,
            married: 27700,
        },
        2022: {
            single: 12950,
            married: 25900,
        },
        2021: {
            single: 12550,
            married: 25100,
        },
    };

    // Find the applicable tax bracket
    const brackets = taxBrackets[year][maritalStatus.toLowerCase()];
    let taxableIncome = income * days - standardDeduction[year][maritalStatus.toLowerCase()];
    let tax = 0;

    for (let i = 0; i < brackets.length; i++) {
        const { limit, rate } = brackets[i];

        if (taxableIncome <= 0) {
            break;
        }

        if (taxableIncome <= limit) {
            tax += taxableIncome * rate;
            break;
        } else {
            const taxableAmount = limit - (i > 0 ? brackets[i - 1].limit : 0);
            tax += taxableAmount * rate;
            taxableIncome -= taxableAmount;
        }
    }

    return format2DP(tax / days);
};

export const stateTax = (grossIncome, state, maritalStatus, days = 1, year = 2023) => {
    const isExist = states_tax_brackets[year];
    if (!isExist) return 0;
    const isExist2 = states_tax_brackets[year][state];
    if (!isExist2) return 0;

    const brackets = states_tax_brackets[year][state][maritalStatus].brackets;

    const standardDeduction = states_tax_brackets[year][state][maritalStatus].standardDeduction;

    let taxableIncome = grossIncome * days - standardDeduction;
    let tax = 0;

    for (let i = 0; i < brackets.length; i++) {
        const { limit, rate } = brackets[i];

        if (taxableIncome <= 0) {
            break;
        }

        if (taxableIncome <= limit) {
            tax += taxableIncome * rate;
            break;
        } else {
            const taxableAmount = limit - (i > 0 ? brackets[i - 1].limit : 0);
            tax += taxableAmount * rate;
            taxableIncome -= taxableAmount;
        }
    }

    return format2DP(tax / days);
};

export const sdiTax = (income, state, maritalStatus, days) => {
    const sdiRates = {
        CA: {
            rate: 0.011,
            incomeLimit: 145600,
            standardDeduction: {
                single: 4803,
                married: 9606,
            },
        },
        HI: {
            rate: 0.005,
            incomeLimit: 122800,
            standardDeduction: {
                single: 2200,
                married: 4400,
            },
        },
        NJ: {
            rate: 0.0014,
            incomeLimit: 156800,
            standardDeduction: {
                single: 0,
                married: 0,
            },
        },
        RI: {
            rate: 0.011,
            incomeLimit: 81500,
            standardDeduction: {
                single: 9300,
                married: 18600,
            },
        },
        NY: {
            rate: 0.014,
            incomeLimit: 151900,
            standardDeduction: {
                single: 8000,
                married: 16050,
            },
        },
    };

    if (!sdiRates[state]) {
        return 0;
    }

    const sdiRate = sdiRates[state].rate;
    const incomeLimit = sdiRates[state].incomeLimit;
    const standardDeduction = sdiRates[state].standardDeduction[maritalStatus];

    const taxableIncome = Math.min(income * days, incomeLimit);

    if (taxableIncome <= standardDeduction) {
        return 0;
    }

    if (state === "NY") {
        return 31.2 / days;
    }

    const sdiDeduction = taxableIncome * sdiRate;
    return format2DP(sdiDeduction / days);
};
