import { lazy } from "react";

const routes = [
    {
        path: "/w-2-form-online-generator",
        component: lazy(() => import("./pages/W2")),
    },
    {
        path: "/1099-misc-form-generator",
        component: lazy(() => import("./pages/Ten99MISC")),
    },
    {
        path: "/1099-nec-form-generator",
        component: lazy(() => import("./pages/Ten99NEC")),
    },
    {
        path: "/invoice-generator",
        component: lazy(() => import("./pages/Invoice")),
    },
    {
        path: "/offer-letter-generator",
        component: lazy(() => import("./pages/OfferLetter")),
    },
    {
        path: "/profile",
        component: lazy(() => import("./pages/Profile")),
    },
    {
        path: "/thank-you",
        component: lazy(() => import("./pages/ThankYou")),
    },
    {
        path: "/completion",
        component: lazy(() => import("./pages/Completion")),
    },

    {
        path: "*",
        component: lazy(() => import("./pages/Paystub")),
    },
];

export default routes;
