import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const BASE_URL = `${appLocalizer.apiUrl}/psh/v1`;

export const apiSlice = createApi({
    reducerPath: "api",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
        headers: {
            "X-WP-NONCE": appLocalizer.nonce,
        },
    }),
    endpoints: builder => ({}),
});

export default apiSlice;
