import {
    calAdjustDeductionsPriorYTD,
    calAdjustDeductionsTotal,
    cal_adjust_earning_prior_ytd,
    calAdjustSDIDeductions,
    calDeleteSDIDeductions,
    calMakeAllDeductionsZeroAllPaystubs,
    calPayDate,
    calPayPeriodEnd,
    calPayPeriodStart,
    calTotalPayPeriodNumber,
    getEarningRegularHoursMax,
} from "../../logic/paystubs_logic";
import { createSlice } from "@reduxjs/toolkit";
import { SDIAllowed } from "../../configs/etc";
import { paystubActiveStep, paystubMaxStep } from "../../configs/paystub_config";
import { addDays, format_date_for_store, getDayDifferance, subtractDays } from "../../helpers/time";
import { gen_payroll_check_number, get_vchr_number, get_advice_number } from "../../helpers/etc";
import {
    PAYSTUB_STEP3_COMPANY_INFORMATION_KEY,
    PAYSTUB_STEP4_EMPLOYEE_INFORMATION_KEY,
    PAYSTUB_STEP5_SALARY_INFO_KEY,
    getStep1ToLocalStorage,
    getStep2ToLocalStorage,
} from "../../memo/paystub_memo";
import { capitalizeWordsFirstLetter } from "../../helpers/formatters";
import storage from "../../helpers/storage";

const {
    companyName: initCompanyName,
    companyTelephoneNumber,
    companyStreetAddress1,
    companyStreetAddress2,
    companyCity,
    companyState,
    companyZipCode,
} = storage.get(PAYSTUB_STEP3_COMPANY_INFORMATION_KEY) || {};
const {
    employeeName,
    employeeSocial,
    employeeID,
    employeePhoneNumber,
    employeeAddress1,
    employeeAddress2,
    employeeCity,
    employeeState,
    employeeZipCode,
    employeeExemptions,
    employeeMaritalStatus: initEmployeeMaritalStatus,
    employeeDirectDeposit,
    employeeDirectDepositValue,
} = storage.get(PAYSTUB_STEP4_EMPLOYEE_INFORMATION_KEY) || {};
const {
    paymentFrequency: paymentFrequencyL,
    paymentType: initPaymentType,
    payPeriodStart: initPayPeriodStart,
} = storage.get(PAYSTUB_STEP5_SALARY_INFO_KEY) || {};

const initPaymentFrequency = paymentFrequencyL || "Bi-Weekly (ex: every other Wednesday)";

const xPayPeriodStart = initPayPeriodStart || calPayPeriodStart(initPaymentFrequency);

const { iniTemplate, initCompanyLogoUrl } = getStep1ToLocalStorage();
const {
    initEmploymentType,
    initEmailAddress,
    initNumberOfPaystubsRequired,
    initStateToBeUsedInTaxCalculations,
} = getStep2ToLocalStorage();

const recalculatePaystubsTexesYTDEtc = (
    paystubs,
    paymentFrequency,
    payPeriodNumber,
    employmentType,
    employeeMaritalStatus,
    stateToBeUsedInTaxCalculations,
    isLastPriorYTDUnlocked
) => {
    // adjust all paystubs earnings priorYTD
    paystubs = cal_adjust_earning_prior_ytd(
        paystubs,
        paymentFrequency,
        payPeriodNumber,
        isLastPriorYTDUnlocked
    );

    if (employmentType !== "contractor") {
        paystubs = calAdjustDeductionsPriorYTD(
            paystubs,
            paymentFrequency,
            payPeriodNumber,
            employeeMaritalStatus,
            stateToBeUsedInTaxCalculations,
            isLastPriorYTDUnlocked
        );
    }

    return paystubs;
};

// noinspection JSCheckFunctionSignatures
const paystubSlice = createSlice({
    name: "paystub",
    initialState: {
        local: {
            activeStep: paystubActiveStep,
            lastFilledStep: paystubActiveStep - 1,
            errors: {},
            activePaystub: 1,
            activeBarPaystub: 1,
            reCalPaystubs: true,
            isLastPriorYTDUnlocked: false,
            isLastDeductionPriorYTDUnlocked: false,
        },
        company: {
            companyName: capitalizeWordsFirstLetter(initCompanyName || ""),
            companyTelephoneNumber: companyTelephoneNumber || "",
            companyStreetAddress1: capitalizeWordsFirstLetter(companyStreetAddress1 || ""),
            companyStreetAddress2: capitalizeWordsFirstLetter(companyStreetAddress2 || ""),
            companyCity: capitalizeWordsFirstLetter(companyCity || ""),
            companyState: companyState || "",
            companyZipCode: companyZipCode || "",
            companyLogoUrl: initCompanyLogoUrl,
        },
        employee: {
            employeeName: capitalizeWordsFirstLetter(employeeName || ""),
            employeeSocial: employeeSocial || "",
            employeeID: employeeID || "",
            employeePhoneNumber: employeePhoneNumber || "",
            employeeAddress1: capitalizeWordsFirstLetter(employeeAddress1 || ""),
            employeeAddress2: capitalizeWordsFirstLetter(employeeAddress2 || ""),
            employeeCity: capitalizeWordsFirstLetter(employeeCity || ""),
            employeeState: employeeState || "",
            employeeZipCode: employeeZipCode || "",
            employeeExemptions: Number(employeeExemptions) || 1,
            employeeMaritalStatus: initEmployeeMaritalStatus || "single",
            employeeDirectDeposit: employeeDirectDeposit || false,
            employeeDirectDepositValue: employeeDirectDepositValue || "",
        },
        salary: {
            payPeriodNumberMax: 16,
            payPeriodNumber: 11,
            emailAddress: initEmailAddress,
            numberOfPaystubsRequired: initNumberOfPaystubsRequired,
            stateToBeUsedInTaxCalculations: initStateToBeUsedInTaxCalculations,
            paymentFrequency: initPaymentFrequency,
            employmentType: initEmploymentType,
            template: Number(iniTemplate) || 1,
        },
        paystubs: [
            {
                payPeriodStart: xPayPeriodStart,
                payDate: calPayDate(xPayPeriodStart, initPaymentFrequency),
                earnings: [
                    {
                        id: 1,
                        label: "Regular",
                        paymentType: initPaymentType || "hourly",
                        rate: initPaymentType === "salary" ? 48000 : 20,
                        hours: initPaymentType === "salary" ? 0 : 80,
                        priorYTD: -1000,
                    },
                ],
                deductions: [
                    {
                        deleteAble: false,
                        name: "FICA - Medicare",
                        total: 23.2,
                        priorYTD: 255.2,
                    },
                    {
                        deleteAble: false,
                        name: "FICA - Social Security",
                        total: 99.2,
                        priorYTD: 1091.2,
                    },
                    {
                        deleteAble: false,
                        name: "Federal Tax",
                        total: 119.62,
                        priorYTD: 956.96,
                    },
                    {
                        deleteAble: false,
                        name: "State Tax",
                        total: 0,
                        priorYTD: 0,
                    },
                ],
                template1: {
                    CONumber: "2rv",
                    fileNumber: "811139",
                    DEPTNumber: "583670",
                    clockNumber: "48074",
                    VCHRNumber: get_vchr_number(),
                    adviceNumber: get_advice_number(),
                    payrollCheckNumber: gen_payroll_check_number(),
                },
            },
        ],
    },
    reducers: {
        setActiveStep: (state, action) => {
            const step = Number(action.payload);
            if (1 <= step && step <= paystubMaxStep) state.local.activeStep = step;
        },
        setLastFilledStep: (state, action) => {
            state.local.lastFilledStep = Number(action.payload);
        },
        setActivePaystub: (state, action) => {
            state.local.activePaystub = Number(action.payload);
        },
        setActiveBarPaystub: (state, action) => {
            const activeBarPaystub = Number(action.payload);
            state.local.activeBarPaystub = activeBarPaystub;
            if (activeBarPaystub !== -1) state.local.activePaystub = activeBarPaystub;
        },
        setCompanyField(state, action) {
            const { field, value } = action.payload;
            if (
                field === "companyName" ||
                field === "companyStreetAddress1" ||
                field === "companyStreetAddress2" ||
                field === "companyCity"
            ) {
                state.company[field] = capitalizeWordsFirstLetter(value);
            } else {
                state.company[field] = value;
            }
        },
        setEmployeeField(state, action) {
            const { field, value } = action.payload;
            if (
                field === "employeeName" ||
                field === "employeeAddress1" ||
                field === "employeeAddress2" ||
                field === "employeeCity"
            ) {
                state.employee[field] = capitalizeWordsFirstLetter(value);
            } else {
                state.employee[field] = value;
            }
        },
        setSalaryField(state, action) {
            const { field, value } = action.payload;
            state.salary[field] = value;
        },
        setTemplate1Field: (state, action) => {
            const { field, value, paystub } = action.payload;
            state.paystubs[paystub - 1].template1[field] = value;
        },
        setErrors: (state, action) => {
            state.local.errors = action.payload;
        },
        setPaymentFrequency: (state, action) => {
            state.salary.paymentFrequency = action.payload;
        },
        setNumberOfPaystubsRequired: (state, action) => {
            const { numberOfPaystubsRequired, paymentFrequency } = action.payload;

            state.salary.numberOfPaystubsRequired = numberOfPaystubsRequired;

            // adjust the active paystub on the salary info step 5
            const activePaystub = state.local.activePaystub;
            state.local.activePaystub =
                numberOfPaystubsRequired < activePaystub ? numberOfPaystubsRequired : activePaystub;

            // setting up variables
            const lengthOfPaystubs = state.paystubs.length;

            // adjust all the paystubs with dates
            let paystubs =
                numberOfPaystubsRequired < lengthOfPaystubs
                    ? state.paystubs.filter((_, index) => index < numberOfPaystubsRequired)
                    : state.paystubs;

            const firstPaystub = state.paystubs[0];
            for (let i = lengthOfPaystubs + 1; i <= numberOfPaystubsRequired; i++) {
                paystubs.push({ ...firstPaystub });
            }

            // setting dates
            let lastPayPeriodStart = state.paystubs[0].payPeriodStart;
            paystubs = paystubs.map((paystub, index) => {
                const earnings = paystub.earnings;
                earnings[0].hours = getEarningRegularHoursMax(paymentFrequency);

                if (index === 0) {
                    return {
                        ...paystub,
                        earnings,
                        payDate: calPayDate(paystub.payPeriodStart, paymentFrequency),
                    };
                }

                lastPayPeriodStart = calPayPeriodStart(paymentFrequency, subtractDays(lastPayPeriodStart, 1));

                return {
                    ...paystub,
                    payPeriodStart: lastPayPeriodStart,
                    payDate: calPayDate(lastPayPeriodStart, paymentFrequency),
                    earnings,
                    template1: {
                        ...paystub.template1,
                        payrollCheckNumber: gen_payroll_check_number(),
                        VCHRNumber: get_vchr_number(),
                        adviceNumber: get_advice_number(),
                    },
                };
            });

            // set the payPeriodNumber
            const payPeriodNumberMax = calTotalPayPeriodNumber(paystubs, paymentFrequency);
            state.salary.payPeriodNumberMax = (payPeriodNumberMax || 1) + 5;
            state.salary.payPeriodNumber = payPeriodNumberMax || 1;

            // adjust all paystubs earnings priorYTD
            paystubs = cal_adjust_earning_prior_ytd(
                paystubs,
                paymentFrequency,
                payPeriodNumberMax,
                state.local.isLastPriorYTDUnlocked
            );

            const { employmentType, stateToBeUsedInTaxCalculations } = state.salary;
            const { employeeMaritalStatus } = state.employee;

            if (SDIAllowed[stateToBeUsedInTaxCalculations]) {
                paystubs = calAdjustSDIDeductions(paystubs);
            }

            if (employmentType === "contractor") {
                // make all deduction zero if it's contractor
                paystubs = calMakeAllDeductionsZeroAllPaystubs(paystubs);
            } else {
                paystubs = calAdjustDeductionsTotal(
                    paystubs,
                    paymentFrequency,
                    employeeMaritalStatus,
                    stateToBeUsedInTaxCalculations
                );
                paystubs = calAdjustDeductionsPriorYTD(
                    paystubs,
                    paymentFrequency,
                    payPeriodNumberMax,
                    state.employee.employeeMaritalStatus,
                    state.salary.stateToBeUsedInTaxCalculations,
                    state.local.isLastPriorYTDUnlocked
                );
            }

            state.paystubs = paystubs;
        },
        setPaystubPayDate: (state, action) => {
            const { payDate, paystubNo } = action.payload;
            const daysDifferance = getDayDifferance(state.paystubs[paystubNo - 1].payDate, payDate);
            state.paystubs = state.paystubs.map((paystub, index) =>
                index < paystubNo - 1
                    ? paystub
                    : {
                          ...paystub,
                          payDate: format_date_for_store(addDays(paystub.payDate, daysDifferance)),
                      }
            );
        },
        setPayPeriodStart: (state, action) => {
            const { paystubNo, payPeriodStart } = action.payload;
            const paymentFrequency = state.salary.paymentFrequency;

            const paystubs = state.paystubs;

            paystubs[paystubNo - 1] = {
                ...paystubs[paystubNo - 1],
                payPeriodStart,
                payDate: calPayDate(payPeriodStart, paymentFrequency),
            };

            for (let i = paystubNo; i < state.paystubs.length; i++) {
                const payPeriodEnd = subtractDays(paystubs[i - 1].payPeriodStart, 1);
                // noinspection JSCheckFunctionSignatures
                paystubs[i] = {
                    ...paystubs[i],
                    payPeriodStart: calPayPeriodStart(paymentFrequency, payPeriodEnd),
                    payDate: format_date_for_store(addDays(payPeriodEnd, 5)),
                };
            }

            for (let i = paystubNo - 2; i >= 0; i--) {
                const payPeriodStart = format_date_for_store(
                    addDays(calPayPeriodEnd(paystubs[i + 1].payPeriodStart, paymentFrequency), 1)
                );
                paystubs[i] = {
                    ...state.paystubs[i],
                    payPeriodStart: payPeriodStart,
                    payDate: calPayDate(payPeriodStart, paymentFrequency),
                };
            }

            state.paystubs = paystubs;
            const payPeriodNumberMax = calTotalPayPeriodNumber(paystubs, paymentFrequency);
            state.salary.payPeriodNumberMax = (payPeriodNumberMax || 1) + 5;
            state.salary.payPeriodNumber = payPeriodNumberMax || 1;

            const { employmentType, payPeriodNumber } = state.salary;

            state.paystubs = recalculatePaystubsTexesYTDEtc(
                state.paystubs,
                paymentFrequency,
                payPeriodNumber,
                employmentType,
                state.employee.employeeMaritalStatus,
                state.salary.stateToBeUsedInTaxCalculations,
                state.local.isLastPriorYTDUnlocked
            );
        },
        setEmploymentType: (state, action) => {
            const employmentType = action.payload;
            state.salary.employmentType = employmentType;

            const { paymentFrequency, stateToBeUsedInTaxCalculations } = state.salary;

            let paystubs = state.paystubs;

            for (let i = 0; i < paystubs.length; i++) {
                const paymentType = paystubs[i].earnings[0].paymentType;
                paystubs[i].earnings[0].label =
                    employmentType === "employee"
                        ? paymentType === "hourly"
                            ? "Regular"
                            : "Salary"
                        : "Contractor";
            }

            if (employmentType === "contractor") {
                paystubs = calMakeAllDeductionsZeroAllPaystubs(paystubs);
                state.salary.stateToBeUsedInTaxCalculations = "";
            } else {
                const { employeeMaritalStatus } = state.employee;

                paystubs = calAdjustDeductionsTotal(
                    paystubs,
                    paymentFrequency,
                    employeeMaritalStatus,
                    stateToBeUsedInTaxCalculations
                );
                paystubs = calAdjustDeductionsPriorYTD(
                    paystubs,
                    paymentFrequency,
                    state.salary.payPeriodNumber,
                    state.employee.employeeMaritalStatus,
                    state.salary.stateToBeUsedInTaxCalculations,
                    state.local.isLastPriorYTDUnlocked
                );
            }
            state.paystubs = paystubs;
        },
        setEarningHours: (state, action) => {
            const { label, paystubNo, value } = action.payload;
            const { paymentFrequency, payPeriodNumber, stateToBeUsedInTaxCalculations } = state.salary;

            let paystubs = state.paystubs;

            paystubs = paystubs.map((paystub, index) => {
                if (index < paystubNo - 1) {
                    return paystub;
                }
                return {
                    ...paystub,
                    earnings: paystub.earnings.map(e =>
                        e.label === label ? { ...e, hours: Number(value) } : e
                    ),
                };
            });

            // adjust all paystubs earnings priorYTD
            paystubs = cal_adjust_earning_prior_ytd(
                paystubs,
                paymentFrequency,
                payPeriodNumber,
                state.local.isLastPriorYTDUnlocked
            );

            if (state.salary.employmentType !== "contractor") {
                const { employeeMaritalStatus } = state.employee;

                paystubs = calAdjustDeductionsTotal(
                    paystubs,
                    paymentFrequency,
                    employeeMaritalStatus,
                    stateToBeUsedInTaxCalculations
                );
                paystubs = calAdjustDeductionsPriorYTD(
                    paystubs,
                    paymentFrequency,
                    payPeriodNumber,
                    state.employee.employeeMaritalStatus,
                    state.salary.stateToBeUsedInTaxCalculations,
                    state.local.isLastPriorYTDUnlocked
                );
            }

            state.paystubs = paystubs;
        },
        setEarningRate: (state, action) => {
            const { label, paystubNo, value } = action.payload;

            const { paymentFrequency, payPeriodNumber, employmentType, stateToBeUsedInTaxCalculations } =
                state.salary;

            let paystubs = state.paystubs;

            paystubs = paystubs.map((paystub, index) => {
                if (index < paystubNo - 1) {
                    return paystub;
                }
                return {
                    ...paystub,
                    earnings: paystub.earnings.map(earning =>
                        earning.label === label ? { ...earning, rate: Number(value) } : earning
                    ),
                };
            });

            // adjust all paystubs earnings priorYTD
            paystubs = cal_adjust_earning_prior_ytd(
                paystubs,
                paymentFrequency,
                payPeriodNumber,
                state.local.isLastPriorYTDUnlocked
            );

            if (employmentType !== "contractor") {
                const { employeeMaritalStatus } = state.employee;

                paystubs = calAdjustDeductionsTotal(
                    paystubs,
                    paymentFrequency,
                    employeeMaritalStatus,
                    stateToBeUsedInTaxCalculations
                );
                paystubs = calAdjustDeductionsPriorYTD(
                    paystubs,
                    paymentFrequency,
                    payPeriodNumber,
                    state.employee.employeeMaritalStatus,
                    state.salary.stateToBeUsedInTaxCalculations,
                    state.local.isLastPriorYTDUnlocked
                );
            }

            state.paystubs = paystubs;
        },
        addEarning: (state, action) => {
            const { label, paymentType } = action.payload;

            if (state.paystubs[0].earnings.find(e => e.label === label)) {
                return;
            }

            state.paystubs = state.paystubs.map(paystub => ({
                ...paystub,
                earnings: [
                    ...paystub.earnings,
                    {
                        label: label,
                        paymentType: paymentType,
                        rate: 0,
                        hours: 0,
                        priorYTD: 0,
                    },
                ],
            }));
        },

        setStateToBeUsedInTaxCalculations: (state, action) => {
            const stateToBeUsedInTaxCalculations = action.payload;

            state.salary.stateToBeUsedInTaxCalculations = stateToBeUsedInTaxCalculations;
            state.company.companyState = stateToBeUsedInTaxCalculations;
            state.employee.employeeState = stateToBeUsedInTaxCalculations;

            if (SDIAllowed[stateToBeUsedInTaxCalculations]) {
                state.paystubs = calAdjustSDIDeductions(state.paystubs);
            } else {
                state.paystubs = calDeleteSDIDeductions(state.paystubs);
            }
        },
        setPaymentType: (state, action) => {
            const paymentType = action.payload;
            for (let i = 0; i < state.paystubs.length; i++) {
                state.paystubs[i].earnings[0].paymentType = paymentType;
                state.paystubs[i].earnings[0].label = paymentType === "salary" ? "Salary" : "Regular";
                state.paystubs[i].earnings[0].rate = paymentType === "hourly" ? 20 : 48000;
                state.paystubs[i].earnings[0].hours =
                    paymentType === "hourly" ? getEarningRegularHoursMax(state.salary.paymentFrequency) : 0;
            }
            state.local.reCalPaystubs = !state.local.reCalPaystubs;
        },
        setEmployeeMaritalStatus(state, action) {
            state.employee.employeeMaritalStatus = action.payload;
            state.local.reCalPaystubs = !state.local.reCalPaystubs;
        },
        setTemplate: (state, action) => {
            const template = action.payload;
            state.salary.template = Number(template);
        },
        deleteEarning(state, action) {
            const { label } = action.payload;
            state.paystubs = state.paystubs.map(paystub => ({
                ...paystub,
                earnings: paystub.earnings.filter(earning => earning.label !== label),
            }));
        },
        setLastPriorYTD: (state, action) => {
            const { priorYTD, earningIndex } = action.payload;

            let paystubs = state.paystubs;

            const lastPaystub = paystubs[paystubs.length - 1];
            paystubs[paystubs.length - 1] = {
                ...lastPaystub,
                earnings: lastPaystub.earnings.map((earning, index) =>
                    earningIndex !== index ? earning : { ...earning, priorYTD }
                ),
            };

            paystubs = cal_adjust_earning_prior_ytd(
                paystubs,
                state.salary.paymentFrequency,
                state.salary.payPeriodNumber,
                state.local.isLastPriorYTDUnlocked
            );

            if (state.salary.employmentType !== "contractor") {
                paystubs = calAdjustDeductionsTotal(
                    paystubs,
                    state.salary.paymentFrequency,
                    state.employee.employeeMaritalStatus,
                    state.salary.stateToBeUsedInTaxCalculations
                );
                paystubs = calAdjustDeductionsPriorYTD(
                    paystubs,
                    state.salary.paymentFrequency,
                    state.salary.payPeriodNumber,
                    state.employee.employeeMaritalStatus,
                    state.salary.stateToBeUsedInTaxCalculations,
                    state.local.isLastPriorYTDUnlocked
                );
            }

            state.paystubs = paystubs;
        },
        setLocalField: (state, action) => {
            const { field, value } = action.payload;
            state.local[field] = value;
        },
        addDeduction(state, action) {
            const name = action.payload;
            if (state.paystubs[0].deductions.find(d => d.name === name) || name === "") {
                return;
            }
            state.paystubs = state.paystubs.map(paystub => ({
                ...paystub,
                deductions: [
                    ...paystub.deductions,
                    {
                        deleteAble: true,
                        name,
                        total: 0,
                        priorYTD: 0,
                        ytdTotal: 0,
                    },
                ],
            }));
        },
        deleteDeduction(state, action) {
            const name = action.payload;
            state.paystubs = state.paystubs.map(paystub => ({
                ...paystub,
                deductions: paystub.deductions.filter(deduction => deduction.name !== name),
            }));
        },
        setDeductionTotal: (state, action) => {
            if (state.salary.employmentType === "contractor") {
                return;
            }
            const { name, value, paystubNo } = action.payload;
            let paystubs = state.paystubs.map((paystub, index) => {
                if (index < paystubNo - 1) {
                    return paystub;
                }
                return {
                    ...paystub,
                    deductions: paystub.deductions.map(deduction => {
                        if (deduction.name !== name) {
                            return deduction;
                        }
                        return {
                            ...deduction,
                            total: value,
                        };
                    }),
                };
            });
            paystubs = calAdjustDeductionsPriorYTD(
                paystubs,
                state.salary.paymentFrequency,
                state.salary.payPeriodNumber,
                state.employee.employeeMaritalStatus,
                state.salary.stateToBeUsedInTaxCalculations,
                state.local.isLastPriorYTDUnlocked
            );
            state.paystubs = paystubs;
        },
        setLastDeductionPriorYTD: (state, action) => {
            if (state.salary.employmentType === "contractor") {
                return;
            }

            const { name, value } = action.payload;

            const paystubs = state.paystubs;

            let priorYTD = value;

            state.paystubs = paystubs
                .reverse()
                .map((paystub, index) => {
                    return {
                        ...paystub,
                        deductions: paystub.deductions.map(deduction => {
                            if (deduction.name !== name) {
                                return deduction;
                            }
                            priorYTD = index === 0 ? value : priorYTD + deduction.total;
                            return {
                                ...deduction,
                                priorYTD,
                            };
                        }),
                    };
                })
                .reverse();
        },
    },
});

export const {
    setActiveStep,
    setLastFilledStep,
    setActivePaystub,
    setErrors,
    setTemplate,
    setCompanyField,
    setEmployeeField,
    setSalaryField,
    setPayPeriodStart,
    setPaystubPayDate,
    setPaymentFrequency,
    addEarning,
    deleteEarning,
    setTemplate1Field,
    setNumberOfPaystubsRequired,
    setStateToBeUsedInTaxCalculations,
    setPaymentType,
    setEmploymentType,
    setEarningRate,
    setEarningHours,
    setActiveBarPaystub,
    setEmployeeMaritalStatus,
    setLastPriorYTD,
    setLocalField,

    addDeduction,
    deleteDeduction,
    setDeductionTotal,
    setLastDeductionPriorYTD,
} = paystubSlice.actions;

export default paystubSlice;
