import { developer_website } from "../configs/etc";

export const generate_random_number = digitLength => {
    const min = 10 ** (digitLength - 1);
    const max = 10 ** digitLength - 1;
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const gen_payroll_check_number = () => {
    return generate_random_number(6);
};

export const get_vchr_number = () => {
    return generate_random_number(5);
};

export const get_advice_number = () => {
    return `000${generate_random_number(4)}`;
};

export const gen_local_storage_key_name = key => {
    return `[${developer_website}]-paystubhero-${key}`;
};
