import { createSlice } from "@reduxjs/toolkit";
import { ten99MISCInitActiveStep, ten99MISCMaxStep } from "../../configs/ten99_misc_config";
import {
    getTen99MISCStep1FromLocalStorage,
    getTen99MISCStep2FromLocalStorage,
    getTen99MISCStep3FromLocalStorage,
    getTen99MISCStep4FromLocalStorage,
    getTen99MISCStep5FromLocalStorage,
} from "../../memo/ten99_misc_memo";
import { capitalizeWordsFirstLetter } from "../../helpers/formatters";

const { initYear, initEmail, initYearlyIncomeBeforeTaxes, initAccountNumber, initIsFATCAFillingRequirement } =
    getTen99MISCStep1FromLocalStorage();
const { initName, initAddress, initCity, initState, initZipCode, initPayersTIN } =
    getTen99MISCStep2FromLocalStorage();
const {
    initRecipientName,
    initRecipientAddress,
    initRecipientCity,
    initRecipientState,
    initRecipientZipCode,
    initRecipientsTIN,
} = getTen99MISCStep3FromLocalStorage();

const {
    initState1State,
    initState1PayersStateNo,
    initState1StateTaxWithheld,
    initState1StateCome,
    initState2State,
    initState2PayersStateNo,
    initState2StateTaxWithheld,
    initState2StateCome,
} = getTen99MISCStep5FromLocalStorage();

const {
    initRents,
    initRoyalties,
    initFishingBoatProceeds,
    initMedicalAndHealthCarePayments,
    initSubstitutePayments,
    initCropInsuranceProceeds,
    initFishPurchasedForResale,
    initOtherIncome,
    initFederalIncomeTaxWithheld,
    initGrossProceedsPaidToAnAttorney,
    initSection409ADeferrals,
    initExcessGoldenParachutePayments,
    initNonQualifiedDeferredCompensation,
    isDirectSalesOrResaleSales,
} = getTen99MISCStep4FromLocalStorage();

const ten99MISCSlice = createSlice({
    name: "ten99MISC",
    initialState: {
        local: {
            activeStep: ten99MISCInitActiveStep,
            lastFilledStep: ten99MISCInitActiveStep - 1,
            errors: {},
        },
        basic: {
            year: initYear,
            email: initEmail,
            yearlyIncomeBeforeTaxes: initYearlyIncomeBeforeTaxes,
            accountNumber: initAccountNumber,
            isFATCAFillingRequirement: initIsFATCAFillingRequirement,
        },
        payer: {
            name: capitalizeWordsFirstLetter(initName),
            address: capitalizeWordsFirstLetter(initAddress),
            city: capitalizeWordsFirstLetter(initCity),
            state: initState,
            zipCode: initZipCode,
            payersTIN: initPayersTIN,
        },
        recipient: {
            name: capitalizeWordsFirstLetter(initRecipientName),
            address: capitalizeWordsFirstLetter(initRecipientAddress),
            city: capitalizeWordsFirstLetter(initRecipientCity),
            state: initRecipientState,
            zipCode: initRecipientZipCode,
            recipientsTIN: initRecipientsTIN,
        },
        paymentsAndTaxes: {
            rents: initRents,
            royalties: initRoyalties,
            fishingBoatProceeds: initFishingBoatProceeds,
            medicalAndHealthCarePayments: initMedicalAndHealthCarePayments,
            substitutePaymentsInLieuOfDividendsOrInterest: initSubstitutePayments,
            cropInsuranceProceeds: initCropInsuranceProceeds,
            fishPurchasedForResale: initFishPurchasedForResale,
            otherIncome: initOtherIncome,
            federalIncomeTaxWithheld: initFederalIncomeTaxWithheld,
            grossProceedsPaidToAnAttorney: initGrossProceedsPaidToAnAttorney,
            section409ADeferrals: initSection409ADeferrals,
            excessGoldenParachutePayments: initExcessGoldenParachutePayments,
            nonQualifiedDeferredCompensation: initNonQualifiedDeferredCompensation,
            isDirectSalesOrResaleSales: isDirectSalesOrResaleSales,
        },
        stateIncomeAndTax: {
            state1: {
                state: initState1State,
                payersStateNo: initState1PayersStateNo,
                stateTaxWithheld: initState1StateTaxWithheld,
                stateIncome: initState1StateCome,
            },
            state2: {
                state: initState2State,
                payersStateNo: initState2PayersStateNo,
                stateTaxWithheld: initState2StateTaxWithheld,
                stateIncome: initState2StateCome,
            },
        },
    },
    reducers: {
        setTen99MISCActiveStep: (state, action) => {
            const step = Number(action.payload);
            if (1 <= step && step <= ten99MISCMaxStep) state.local.activeStep = step;
        },
        setTen99MISCLastFilledStep: (state, action) => {
            state.local.lastFilledStep = Number(action.payload);
        },
        setTen99MISCErrors: (state, action) => {
            state.local.errors = action.payload;
        },
        setTen99MISCBasicField: (state, action) => {
            const { field, value } = action.payload;

            if (field === "yearlyIncomeBeforeTaxes") {
                state.paymentsAndTaxes.otherIncome = value;
            }

            state.basic[field] = value;
        },
        setTen99MISCPayerField: (state, action) => {
            const { field, value } = action.payload;
            if (field === "name" || field === "address" || field === "city") {
                state.payer[field] = capitalizeWordsFirstLetter(value);
            } else {
                state.payer[field] = value;
            }
        },
        setTen99MISCRecipientField: (state, action) => {
            const { field, value } = action.payload;
            if (field === "name" || field === "address" || field === "city") {
                state.recipient[field] = capitalizeWordsFirstLetter(value);
            } else {
                state.recipient[field] = value;
            }
        },
        setTen99MISCPaymentAndTaxesField: (state, action) => {
            const { field, value } = action.payload;
            state.paymentsAndTaxes[field] = value;
        },
        setTen99MISCStateIncomeAndTaxState1: (state, action) => {
            const { field, value } = action.payload;
            state.stateIncomeAndTax.state1[field] = value;
        },
        setTen99MISCStateIncomeAndTaxState2: (state, action) => {
            const { field, value } = action.payload;
            state.stateIncomeAndTax.state2[field] = value;
        },
    },
});

export const {
    setTen99MISCActiveStep,
    setTen99MISCLastFilledStep,
    setTen99MISCErrors,
    setTen99MISCBasicField,
    setTen99MISCPayerField,
    setTen99MISCRecipientField,
    setTen99MISCPaymentAndTaxesField,
    setTen99MISCStateIncomeAndTaxState1,
    setTen99MISCStateIncomeAndTaxState2,
} = ten99MISCSlice.actions;

export default ten99MISCSlice;
