class Storage {
    keys = {
        TEN99_NEC_STEP_1_PAYER: "ten99_nec_step1_payer",
        TEN99_NEC_STEP_2_RECIPIENT: "ten99_nec_step2_recipient",
        TEN99_NEC_STEP_3_FILLING_INFORMATION: "ten99_nec_step3_filling_information",
        TEN99_NEC_STEP_4_STATE_INCOME_AND_TAX: "ten99_nec_step4_state_income_and_tax",
    };
    key_version = "-v2";
    get(key) {
        const item = localStorage.getItem(key + this.key_version);
        return item ? JSON.parse(item) : null;
    }
    get2(key) {
        return this.get(key + this.key_version) || {};
    }
    set(key, value) {
        localStorage.setItem(key + this.key_version, JSON.stringify(value));
    }
    remove(key) {
        localStorage.removeItem(key + this.key_version);
    }
}

const storage = new Storage();
export default storage;
