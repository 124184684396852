import React from "react";
import ReactDOM from "react-dom/client";

import "./styles/index.css";
import "./styles/cropper.css";

import App from "./App";

const root = document.getElementById("paystubhero-plugin-paystub-shortcode");

if (root) {
    ReactDOM.createRoot(root).render(<App />);
}
