import { PAYSTUB_CONTEXT, W2_CONTEXT } from "../configs/consts";

const tracker = {
    identify: email => {
        try {
            if (!klaviyo) {
                return;
            }
            klaviyo?.push([
                "identify",
                {
                    $email: email,
                },
            ]);
        } catch (e) {
            console.log("identify error: ", e);
        }
    },
    addCustomProperties: (properties = {}) => {
        try {
            if (!klaviyo) {
                return;
            }
            klaviyo?.push(["identify", properties]);
        } catch (e) {
            console.log("add custom properties error: ", e);
        }
    },
    track: (trackName, properties = {}) => {
        try {
            if (!klaviyo) {
                return;
            }
            klaviyo?.push(["track", trackName, properties]);
        } catch (e) {
            console.log(`track error: (${trackName})`, e);
        }
    },
    cancelOrderTrack: context => {
        tracker.track("Cancelled Order", { documentName: context });
    },
    placedOrderTrack: context => {
        tracker.track("Placed Order", { documentName: context });
    },
    fulfilledOrderTrack: (context, amount = 0) => {
        tracker.track("Fulfilled Order", {
            documentName: context,
            $value: amount,
        });
        console.log("traking", context, "value", amount);
    },
    startedCheckoutTrack: context => {
        tracker.track("Started Checkout", { documentName: context });
    },
};

const viewedProductTrackInternal = item => {
    tracker.track("Viewed Product", {
        ProductName: item.ProductName,
        ProductID: item.ProductID,
        SKU: item.SKU,
        Categories: item.Categories,
        ImageURL: item.ImageURL,
        URL: item.URL,
        Brand: item.Brand,
        Price: item.Price,
        CompareAtPrice: item.CompareAtPrice,
    });
};

export const viewedProductTrack = context => {
    switch (context) {
        case PAYSTUB_CONTEXT:
            viewedProductTrackInternal({
                ProductName: "Paystub",
                ProductID: "100",
                Categories: "Digital Documents",
                ImageURL: "https://i0.wp.com/www.paystubhero.com/wp-content/uploads/2023/07/242-1.png",
                URL: "https://www.paystubhero.com/generate-pay-stubs/",
                Brand: "Paystub Hero",
                Price: "7.5",
            });
            break;
        case W2_CONTEXT:
            viewedProductTrackInternal({
                ProductName: "W-2",
                ProductID: "150",
                Categories: "Digital Documents",
                ImageURL: "https://i0.wp.com/www.paystubhero.com/wp-content/uploads/2023/07/243-2.png",
                URL: "https://www.paystubhero.com/w-2-form-online-generator/",
                Brand: "Paystub Hero",
                Price: "12.99",
            });
            break;
    }
};

export default tracker;
