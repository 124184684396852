export const paystubActiveStep = 1;

export const paystubStepsNameInBar = {
    1: "1.Template Selection",
    2: "2.Paystub Details",
    3: "3.Company Info",
    4: "4.Employee Info",
    5: "5.Salary Information",
    6: "6.Review",
    7: "7.Checkout",
};

export const getPaystubStepsNameInBar = step => {
    return paystubStepsNameInBar[step] || paystubStepsNameInBar[1];
};

export const paystubMaxStep = [...Object.keys(paystubStepsNameInBar)].length;

export const paystubStepLoadingMessages = {
    1: `Loading Step 1 of ${paystubMaxStep}`,
    2: `Loading Step 2 of ${paystubMaxStep}`,
    3: `Loading Step 3 of ${paystubMaxStep}`,
    4: `Loading Step 4 of ${paystubMaxStep}`,
    5: `Loading Step 5 of ${paystubMaxStep}`,
    6: "All done! Time to review",
    7: "Generating your Paystubs",
};

export const paystubMobileStepAboveText = {
    1: `Step 1 of ${paystubMaxStep}`,
    2: `Step 2 of ${paystubMaxStep}`,
    3: `Step 3 of ${paystubMaxStep}`,
};

export const allowTemplateForUploadCompanyLogo = [1, 3, 4];

export const get_pay_period_number_of_full_year = payment_frequency => {
    const map = {
        Daily: 365,
        "Weekly (ex: every Friday)": 52,
        "Bi-Weekly (ex: every other Wednesday)": 26,
        "Semi-Monthly (ex: 1st and 15th)": 24,
        "Monthly (ex: 1st of month only)": 12,
        Quarterly: 4,
        "Semi-Annually": 8,
        Annually: 1,
    };
    return map[payment_frequency] || map["Bi-Weekly (ex: every other Wednesday)"];
};
