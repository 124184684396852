import year_2023 from "./tax_brackets/year_2023";
import year_2022 from "./tax_brackets/year_2022";
import year_2021 from "./tax_brackets/year_2021";

const states_tax_brackets = {
    2023: year_2023,
    2022: year_2022,
    2021: year_2021,
};

export default states_tax_brackets;
