import storage from "../helpers/storage";

const GLOBAL_BILLING_ADDRESS = "global_billing_address";

export const storeBillingAddressToLocalStorage = billingAddress => {
    storage.set(GLOBAL_BILLING_ADDRESS, billingAddress);
};

export const getBillingAddressFromLocalStorage = () => {
    const { email, firstName, lastName, phone } = storage.get(GLOBAL_BILLING_ADDRESS) || {};
    return {
        initEmail: email || "",
        initFirstName: firstName || "",
        initLastName: lastName || "",
        initPhone: phone || "",
    };
};
