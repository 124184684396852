export const ten99NECStepsNameInBar = {
    1: "1.Payer",
    2: "2.Recipient",
    3: "3.Filling Info",
    4: "4.Income & tax",
    5: "5.Preview",
    6: "6.Checkout",
};

export const getTen99NECStepsNameInBar = step => {
    return ten99NECStepsNameInBar[step] || ten99NECStepsNameInBar[1];
};

export const ten99NECMaxStep = [...Object.keys(ten99NECStepsNameInBar)].length;

export const ten99NECStepLoadingMessages = {
    1: `Loading Step 1 of ${ten99NECMaxStep}`,
    2: `Loading Step 2 of ${ten99NECMaxStep}`,
    3: `Loading Step 3 of ${ten99NECMaxStep}`,
    4: `Loading Step 4 of ${ten99NECMaxStep}`,
    5: "All done! Time to preview",
    6: "Generating your 1099",
};

export const ten99NECMobileStepAboveText = {
    1: `Step 1 of ${ten99NECMaxStep}`,
    2: `Step 2 of ${ten99NECMaxStep}`,
    3: `Step 3 of ${ten99NECMaxStep}`,
};

export const ten99NECYears = [
    { label: "2021", value: "2021" },
    { label: "2022", value: "2022" },
    { label: "2023", value: "2023" },
];

export const ten99NECInitActiveStep = 1;
