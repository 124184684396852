import { configureStore } from "@reduxjs/toolkit";
import apiSlice from "./slices/api/apiSlice";
import globalSlice from "./slices/global/globalSlice";
import paystubSlice from "./slices/paystub/paystubSlice";
import profileSlice from "./slices/profile/profileSlice";
import ten99MISCSlice from "./slices/ten99MISC/ten99MISCSlice";
import w2Slice from "./slices/w2/w2Slice";
import ten99NECSlice from "./slices/ten99NEC/ten99NECSlice";

export const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        global: globalSlice.reducer,
        paystub: paystubSlice.reducer,
        profile: profileSlice.reducer,
        w2: w2Slice.reducer,
        ten99MISC: ten99MISCSlice.reducer,
        ten99NEC: ten99NECSlice.reducer,
    },
    middleware: gdm => gdm().concat(apiSlice.middleware),
});
