import { TEN99_NEC_CONTEXT } from "../configs/consts";

import storage from "../helpers/storage";
import tracker from "../helpers/tracker";

export const getTen99NECStep1Payer = () => {
    const {
        name = "",
        address = "",
        city = "",
        state = "",
        zipCode = "",
        payersTIN = "",
    } = storage.get2(storage.keys.TEN99_NEC_STEP_1_PAYER);

    return {
        initName: name,
        initAddress: address,
        initCity: city,
        initState: state,
        initZipCode: zipCode,
        initPayersTIN: payersTIN,
    };
};

export const getTen99NECStep2Recipient = () => {
    const {
        name = "",
        address = "",
        city = "",
        state = "",
        zipCode = "",
        recipientsTIN = "",
    } = storage.get2(storage.keys.TEN99_NEC_STEP_2_RECIPIENT);

    return {
        initRecipientName: name,
        initRecipientAddress: address,
        initRecipientCity: city,
        initRecipientState: state,
        initRecipientZipCode: zipCode,
        initRecipientsTIN: recipientsTIN,
    };
};

export const getTen99NECStep3FillingInformation = () => {
    const {
        year = 2023,
        accountNumber = "",
        secondTINNot = false,
        nonEmployeeCompensation = 0,
        isDirectSalesOrResaleSales = false,
        federalIncomeTaxWithheld = 0,
    } = storage.get2(storage.keys.TEN99_NEC_STEP_3_FILLING_INFORMATION);

    return {
        initYear: Number(year),
        initAccountNumber: accountNumber,
        initSecondTINNot: secondTINNot,
        initNonEmployeeCompensation: nonEmployeeCompensation,
        initFederalIncomeTaxWithheld: federalIncomeTaxWithheld,
        isDirectSalesOrResaleSales,
    };
};

export const getTen99NECStep4StateIncomeAndTax = () => {
    const {
        state1: {
            state: initState1State = "",
            payersStateNo: initState1PayersStateNo = "",
            stateTaxWithheld: initState1StateTaxWithheld = 0,
            stateIncome: initState1StateCome = 0,
        } = {},
        state2: {
            state: initState2State = "",
            payersStateNo: initState2PayersStateNo = "",
            stateTaxWithheld: initState2StateTaxWithheld = 0,
            stateIncome: initState2StateCome = 0,
        } = {},
    } = storage.get2(storage.keys.TEN99_NEC_STEP_4_STATE_INCOME_AND_TAX);

    return {
        initState1State,
        initState1PayersStateNo,
        initState1StateTaxWithheld,
        initState1StateCome,
        initState2State,
        initState2PayersStateNo,
        initState2StateTaxWithheld,
        initState2StateCome,
    };
};

export const storeTen99NECStepsToLocalStorage = (step, ten99NEC) => {
    switch (step) {
        case 1:
            return storage.set(storage.keys.TEN99_NEC_STEP_1_PAYER, ten99NEC.payer);
        case 2:
            return storage.set(storage.keys.TEN99_NEC_STEP_2_RECIPIENT, ten99NEC.recipient);
        case 3:
            return storage.set(storage.keys.TEN99_NEC_STEP_3_FILLING_INFORMATION, ten99NEC.filingInformation);
        case 4:
            return storage.set(
                storage.keys.TEN99_NEC_STEP_4_STATE_INCOME_AND_TAX,
                ten99NEC.stateIncomeAndTax
            );
        case 5:
            tracker.startedCheckoutTrack(TEN99_NEC_CONTEXT);
            break;
    }
};
