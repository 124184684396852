import dayjs from "dayjs";

const format_date = (date, format) => dayjs(date).format(format);

export const format_date_for_store = date => format_date(date, "YYYY-MM-DD");
export const format_date_for_presentation = date => format_date(date, "MMM D, YYYY");
export const format_date_month_day_year = date => format_date(date, "MM/DD/YYYY");
export const format_date_month_letter_day_year = date => format_date(date, "MMMM DD, YYYY");
export const format_date_day_month_year = date => format_date(date, "DD MMM YYYY");
export const format_created_at_date_time = dateTimeObj => format_date(dateTimeObj.date, "MMMM D, YYYY");

export const getTodayDate = () => {
    return format_date_for_store(dayjs());
};

export const getYear = date => {
    return dayjs(date).year();
};

const getTotalWorkingDays = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const weekdays = [0, 1, 2, 4, 5];

    let totalWorkingDays = 0;
    let currentDate = new Date(start);

    while (currentDate <= end) {
        if (weekdays.includes(currentDate.getDay())) {
            totalWorkingDays++;
        }
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return totalWorkingDays;
};

export const getDailyPayPeriodNumber = date => {
    const referenceDate = dayjs(date).startOf("year");
    return getTotalWorkingDays(referenceDate, date);
};

export const getWeeklyPayPeriodNumber = date => {
    const referenceDate = dayjs(date).startOf("year");
    const daysDifference = dayjs(date).diff(referenceDate, "day");
    return Math.floor(daysDifference / 7) + 1;
};

export const getBiWeeklyPayPeriodNumber = date => {
    const referenceDate = dayjs(date).startOf("year");
    const daysDifference = dayjs(date).diff(referenceDate, "day");
    return Math.floor(daysDifference / 14) + 1;
};

export const getSemiMonthlyPayPeriodNumber = date => {
    const referenceDate = dayjs(date).startOf("year");
    const monthsDifference = dayjs(date).diff(referenceDate, "month");
    const dayOfMonth = dayjs(date).date();

    let payPeriodNumber = monthsDifference * 2;

    if (dayOfMonth >= 1 && dayOfMonth <= 15) {
        payPeriodNumber += 1;
    } else if (dayOfMonth > 15) {
        payPeriodNumber += 2;
    }

    return payPeriodNumber;
};

export const getMonthlyPayPeriodNumber = date => {
    return dayjs(date).month() + 1;
};

export const getQuarterlyPayPeriodNumber = date => {
    const referenceDate = dayjs(date).startOf("year");
    const monthsDifference = dayjs(date).diff(referenceDate, "month");
    return Math.floor(monthsDifference / 3) + 1;
};

export const getSemiAnnuallyPayPeriodNumber = date => {
    const referenceDate = dayjs(date).startOf("year");
    const monthsDifference = dayjs(date).diff(referenceDate, "month");
    return Math.floor(monthsDifference / 6) + 1;
};

export const subtractDays = (date, numberOfDays) => {
    return dayjs(date).subtract(numberOfDays, "day");
};

export const subtractMonths = (date, numberOfMonths) => {
    return dayjs(date).subtract(numberOfMonths, "month");
};

export const subtractYears = (date, numberOfYears) => {
    return dayjs(date).subtract(numberOfYears, "year");
};

export const addDays = (date, numberOfDays) => {
    return dayjs(date).add(numberOfDays, "day");
};

export const addMonths = (date, numberOfMonths) => {
    return dayjs(date).add(numberOfMonths, "month");
};

export const addYears = (date, numberOfYears) => {
    return dayjs(date).add(numberOfYears, "year");
};

export const getDayDifferance = (startDate, endDate) => {
    return dayjs(endDate).diff(startDate, "day");
};
