export const is_valid_email = email => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

export const is_valid_fields = (fields, data) => {
    let isValid = true;
    const errors = {};
    Object.entries(fields).forEach(([field, label]) => {
        if (!data[field]) {
            errors[field] = `${label} is required`;
            isValid = false;
        }
    });
    return { isValid, errors };
};
