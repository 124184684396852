import {
    addDays,
    addMonths,
    addYears,
    format_date_for_store,
    getBiWeeklyPayPeriodNumber,
    getDailyPayPeriodNumber,
    getMonthlyPayPeriodNumber,
    getQuarterlyPayPeriodNumber,
    getSemiAnnuallyPayPeriodNumber,
    getSemiMonthlyPayPeriodNumber,
    getTodayDate,
    getWeeklyPayPeriodNumber,
    getYear,
    subtractDays,
    subtractMonths,
    subtractYears,
} from "../helpers/time";
import { format2DP, formatCommas3 } from "../helpers/formatters";
import { is_valid_email } from "../helpers/validation";
import { federalTax, ficaMedicareTex, ficaSocialSecurityTex, sdiTax, stateTax } from "./texes";
import { get_pay_period_number_of_full_year } from "../configs/paystub_config";

export const validatePaystubActiveStepRequiredFields = (step, generatePaystub) => {
    if (step === 2) {
        return validatePaystubDetails(generatePaystub.salary);
    }
    if (step === 3) {
        return validateCompany(generatePaystub.company);
    }
    if (step === 4) {
        return validateEmployeeInformation(generatePaystub.employee);
    }
    return { isValid: true, errors: {} };
};

const validatePaystubDetails = salary => {
    let isValid = true;
    const errors = {};

    if (!is_valid_email(salary.emailAddress)) {
        errors["emailAddress"] = "Email address have to be valid email";
        isValid = false;
    }
    if (salary.employmentType === "employee" && salary.stateToBeUsedInTaxCalculations === "") {
        errors["stateToBeUsedInTaxCalculations"] = "State is required";
        isValid = false;
    }

    return { isValid, errors };
};

const validateCompany = company => {
    let isValid = true;
    const errors = {};
    const requiredFields = {
        companyName: "Company name",
        companyStreetAddress1: "Company street address 1",
        companyCity: "Company city",
        companyState: "Company state",
        companyZipCode: "Company zip code",
    };

    Object.entries(requiredFields).forEach(([field, label]) => {
        if (company[field] === "") {
            errors[field] = `${label} is required`;
            isValid = false;
        }
    });

    return { isValid, errors };
};

const validateEmployeeInformation = employee => {
    let isValid = true;
    const errors = {};

    const requiredFields = {
        employeeName: "Employee name",
        employeeStreetAddress1: "Employee street address 1",
        employeeCity: "Employee city",
        employeeState: "Employee state",
        employeeZipCode: "Employee zip code",
        employeeAddress1: "Employee address 1",
        employeeSocial: "SSN is required",
    };

    if (employee.employeeDirectDeposit) {
        requiredFields["employeeDirectDepositValue"] = "Last 4 digit of your bank acct. number";
    }

    Object.entries(requiredFields).forEach(([field, label]) => {
        if (employee[field] === "") {
            errors[field] = `${label} is required`;
            isValid = false;
        }
    });

    return { isValid, errors };
};

export const calEarningTotal = (earning, paymentFrequency) => {
    let total;
    if (earning.paymentType === "hourly") {
        total = earning.rate * earning.hours;
    } else {
        if (earning?.id === 1) {
            total = earning.rate / calNumberOfSalaryCanGet(paymentFrequency);
        } else {
            total = earning.rate;
        }
    }
    return format2DP(total);
};

const calNumberOfSalaryCanGet = paymentFrequency => {
    const map = {
        Daily: 260,
        "Weekly (ex: every Friday)": 52,
        "Bi-Weekly (ex: every other Wednesday)": 26,
        "Semi-Monthly (ex: 1st and 15th)": 24,
        "Monthly (ex: 1st of month only)": 12,
        Quarterly: 4,
        "Semi-Annually": 2,
        Annually: 1,
    };
    return map[paymentFrequency] || 1;
};

export const calEarningYTDTotal = (earning, paymentFrequency) => {
    return calEarningTotal(earning, paymentFrequency) + earning.priorYTD;
};

// earnings sums
export const calSumOfEarningsTotal = (earnings, paymentFrequency) => {
    return earnings.reduce((sum, earning) => sum + calEarningTotal(earning, paymentFrequency), 0);
};

export const calDeductionYTDTotal = deduction => {
    return deduction.total + deduction.priorYTD;
};

// Deductions Sums
export const calSumOfDeductionTotal = deductions => {
    return deductions.reduce((sum, { total }) => sum + total, 0);
};

export const calSumOfDeductionPriorYTD = deductions => {
    return deductions.reduce((sum, { priorYTD }) => sum + priorYTD, 0);
};

export const calSumOfDeductionYTDTotal = deductions => {
    return deductions.reduce((sum, deduction) => sum + calDeductionYTDTotal(deduction), 0);
};

// Net Pay calculations
export const calNetPayTotal = (earnings, deductions, paymentFrequency) => {
    const sumOfEarningsTotal = calSumOfEarningsTotal(earnings, paymentFrequency);
    const sumOfDeductionsTotal = calSumOfDeductionTotal(deductions);
    return sumOfEarningsTotal - sumOfDeductionsTotal;
};

// Date calculations
export const calPayPeriodStart = (paymentFrequency, payPeriodEndX = getTodayDate()) => {
    const map = {
        Daily: subtractDays(payPeriodEndX, 1),
        "Weekly (ex: every Friday)": subtractDays(payPeriodEndX, 7),
        "Bi-Weekly (ex: every other Wednesday)": subtractDays(payPeriodEndX, 14),
        "Semi-Monthly (ex: 1st and 15th)": subtractDays(payPeriodEndX, 15),
        "Monthly (ex: 1st of month only)": subtractMonths(payPeriodEndX, 1),
        Quarterly: subtractMonths(payPeriodEndX, 3),
        "Semi-Annually": subtractMonths(payPeriodEndX, 6),
        Annually: subtractYears(payPeriodEndX, 1),
    };
    return format_date_for_store(addDays(map[paymentFrequency] || subtractDays(payPeriodEndX, 14), 1));
};

export const calPayPeriodEnd = (payPeriodStart, paymentFrequency) => {
    const map = {
        Daily: addDays(payPeriodStart, 1),
        "Weekly (ex: every Friday)": addDays(payPeriodStart, 7),
        "Bi-Weekly (ex: every other Wednesday)": addDays(payPeriodStart, 14),
        "Semi-Monthly (ex: 1st and 15th)": addDays(payPeriodStart, 15),
        "Monthly (ex: 1st of month only)": addMonths(payPeriodStart, 1),
        Quarterly: addMonths(payPeriodStart, 3),
        "Semi-Annually": addMonths(payPeriodStart, 6),
        Annually: addYears(payPeriodStart, 1),
    };
    return format_date_for_store(subtractDays(map[paymentFrequency] || addDays(payPeriodStart, 14), 1));
};

export const calPayDate = (payPeriodStart, paymentFrequency) => {
    return format_date_for_store(addDays(calPayPeriodEnd(payPeriodStart, paymentFrequency), 5));
};

export const calTotalPayPeriodNumber = (paystubs, paymentFrequency) => {
    const latest_year_paystubs = get_paystubs_of_latest_year(paystubs);
    const date = latest_year_paystubs[latest_year_paystubs.length - 1].payPeriodStart;
    const map = {
        Daily: getDailyPayPeriodNumber(date),
        "Weekly (ex: every Friday)": getWeeklyPayPeriodNumber(date),
        "Bi-Weekly (ex: every other Wednesday)": getBiWeeklyPayPeriodNumber(date),
        "Semi-Monthly (ex: 1st and 15th)": getSemiMonthlyPayPeriodNumber(date),
        "Monthly (ex: 1st of month only)": getMonthlyPayPeriodNumber(date),
        Quarterly: getQuarterlyPayPeriodNumber(date),
        "Semi-Annually": getSemiAnnuallyPayPeriodNumber(date),
        Annually: 1,
    };
    return map[paymentFrequency] || map["Bi-Weekly (ex: every other Wednesday)"];
};

export const calNumberPaystubsHave = paymentFrequency => {
    const map = {
        Daily: 52,
        "Weekly (ex: every Friday)": 52,
        "Bi-Weekly (ex: every other Wednesday)": 26,
        "Semi-Monthly (ex: 1st and 15th)": 24,
        "Monthly (ex: 1st of month only)": 12,
        Quarterly: 4,
        "Semi-Annually": 2,
        Annually: 1,
    };
    return map[paymentFrequency] || 1;
};

export const calDeductionTotal = (name, earnings, paymentFrequency, maritalStatus, state) => {
    const grossIncome = calSumOfEarningsTotal(earnings, paymentFrequency);
    return getDeductionTotal(grossIncome, name, paymentFrequency, maritalStatus, state);
};

const getDeductionTotal = (grossIncome, name, paymentFrequency, maritalStatus, state) => {
    switch (name) {
        case "FICA - Medicare":
            return ficaMedicareTex(grossIncome, maritalStatus);
        case "FICA - Social Security":
            return ficaSocialSecurityTex(grossIncome);
        case "Federal Tax":
            return federalTax(grossIncome, maritalStatus, calNumberOfSalaryCanGet(paymentFrequency));
        case "State Tax":
            return stateTax(
                grossIncome,
                state,
                maritalStatus,
                calNumberOfSalaryCanGet(paymentFrequency),
                2023
            );
        case "SDI":
            return sdiTax(grossIncome, state, maritalStatus, calNumberOfSalaryCanGet(paymentFrequency));
        default:
            return 0;
    }
};

export const cal_adjust_earning_prior_ytd = (
    paystubs,
    paymentFrequency,
    payPeriodNumber,
    isLastPriorYTDUnlocked = false
) => {
    if (!isLastPriorYTDUnlocked) {
        const latest_year_paystubs_length = get_paystubs_of_latest_year(paystubs).length;
        const latest_year_paystubs = paystubs.slice(0, latest_year_paystubs_length);
        const last_year_paystubs = paystubs.slice(latest_year_paystubs_length, paystubs.length);
        const max_pay_period_number = get_pay_period_number_of_full_year(paymentFrequency);
        return [
            ...final_earnings_prior_ytd_calculation(latest_year_paystubs, paymentFrequency, payPeriodNumber),
            ...final_earnings_prior_ytd_calculation(
                last_year_paystubs,
                paymentFrequency,
                max_pay_period_number - last_year_paystubs.length + 1
            ),
        ];
    }
    return final_earnings_prior_ytd_calculation(
        paystubs,
        paymentFrequency,
        payPeriodNumber,
        isLastPriorYTDUnlocked
    );
};

const get_paystubs_of_latest_year = paystubs => {
    const today_year = getYear(paystubs[0].payPeriodStart);
    return [...paystubs].filter(paystub => getYear(paystub.payPeriodStart) === today_year);
};

const final_earnings_prior_ytd_calculation = (
    paystubs,
    paymentFrequency,
    payPeriodNumber,
    isLastPriorYTDUnlocked
) => {
    if (paystubs.length === 0) {
        return [];
    }
    const latest_paystubs = paystubs.reverse();

    const latest_last_paystub = {
        ...latest_paystubs[0],
        earnings: latest_paystubs[0].earnings.map(earning => ({
            ...earning,
            priorYTD:
                !isLastPriorYTDUnlocked || earning.priorYTD === -1000
                    ? format2DP(calEarningTotal(earning, paymentFrequency) * (payPeriodNumber - 1))
                    : earning.priorYTD,
        })),
    };
    let last_earnings = [...latest_last_paystub.earnings];
    return latest_paystubs
        .map((paystub, index) => {
            if (index === 0) {
                return latest_last_paystub;
            }
            const tmp_earnings = [];
            const tmp_paystub = {
                ...paystub,
                earnings: paystub.earnings.map(earning => {
                    const last_earning = last_earnings.find(e => e.label === earning.label);
                    const tmp_earning = {
                        ...earning,
                        priorYTD: format2DP(
                            last_earning.priorYTD + calEarningTotal(last_earning, paymentFrequency)
                        ),
                    };
                    tmp_earnings.push(tmp_earning);
                    return tmp_earning;
                }),
            };
            last_earnings.forEach(e => {
                const x = tmp_earnings.find(y => y.label === e.label);
                if (!x) {
                    tmp_earnings.push(e);
                }
            });
            last_earnings = [...tmp_earnings];
            return tmp_paystub;
        })
        .reverse();
};

export const calAdjustDeductionsPriorYTD = (
    paystubs,
    paymentFrequency,
    payPeriodNumber,
    maritalStatus,
    state,
    isLastPriorYTDUnlocked = false
) => {
    if (!isLastPriorYTDUnlocked) {
        const latest_year_paystubs_length = get_paystubs_of_latest_year(paystubs).length;
        const latest_year_paystubs = paystubs.slice(0, latest_year_paystubs_length);
        const last_year_paystubs = paystubs.slice(latest_year_paystubs_length, paystubs.length);
        const max_pay_period_number = get_pay_period_number_of_full_year(paymentFrequency);
        return [
            ...final_deductions_prior_ytd_calculation(latest_year_paystubs, payPeriodNumber),
            ...final_deductions_prior_ytd_calculation(
                last_year_paystubs,
                max_pay_period_number - last_year_paystubs.length + 1
            ),
        ];
    }
    return final_deductions_prior_ytd_calculation(
        paystubs,
        payPeriodNumber,
        isLastPriorYTDUnlocked,
        paymentFrequency,
        maritalStatus,
        state
    );
};

const final_deductions_prior_ytd_calculation = (
    paystubs,
    payPeriodNumber,
    isLastPriorYTDUnlocked,
    paymentFrequency,
    maritalStatus,
    state
) => {
    if (paystubs.length === 0) {
        return [];
    }
    const latest_paystubs = paystubs.reverse();
    const latest_last_paystub = {
        ...latest_paystubs[0],
        deductions: latest_paystubs[0].deductions.map(deduction => ({
            ...deduction,
            priorYTD: !isLastPriorYTDUnlocked
                ? format2DP(deduction.total * (payPeriodNumber - 1))
                : calDeductionsPriorYTDFromSumEarningsPriorYTD(
                      calSumOfEarningsPriorYTD(latest_paystubs[0].earnings),
                      deduction.name,
                      paymentFrequency,
                      maritalStatus,
                      state
                  ),
        })),
    };
    let last_deductions = [...latest_last_paystub.deductions];
    return latest_paystubs
        .map((paystub, index) => {
            if (index === 0) {
                return latest_last_paystub;
            }
            const tmp_deductions = [];
            const tmp_paystub = {
                ...paystub,
                deductions: paystub.deductions.map(deduction => {
                    const last_deduction = last_deductions.find(d => d.name === deduction.name);
                    const tmp_deduction = {
                        ...deduction,
                        priorYTD: format2DP(last_deduction.priorYTD + last_deduction.total),
                    };
                    tmp_deductions.push(tmp_deduction);
                    return tmp_deduction;
                }),
            };
            last_deductions.forEach(d => {
                const x = tmp_deductions.find(y => y.name === d.name);
                if (!x) {
                    tmp_deductions.push(d);
                }
            });
            last_deductions = [...tmp_deductions];
            return tmp_paystub;
        })
        .reverse();
};

const calDeductionsPriorYTDFromSumEarningsPriorYTD = (
    sumOfEarningsPriorYTD,
    name,
    paymentFrequency,
    maritalStatus,
    state
) => {
    return getDeductionTotal(sumOfEarningsPriorYTD, name, paymentFrequency, maritalStatus, state);
};

export const calMakeAllDeductionsZero = deductions => {
    return deductions.map(deduction => ({
        ...deduction,
        total: 0,
        priorYTD: 0,
    }));
};

export const calMakeAllDeductionsZeroAllPaystubs = paystubs => {
    return paystubs.map(paystub => ({
        ...paystub,
        deductions: calMakeAllDeductionsZero(paystub.deductions),
    }));
};

export const calAdjustDeductionsTotal = (paystubs, paymentFrequency, maritalStatus, state) => {
    return paystubs.map(paystub => {
        return {
            ...paystub,
            deductions: paystub.deductions.map(deduction => {
                return {
                    ...deduction,
                    total: calDeductionTotal(
                        deduction.name,
                        paystub.earnings,
                        paymentFrequency,
                        maritalStatus,
                        state
                    ),
                };
            }),
        };
    });
};

export const calAdjustSDIDeductions = paystubs => {
    return paystubs.map(paystub => {
        return {
            ...paystub,
            deductions: paystub.deductions.find(deduction => deduction.name === "SDI")
                ? paystub.deductions
                : [
                      ...paystub.deductions,
                      {
                          deleteAble: false,
                          name: "SDI",
                          total: 0,
                          priorYTD: 0,
                      },
                  ],
        };
    });
};

export const calDeleteSDIDeductions = paystubs => {
    return paystubs.map(paystub => {
        return {
            ...paystub,
            deductions: paystub.deductions.filter(deduction => deduction.name !== "SDI"),
        };
    });
};

export const getEarningRegularHoursMax = paymentFrequency => {
    const x = {
        "Weekly (ex: every Friday)": 40,
        "Bi-Weekly (ex: every other Wednesday)": 80,
    };

    return x[paymentFrequency] || 0;
};

export const getEarningHoursForDisplay = earning => {
    if (earning.paymentType === "hourly") return formatCommas3(earning.hours);
    return "-";
};

const calSumOfEarningsPriorYTD = earnings => {
    return earnings.reduce((sum, { priorYTD }) => sum + priorYTD, 0);
};

export const calculate_earnings_prior_ytd_sum = (paystubs, paystub_no, payment_frequency) => {
    let tmp_paystubs = [...paystubs].slice(paystub_no - 1).reverse();
    if (tmp_paystubs.length === 1) {
        return calSumOfEarningsPriorYTD(tmp_paystubs[0].earnings);
    }
    tmp_paystubs = tmp_paystubs.filter(
        x => getYear(x.payPeriodStart) === getYear(paystubs[paystub_no - 1].payPeriodStart)
    );
    tmp_paystubs.pop();
    if (tmp_paystubs.length === 0) return 0;
    return tmp_paystubs.reduce(
        (previous, current) => previous + calSumOfEarningsTotal(current.earnings, payment_frequency),
        calSumOfEarningsPriorYTD(tmp_paystubs[0].earnings)
    );
};

export const calculate_earnings_ytd_total_sum = (paystubs, paystub_no, payment_frequency) => {
    return (
        calculate_earnings_prior_ytd_sum(paystubs, paystub_no, payment_frequency) +
        calSumOfEarningsTotal(paystubs[paystub_no - 1].earnings, payment_frequency)
    );
};

export const calculate_net_pay_prior_ytd = (paystubs, paystub_no, payment_frequency) => {
    const sumOfEarningsPriorYTD = calculate_earnings_prior_ytd_sum(paystubs, paystub_no, payment_frequency);
    const sumOfDeductionsPriorYTD = calSumOfDeductionPriorYTD(paystubs[paystub_no - 1].deductions);
    return sumOfEarningsPriorYTD - sumOfDeductionsPriorYTD;
};
export const calculate_net_pay_ytd_total = (paystubs, paystub_no, payment_frequency) => {
    const sumOfEarningsYTDTotal = calculate_earnings_ytd_total_sum(paystubs, paystub_no, payment_frequency);
    const sumOfDeductionsYTDTotal = calSumOfDeductionYTDTotal(paystubs[paystub_no - 1].deductions);
    return sumOfEarningsYTDTotal - sumOfDeductionsYTDTotal;
};
