// https://taxfoundation.org/state-income-tax-rates-2021/

export default {
    AL: {
        single: {
            brackets: [
                {
                    limit: 500,
                    rate: 0.02,
                },
                {
                    limit: 3000,
                    rate: 0.04,
                },
                {
                    limit: Infinity,
                    rate: 0.05,
                },
            ],
            standardDeduction: 2500,
        },
        married: {
            brackets: [
                {
                    limit: 1000,
                    rate: 0.02,
                },
                {
                    limit: 6000,
                    rate: 0.04,
                },
                {
                    limit: Infinity,
                    rate: 0.05,
                },
            ],
            standardDeduction: 7500,
        },
    },
    AZ: {
        single: {
            brackets: [
                {
                    limit: 27272,
                    rate: 0.0259,
                },
                {
                    limit: 54544,
                    rate: 0.0334,
                },
                {
                    limit: 163632,
                    rate: 0.0417,
                },
                {
                    limit: 250000,
                    rate: 0.045,
                },
                {
                    limit: Infinity,
                    rate: 0.08,
                },
            ],
            standardDeduction: 12550,
        },
        married: {
            brackets: [
                {
                    limit: 54544,
                    rate: 0.0259,
                },
                {
                    limit: 109088,
                    rate: 0.0334,
                },
                {
                    limit: 327263,
                    rate: 0.0417,
                },
                {
                    limit: 500000,
                    rate: 0.045,
                },
                {
                    limit: Infinity,
                    rate: 0.08,
                },
            ],
            standardDeduction: 25100,
        },
    },
    AR: {
        single: {
            brackets: [
                {
                    limit: 4000,
                    rate: 0.02,
                },
                {
                    limit: 8000,
                    rate: 0.04,
                },
                {
                    limit: Infinity,
                    rate: 0.059000000000000004,
                },
            ],
            standardDeduction: 2200,
        },
        married: {
            brackets: [
                {
                    limit: 4000,
                    rate: 0.02,
                },
                {
                    limit: 8000,
                    rate: 0.04,
                },
                {
                    limit: Infinity,
                    rate: 0.059000000000000004,
                },
            ],
            standardDeduction: 4400,
        },
    },
    CA: {
        single: {
            brackets: [
                {
                    limit: 8932,
                    rate: 0.01,
                },
                {
                    limit: 21175,
                    rate: 0.02,
                },
                {
                    limit: 33421,
                    rate: 0.04,
                },
                {
                    limit: 46394,
                    rate: 0.06,
                },
                {
                    limit: 58634,
                    rate: 0.08,
                },
                {
                    limit: 299508,
                    rate: 0.09300000000000001,
                },
                {
                    limit: 359407,
                    rate: 0.10300000000000001,
                },
                {
                    limit: 599012,
                    rate: 0.113,
                },
                {
                    limit: 1000000,
                    rate: 0.12300000000000001,
                },
                {
                    limit: Infinity,
                    rate: 0.133,
                },
            ],
            standardDeduction: 4601,
        },
        married: {
            brackets: [
                {
                    limit: 17864,
                    rate: 0.01,
                },
                {
                    limit: 42350,
                    rate: 0.02,
                },
                {
                    limit: 66842,
                    rate: 0.04,
                },
                {
                    limit: 92788,
                    rate: 0.06,
                },
                {
                    limit: 117268,
                    rate: 0.08,
                },
                {
                    limit: 599016,
                    rate: 0.09300000000000001,
                },
                {
                    limit: 718814,
                    rate: 0.10300000000000001,
                },
                {
                    limit: 1000000,
                    rate: 0.113,
                },
                {
                    limit: 1198024,
                    rate: 0.12300000000000001,
                },
                {
                    limit: Infinity,
                    rate: 0.133,
                },
            ],
            standardDeduction: 9202,
        },
    },
    CO: {
        single: {
            brackets: [
                {
                    limit: Infinity,
                    rate: 0.0455,
                },
            ],
            standardDeduction: 12550,
        },
        married: {
            brackets: [
                {
                    limit: Infinity,
                    rate: 0.0455,
                },
            ],
            standardDeduction: 25100,
        },
    },
    CT: {
        single: {
            brackets: [
                {
                    limit: 10000,
                    rate: 0.03,
                },
                {
                    limit: 50000,
                    rate: 0.05,
                },
                {
                    limit: 100000,
                    rate: 0.055,
                },
                {
                    limit: 200000,
                    rate: 0.06,
                },
                {
                    limit: 250000,
                    rate: 0.065,
                },
                {
                    limit: 500000,
                    rate: 0.069,
                },
                {
                    limit: Infinity,
                    rate: 0.0699,
                },
            ],
            standardDeduction: null,
        },
        married: {
            brackets: [
                {
                    limit: 20000,
                    rate: 0.03,
                },
                {
                    limit: 100000,
                    rate: 0.05,
                },
                {
                    limit: 200000,
                    rate: 0.055,
                },
                {
                    limit: 400000,
                    rate: 0.06,
                },
                {
                    limit: 500000,
                    rate: 0.065,
                },
                {
                    limit: 1000000,
                    rate: 0.069,
                },
                {
                    limit: Infinity,
                    rate: 0.0699,
                },
            ],
            standardDeduction: null,
        },
    },
    DE: {
        single: {
            brackets: [
                {
                    limit: 5000,
                    rate: 0.022000000000000002,
                },
                {
                    limit: 10000,
                    rate: 0.039,
                },
                {
                    limit: 20000,
                    rate: 0.048,
                },
                {
                    limit: 25000,
                    rate: 0.052000000000000005,
                },
                {
                    limit: 60000,
                    rate: 0.0555,
                },
                {
                    limit: Infinity,
                    rate: 0.066,
                },
            ],
            standardDeduction: 3250,
        },
        married: {
            brackets: [
                {
                    limit: 5000,
                    rate: 0.022000000000000002,
                },
                {
                    limit: 10000,
                    rate: 0.039,
                },
                {
                    limit: 20000,
                    rate: 0.048,
                },
                {
                    limit: 25000,
                    rate: 0.052000000000000005,
                },
                {
                    limit: 60000,
                    rate: 0.0555,
                },
                {
                    limit: Infinity,
                    rate: 0.066,
                },
            ],
            standardDeduction: 6500,
        },
    },
    GA: {
        single: {
            brackets: [
                {
                    limit: 750,
                    rate: 0.01,
                },
                {
                    limit: 2250,
                    rate: 0.02,
                },
                {
                    limit: 3750,
                    rate: 0.03,
                },
                {
                    limit: 5250,
                    rate: 0.04,
                },
                {
                    limit: 7000,
                    rate: 0.05,
                },
                {
                    limit: Infinity,
                    rate: 0.0575,
                },
            ],
            standardDeduction: 4600,
        },
        married: {
            brackets: [
                {
                    limit: 1000,
                    rate: 0.01,
                },
                {
                    limit: 3000,
                    rate: 0.02,
                },
                {
                    limit: 5000,
                    rate: 0.03,
                },
                {
                    limit: 7000,
                    rate: 0.04,
                },
                {
                    limit: 10000,
                    rate: 0.05,
                },
                {
                    limit: Infinity,
                    rate: 0.0575,
                },
            ],
            standardDeduction: 6000,
        },
    },
    HI: {
        single: {
            brackets: [
                {
                    limit: 2400,
                    rate: 0.013999999999999999,
                },
                {
                    limit: 4800,
                    rate: 0.032,
                },
                {
                    limit: 9600,
                    rate: 0.055,
                },
                {
                    limit: 14400,
                    rate: 0.064,
                },
                {
                    limit: 19200,
                    rate: 0.068,
                },
                {
                    limit: 24000,
                    rate: 0.07200000000000001,
                },
                {
                    limit: 36000,
                    rate: 0.076,
                },
                {
                    limit: 48000,
                    rate: 0.079,
                },
                {
                    limit: 150000,
                    rate: 0.0825,
                },
                {
                    limit: 175000,
                    rate: 0.09,
                },
                {
                    limit: 200000,
                    rate: 0.1,
                },
                {
                    limit: Infinity,
                    rate: 0.11,
                },
            ],
            standardDeduction: 2200,
        },
        married: {
            brackets: [
                {
                    limit: 4800,
                    rate: 0.013999999999999999,
                },
                {
                    limit: 9600,
                    rate: 0.032,
                },
                {
                    limit: 19200,
                    rate: 0.055,
                },
                {
                    limit: 28800,
                    rate: 0.064,
                },
                {
                    limit: 38400,
                    rate: 0.068,
                },
                {
                    limit: 48000,
                    rate: 0.07200000000000001,
                },
                {
                    limit: 72000,
                    rate: 0.076,
                },
                {
                    limit: 96000,
                    rate: 0.079,
                },
                {
                    limit: 300000,
                    rate: 0.0825,
                },
                {
                    limit: 350000,
                    rate: 0.09,
                },
                {
                    limit: 400000,
                    rate: 0.1,
                },
                {
                    limit: Infinity,
                    rate: 0.11,
                },
            ],
            standardDeduction: 4400,
        },
    },
    ID: {
        single: {
            brackets: [
                {
                    limit: 1568,
                    rate: 0.01125,
                },
                {
                    limit: 3136,
                    rate: 0.03125,
                },
                {
                    limit: 4704,
                    rate: 0.03625,
                },
                {
                    limit: 6272,
                    rate: 0.04625,
                },
                {
                    limit: 7840,
                    rate: 0.05625,
                },
                {
                    limit: 11760,
                    rate: 0.06625,
                },
                {
                    limit: Infinity,
                    rate: 0.06924999999999999,
                },
            ],
            standardDeduction: 12550,
        },
        married: {
            brackets: [
                {
                    limit: 3136,
                    rate: 0.01125,
                },
                {
                    limit: 6272,
                    rate: 0.03125,
                },
                {
                    limit: 9408,
                    rate: 0.03625,
                },
                {
                    limit: 12544,
                    rate: 0.04625,
                },
                {
                    limit: 15680,
                    rate: 0.05625,
                },
                {
                    limit: 23520,
                    rate: 0.06625,
                },
                {
                    limit: Infinity,
                    rate: 0.06924999999999999,
                },
            ],
            standardDeduction: 25100,
        },
    },
    IL: {
        single: {
            brackets: [
                {
                    limit: Infinity,
                    rate: 0.0495,
                },
            ],
            standardDeduction: null,
        },
        married: {
            brackets: [
                {
                    limit: Infinity,
                    rate: 0.0495,
                },
            ],
            standardDeduction: null,
        },
    },
    IN: {
        single: {
            brackets: [
                {
                    limit: Infinity,
                    rate: 0.0323,
                },
            ],
            standardDeduction: null,
        },
        married: {
            brackets: [
                {
                    limit: Infinity,
                    rate: 0.0323,
                },
            ],
            standardDeduction: null,
        },
    },
    IA: {
        single: {
            brackets: [
                {
                    limit: 1676,
                    rate: 0.0033,
                },
                {
                    limit: 3352,
                    rate: 0.0067,
                },
                {
                    limit: 6704,
                    rate: 0.0225,
                },
                {
                    limit: 15084,
                    rate: 0.0414,
                },
                {
                    limit: 25140,
                    rate: 0.056299999999999996,
                },
                {
                    limit: 33520,
                    rate: 0.0596,
                },
                {
                    limit: 50280,
                    rate: 0.0625,
                },
                {
                    limit: 75420,
                    rate: 0.07440000000000001,
                },
                {
                    limit: Infinity,
                    rate: 0.08529999999999999,
                },
            ],
            standardDeduction: 2130,
        },
        married: {
            brackets: [
                {
                    limit: 1676,
                    rate: 0.0033,
                },
                {
                    limit: 3352,
                    rate: 0.0067,
                },
                {
                    limit: 6704,
                    rate: 0.0225,
                },
                {
                    limit: 15084,
                    rate: 0.0414,
                },
                {
                    limit: 25140,
                    rate: 0.056299999999999996,
                },
                {
                    limit: 33520,
                    rate: 0.0596,
                },
                {
                    limit: 50280,
                    rate: 0.0625,
                },
                {
                    limit: 75420,
                    rate: 0.07440000000000001,
                },
                {
                    limit: Infinity,
                    rate: 0.08529999999999999,
                },
            ],
            standardDeduction: 5240,
        },
    },
    KS: {
        single: {
            brackets: [
                {
                    limit: 15000,
                    rate: 0.031,
                },
                {
                    limit: 30000,
                    rate: 0.0525,
                },
                {
                    limit: Infinity,
                    rate: 0.057,
                },
            ],
            standardDeduction: 3000,
        },
        married: {
            brackets: [
                {
                    limit: 30000,
                    rate: 0.031,
                },
                {
                    limit: 60000,
                    rate: 0.0525,
                },
                {
                    limit: Infinity,
                    rate: 0.057,
                },
            ],
            standardDeduction: 7500,
        },
    },
    KY: {
        single: {
            brackets: [
                {
                    limit: Infinity,
                    rate: 0.05,
                },
            ],
            standardDeduction: 2690,
        },
        married: {
            brackets: [
                {
                    limit: Infinity,
                    rate: 0.05,
                },
            ],
            standardDeduction: 5380,
        },
    },
    LA: {
        single: {
            brackets: [
                {
                    limit: 12500,
                    rate: 0.02,
                },
                {
                    limit: 50000,
                    rate: 0.04,
                },
                {
                    limit: Infinity,
                    rate: 0.06,
                },
            ],
            standardDeduction: null,
        },
        married: {
            brackets: [
                {
                    limit: 25000,
                    rate: 0.02,
                },
                {
                    limit: 100000,
                    rate: 0.04,
                },
                {
                    limit: Infinity,
                    rate: 0.06,
                },
            ],
            standardDeduction: null,
        },
    },
    ME: {
        single: {
            brackets: [
                {
                    limit: 22450,
                    rate: 0.057999999999999996,
                },
                {
                    limit: 53150,
                    rate: 0.0675,
                },
                {
                    limit: Infinity,
                    rate: 0.07150000000000001,
                },
            ],
            standardDeduction: 12550,
        },
        married: {
            brackets: [
                {
                    limit: 44950,
                    rate: 0.057999999999999996,
                },
                {
                    limit: 106350,
                    rate: 0.0675,
                },
                {
                    limit: Infinity,
                    rate: 0.07150000000000001,
                },
            ],
            standardDeduction: 25100,
        },
    },
    MD: {
        single: {
            brackets: [
                {
                    limit: 1000,
                    rate: 0.02,
                },
                {
                    limit: 2000,
                    rate: 0.03,
                },
                {
                    limit: 3000,
                    rate: 0.04,
                },
                {
                    limit: 100000,
                    rate: 0.0475,
                },
                {
                    limit: 125000,
                    rate: 0.05,
                },
                {
                    limit: 150000,
                    rate: 0.0525,
                },
                {
                    limit: 250000,
                    rate: 0.055,
                },
                {
                    limit: Infinity,
                    rate: 0.0575,
                },
            ],
            standardDeduction: 2300,
        },
        married: {
            brackets: [
                {
                    limit: 1000,
                    rate: 0.02,
                },
                {
                    limit: 2000,
                    rate: 0.03,
                },
                {
                    limit: 3000,
                    rate: 0.04,
                },
                {
                    limit: 150000,
                    rate: 0.0475,
                },
                {
                    limit: 175000,
                    rate: 0.05,
                },
                {
                    limit: 225000,
                    rate: 0.0525,
                },
                {
                    limit: 300000,
                    rate: 0.055,
                },
                {
                    limit: Infinity,
                    rate: 0.0575,
                },
            ],
            standardDeduction: 4650,
        },
    },
    MA: {
        single: {
            brackets: [
                {
                    limit: Infinity,
                    rate: 0.05,
                },
            ],
            standardDeduction: null,
        },
        married: {
            brackets: [
                {
                    limit: Infinity,
                    rate: 0.05,
                },
            ],
            standardDeduction: null,
        },
    },
    MI: {
        single: {
            brackets: [
                {
                    limit: Infinity,
                    rate: 0.0425,
                },
            ],
            standardDeduction: null,
        },
        married: {
            brackets: [
                {
                    limit: Infinity,
                    rate: 0.0425,
                },
            ],
            standardDeduction: null,
        },
    },
    MN: {
        single: {
            brackets: [
                {
                    limit: 27230,
                    rate: 0.0535,
                },
                {
                    limit: 89440,
                    rate: 0.068,
                },
                {
                    limit: 166040,
                    rate: 0.0785,
                },
                {
                    limit: Infinity,
                    rate: 0.09849999999999999,
                },
            ],
            standardDeduction: 12525,
        },
        married: {
            brackets: [
                {
                    limit: 39810,
                    rate: 0.0535,
                },
                {
                    limit: 158140,
                    rate: 0.068,
                },
                {
                    limit: 276200,
                    rate: 0.0785,
                },
                {
                    limit: Infinity,
                    rate: 0.09849999999999999,
                },
            ],
            standardDeduction: 25050,
        },
    },
    MS: {
        single: {
            brackets: [
                {
                    rate: 0,
                    limit: 4000,
                },
                {
                    limit: 5000,
                    rate: 0.03,
                },
                {
                    limit: 10000,
                    rate: 0.04,
                },
                {
                    limit: Infinity,
                    rate: 0.05,
                },
            ],
            standardDeduction: 2300,
        },
        married: {
            brackets: [
                {
                    rate: 0,
                    limit: 4000,
                },
                {
                    limit: 5000,
                    rate: 0.03,
                },
                {
                    limit: 10000,
                    rate: 0.04,
                },
                {
                    limit: Infinity,
                    rate: 0.05,
                },
            ],
            standardDeduction: 4600,
        },
    },
    MO: {
        single: {
            brackets: [
                {
                    limit: 1073,
                    rate: 0.015,
                },
                {
                    limit: 2146,
                    rate: 0.02,
                },
                {
                    limit: 3219,
                    rate: 0.025,
                },
                {
                    limit: 4292,
                    rate: 0.03,
                },
                {
                    limit: 5365,
                    rate: 0.035,
                },
                {
                    limit: 6438,
                    rate: 0.04,
                },
                {
                    limit: 7511,
                    rate: 0.045,
                },
                {
                    limit: 8584,
                    rate: 0.05,
                },
                {
                    limit: Infinity,
                    rate: 0.054000000000000006,
                },
            ],
            standardDeduction: 12550,
        },
        married: {
            brackets: [
                {
                    limit: 1073,
                    rate: 0.015,
                },
                {
                    limit: 2146,
                    rate: 0.02,
                },
                {
                    limit: 3219,
                    rate: 0.025,
                },
                {
                    limit: 4292,
                    rate: 0.03,
                },
                {
                    limit: 5365,
                    rate: 0.035,
                },
                {
                    limit: 6438,
                    rate: 0.04,
                },
                {
                    limit: 7511,
                    rate: 0.045,
                },
                {
                    limit: 8584,
                    rate: 0.05,
                },
                {
                    limit: Infinity,
                    rate: 0.054000000000000006,
                },
            ],
            standardDeduction: 25100,
        },
    },
    MT: {
        single: {
            brackets: [
                {
                    limit: 3100,
                    rate: 0.01,
                },
                {
                    limit: 5500,
                    rate: 0.02,
                },
                {
                    limit: 8400,
                    rate: 0.03,
                },
                {
                    limit: 11300,
                    rate: 0.04,
                },
                {
                    limit: 14500,
                    rate: 0.05,
                },
                {
                    limit: 18700,
                    rate: 0.06,
                },
                {
                    limit: Infinity,
                    rate: 0.069,
                },
            ],
            standardDeduction: 4790,
        },
        married: {
            brackets: [
                {
                    limit: 3100,
                    rate: 0.01,
                },
                {
                    limit: 5500,
                    rate: 0.02,
                },
                {
                    limit: 8400,
                    rate: 0.03,
                },
                {
                    limit: 11300,
                    rate: 0.04,
                },
                {
                    limit: 14500,
                    rate: 0.05,
                },
                {
                    limit: 18700,
                    rate: 0.06,
                },
                {
                    limit: Infinity,
                    rate: 0.069,
                },
            ],
            standardDeduction: 9580,
        },
    },
    NE: {
        single: {
            brackets: [
                {
                    limit: 3340,
                    rate: 0.0246,
                },
                {
                    limit: 19990,
                    rate: 0.0351,
                },
                {
                    limit: 32210,
                    rate: 0.0501,
                },
                {
                    limit: Infinity,
                    rate: 0.0684,
                },
            ],
            standardDeduction: 7100,
        },
        married: {
            brackets: [
                {
                    limit: 6660,
                    rate: 0.0246,
                },
                {
                    limit: 39990,
                    rate: 0.0351,
                },
                {
                    limit: 64430,
                    rate: 0.0501,
                },
                {
                    limit: Infinity,
                    rate: 0.0684,
                },
            ],
            standardDeduction: 14200,
        },
    },
    NH: {
        single: {
            brackets: [
                {
                    limit: Infinity,
                    rate: 0.05,
                },
            ],
            standardDeduction: null,
        },
        married: {
            brackets: [
                {
                    limit: Infinity,
                    rate: 0.05,
                },
            ],
            standardDeduction: null,
        },
    },
    NJ: {
        single: {
            brackets: [
                {
                    limit: 20000,
                    rate: 0.013999999999999999,
                },
                {
                    limit: 35000,
                    rate: 0.0175,
                },
                {
                    limit: 40000,
                    rate: 0.035,
                },
                {
                    limit: 75000,
                    rate: 0.05525,
                },
                {
                    limit: 500000,
                    rate: 0.0637,
                },
                {
                    limit: 1000000,
                    rate: 0.0897,
                },
                {
                    limit: Infinity,
                    rate: 0.1075,
                },
                {
                    limit: Infinity,
                    rate: null,
                },
            ],
            standardDeduction: null,
        },
        married: {
            brackets: [
                {
                    limit: 20000,
                    rate: 0.013999999999999999,
                },
                {
                    limit: 50000,
                    rate: 0.0175,
                },
                {
                    limit: 70000,
                    rate: 0.0245,
                },
                {
                    limit: 80000,
                    rate: 0.035,
                },
                {
                    limit: 150000,
                    rate: 0.05525,
                },
                {
                    limit: 500000,
                    rate: 0.0637,
                },
                {
                    limit: 1000000,
                    rate: 0.0897,
                },
                {
                    limit: Infinity,
                    rate: 0.1075,
                },
            ],
            standardDeduction: null,
        },
    },
    NM: {
        single: {
            brackets: [
                {
                    limit: 5500,
                    rate: 0.017,
                },
                {
                    limit: 11000,
                    rate: 0.032,
                },
                {
                    limit: 16000,
                    rate: 0.047,
                },
                {
                    limit: 210000,
                    rate: 0.049,
                },
                {
                    limit: Infinity,
                    rate: 0.059000000000000004,
                },
            ],
            standardDeduction: 12550,
        },
        married: {
            brackets: [
                {
                    limit: 8000,
                    rate: 0.017,
                },
                {
                    limit: 16000,
                    rate: 0.032,
                },
                {
                    limit: 24000,
                    rate: 0.047,
                },
                {
                    limit: 315000,
                    rate: 0.049,
                },
                {
                    limit: Infinity,
                    rate: 0.059000000000000004,
                },
            ],
            standardDeduction: 25100,
        },
    },
    NY: {
        single: {
            brackets: [
                {
                    limit: 8500,
                    rate: 0.04,
                },
                {
                    limit: 11700,
                    rate: 0.045,
                },
                {
                    limit: 13900,
                    rate: 0.0525,
                },
                {
                    limit: 21400,
                    rate: 0.059000000000000004,
                },
                {
                    limit: 80650,
                    rate: 0.059699999999999996,
                },
                {
                    limit: 215400,
                    rate: 0.0633,
                },
                {
                    limit: 1077550,
                    rate: 0.06849999999999999,
                },
                {
                    limit: Infinity,
                    rate: 0.0882,
                },
            ],
            standardDeduction: 8000,
        },
        married: {
            brackets: [
                {
                    limit: 17150,
                    rate: 0.04,
                },
                {
                    limit: 23600,
                    rate: 0.045,
                },
                {
                    limit: 27900,
                    rate: 0.0525,
                },
                {
                    limit: 43000,
                    rate: 0.059000000000000004,
                },
                {
                    limit: 161550,
                    rate: 0.059699999999999996,
                },
                {
                    limit: 323200,
                    rate: 0.0633,
                },
                {
                    limit: 2155350,
                    rate: 0.06849999999999999,
                },
                {
                    limit: Infinity,
                    rate: 0.0882,
                },
            ],
            standardDeduction: 16050,
        },
    },
    NC: {
        single: {
            brackets: [
                {
                    limit: Infinity,
                    rate: 0.0525,
                },
            ],
            standardDeduction: 10750,
        },
        married: {
            brackets: [
                {
                    limit: Infinity,
                    rate: 0.0525,
                },
            ],
            standardDeduction: 21500,
        },
    },
    ND: {
        single: {
            brackets: [
                {
                    limit: 40125,
                    rate: 0.011000000000000001,
                },
                {
                    limit: 97150,
                    rate: 0.0204,
                },
                {
                    limit: 202650,
                    rate: 0.0227,
                },
                {
                    limit: 440600,
                    rate: 0.0264,
                },
                {
                    limit: Infinity,
                    rate: 0.028999999999999998,
                },
            ],
            standardDeduction: 12550,
        },
        married: {
            brackets: [
                {
                    limit: 67050,
                    rate: 0.011000000000000001,
                },
                {
                    limit: 161950,
                    rate: 0.0204,
                },
                {
                    limit: 246700,
                    rate: 0.0227,
                },
                {
                    limit: 440600,
                    rate: 0.0264,
                },
                {
                    limit: Infinity,
                    rate: 0.028999999999999998,
                },
            ],
            standardDeduction: 25100,
        },
    },
    OH: {
        single: {
            brackets: [
                {
                    limit: 44250,
                    rate: 0.0285,
                },
                {
                    limit: 88450,
                    rate: 0.03326,
                },
                {
                    limit: 110650,
                    rate: 0.03802,
                },
                {
                    limit: 221300,
                    rate: 0.04413,
                },
                {
                    limit: Infinity,
                    rate: 0.04797,
                },
            ],
            standardDeduction: null,
        },
        married: {
            brackets: [
                {
                    limit: 44250,
                    rate: 0.0285,
                },
                {
                    limit: 88450,
                    rate: 0.03326,
                },
                {
                    limit: 110650,
                    rate: 0.03802,
                },
                {
                    limit: 221300,
                    rate: 0.04413,
                },
                {
                    limit: Infinity,
                    rate: 0.04797,
                },
            ],
            standardDeduction: null,
        },
    },
    OK: {
        single: {
            brackets: [
                {
                    limit: 1000,
                    rate: 0.005,
                },
                {
                    limit: 2500,
                    rate: 0.01,
                },
                {
                    limit: 3750,
                    rate: 0.02,
                },
                {
                    limit: 4900,
                    rate: 0.03,
                },
                {
                    limit: 7200,
                    rate: 0.04,
                },
                {
                    limit: Infinity,
                    rate: 0.05,
                },
            ],
            standardDeduction: 6350,
        },
        married: {
            brackets: [
                {
                    limit: 2000,
                    rate: 0.005,
                },
                {
                    limit: 5000,
                    rate: 0.01,
                },
                {
                    limit: 7500,
                    rate: 0.02,
                },
                {
                    limit: 9800,
                    rate: 0.03,
                },
                {
                    limit: 12200,
                    rate: 0.04,
                },
                {
                    limit: Infinity,
                    rate: 0.05,
                },
            ],
            standardDeduction: 12700,
        },
    },
    OR: {
        single: {
            brackets: [
                {
                    limit: 3650,
                    rate: 0.0475,
                },
                {
                    limit: 9200,
                    rate: 0.0675,
                },
                {
                    limit: 125000,
                    rate: 0.0875,
                },
                {
                    limit: Infinity,
                    rate: 0.099,
                },
            ],
            standardDeduction: 2315,
        },
        married: {
            brackets: [
                {
                    limit: 7300,
                    rate: 0.0475,
                },
                {
                    limit: 18400,
                    rate: 0.0675,
                },
                {
                    limit: 250000,
                    rate: 0.0875,
                },
                {
                    limit: Infinity,
                    rate: 0.099,
                },
            ],
            standardDeduction: 4630,
        },
    },
    PA: {
        single: {
            brackets: [
                {
                    limit: Infinity,
                    rate: 0.030699999999999998,
                },
            ],
            standardDeduction: null,
        },
        married: {
            brackets: [
                {
                    limit: Infinity,
                    rate: 0.030699999999999998,
                },
            ],
            standardDeduction: null,
        },
    },
    RI: {
        single: {
            brackets: [
                {
                    limit: 66200,
                    rate: 0.0375,
                },
                {
                    limit: 150550,
                    rate: 0.0475,
                },
                {
                    limit: Infinity,
                    rate: 0.0599,
                },
            ],
            standardDeduction: 9050,
        },
        married: {
            brackets: [
                {
                    limit: 66200,
                    rate: 0.0375,
                },
                {
                    limit: 150550,
                    rate: 0.0475,
                },
                {
                    limit: Infinity,
                    rate: 0.0599,
                },
            ],
            standardDeduction: 18100,
        },
    },
    SC: {
        single: {
            brackets: [
                {
                    limit: 3070,
                    rate: 0,
                },
                {
                    limit: 6150,
                    rate: 0.03,
                },
                {
                    limit: 9230,
                    rate: 0.04,
                },
                {
                    limit: 12310,
                    rate: 0.05,
                },
                {
                    limit: 15400,
                    rate: 0.06,
                },
                {
                    limit: Infinity,
                    rate: 0.07,
                },
            ],
            standardDeduction: 12550,
        },
        married: {
            brackets: [
                {
                    limit: 3070,
                    rate: 0,
                },
                {
                    limit: 6150,
                    rate: 0.03,
                },
                {
                    limit: 9230,
                    rate: 0.04,
                },
                {
                    limit: 12310,
                    rate: 0.05,
                },
                {
                    limit: 15400,
                    rate: 0.06,
                },
                {
                    limit: Infinity,
                    rate: 0.07,
                },
            ],
            standardDeduction: 25100,
        },
    },
    UT: {
        single: {
            brackets: [
                {
                    limit: Infinity,
                    rate: 0.0495,
                },
            ],
            standardDeduction: 753,
        },
        married: {
            brackets: [
                {
                    limit: Infinity,
                    rate: 0.0495,
                },
            ],
            standardDeduction: 1506,
        },
    },
    VT: {
        single: {
            brackets: [
                {
                    limit: 40350,
                    rate: 0.0335,
                },
                {
                    limit: 97800,
                    rate: 0.066,
                },
                {
                    limit: 204000,
                    rate: 0.076,
                },
                {
                    limit: Infinity,
                    rate: 0.0875,
                },
            ],
            standardDeduction: 6250,
        },
        married: {
            brackets: [
                {
                    limit: 67450,
                    rate: 0.0335,
                },
                {
                    limit: 163000,
                    rate: 0.066,
                },
                {
                    limit: 248350,
                    rate: 0.076,
                },
                {
                    limit: Infinity,
                    rate: 0.0875,
                },
            ],
            standardDeduction: 12500,
        },
    },
    VA: {
        single: {
            brackets: [
                {
                    limit: 3000,
                    rate: 0.02,
                },
                {
                    limit: 5000,
                    rate: 0.03,
                },
                {
                    limit: 17000,
                    rate: 0.05,
                },
                {
                    limit: Infinity,
                    rate: 0.0575,
                },
            ],
            standardDeduction: 4500,
        },
        married: {
            brackets: [
                {
                    limit: 3000,
                    rate: 0.02,
                },
                {
                    limit: 5000,
                    rate: 0.03,
                },
                {
                    limit: 17000,
                    rate: 0.05,
                },
                {
                    limit: Infinity,
                    rate: 0.0575,
                },
            ],
            standardDeduction: 9000,
        },
    },
    WV: {
        single: {
            brackets: [
                {
                    limit: 10000,
                    rate: 0.03,
                },
                {
                    limit: 25000,
                    rate: 0.04,
                },
                {
                    limit: 40000,
                    rate: 0.045,
                },
                {
                    limit: 60000,
                    rate: 0.06,
                },
                {
                    limit: Infinity,
                    rate: 0.065,
                },
            ],
            standardDeduction: null,
        },
        married: {
            brackets: [
                {
                    limit: 10000,
                    rate: 0.03,
                },
                {
                    limit: 25000,
                    rate: 0.04,
                },
                {
                    limit: 40000,
                    rate: 0.045,
                },
                {
                    limit: 60000,
                    rate: 0.06,
                },
                {
                    limit: Infinity,
                    rate: 0.065,
                },
            ],
            standardDeduction: null,
        },
    },
    WI: {
        single: {
            brackets: [
                {
                    limit: 12120,
                    rate: 0.0354,
                },
                {
                    limit: 24250,
                    rate: 0.04650000000000001,
                },
                {
                    limit: 266930,
                    rate: 0.06269999999999999,
                },
                {
                    limit: Infinity,
                    rate: 0.0765,
                },
            ],
            standardDeduction: 11050,
        },
        married: {
            brackets: [
                {
                    limit: 16160,
                    rate: 0.0354,
                },
                {
                    limit: 32330,
                    rate: 0.04650000000000001,
                },
                {
                    limit: 355910,
                    rate: 0.06269999999999999,
                },
                {
                    limit: Infinity,
                    rate: 0.0765,
                },
            ],
            standardDeduction: 20470,
        },
    },
    WA: {
        single: {
            brackets: [
                {
                    limit: 10000,
                    rate: 0.04,
                },
                {
                    limit: 40000,
                    rate: 0.06,
                },
                {
                    limit: 60000,
                    rate: 0.065,
                },
                {
                    limit: 350000,
                    rate: 0.085,
                },
                {
                    limit: 1000000,
                    rate: 0.0875,
                },
                {
                    limit: Infinity,
                    rate: 0.0895,
                },
            ],
            standardDeduction: 12550,
        },
        married: {
            brackets: [
                {
                    limit: 10000,
                    rate: 0.04,
                },
                {
                    limit: 40000,
                    rate: 0.06,
                },
                {
                    limit: 60000,
                    rate: 0.065,
                },
                {
                    limit: 350000,
                    rate: 0.085,
                },
                {
                    limit: 1000000,
                    rate: 0.0875,
                },
                {
                    limit: Infinity,
                    rate: 0.0895,
                },
            ],
            standardDeduction: 25100,
        },
    },
    "D.C.": {
        single: {
            brackets: [
                { limit: 10000, rate: 0.04 },
                { limit: 40000, rate: 0.06 },
                { limit: 60000, rate: 0.065 },
                { limit: 350000, rate: 0.085 },
                { limit: 1000000, rate: 0.0875 },
                { limit: Infinity, rate: 0.0895 },
            ],
            standardDeduction: 12550,
        },
        married: {
            brackets: [
                { limit: 10000, rate: 0.04 },
                { limit: 40000, rate: 0.06 },
                { limit: 60000, rate: 0.065 },
                { limit: 350000, rate: 0.085 },
                { limit: 1000000, rate: 0.0875 },
                { limit: Infinity, rate: 0.0895 },
            ],
            standardDeduction: 25100,
        },
    },
    WY: {
        single: {
            brackets: [{ limit: Infinity, rate: 0 }],
            standardDeduction: 0,
        },
        married: {
            brackets: [{ limit: Infinity, rate: 0 }],
            standardDeduction: 0,
        },
    },
};
