/**
 * @param {number} number
 * @returns {string}
 */
export const formatCommas3 = number => {
    const fixedNumber = number.toFixed(2);
    const [wholeNumber, decimalPart] = fixedNumber.split(".");
    const formattedWholeNumber = wholeNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formattedWholeNumber + "." + decimalPart;
};

/**
 * @param {number} num
 * @returns {number}
 */
export const format2DP = num => {
    return typeof num === "number" ? Number(num.toFixed(2)) : Number(num);
};

/**
 * @param {number} number
 * @returns {string}
 */
export const convertMoneyToWords = number => {
    const ones = ["", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine"];
    const tens = ["", "", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"];
    const teens = [
        "ten",
        "eleven",
        "twelve",
        "thirteen",
        "fourteen",
        "fifteen",
        "sixteen",
        "seventeen",
        "eighteen",
        "nineteen",
    ];
    const thousands = ["", "thousand", "million", "billion", "trillion"];

    const numArr = number.toString().split(".");
    let dollars = numArr[0];
    let cents = numArr[1] ? (numArr[1][0] || "0") + (numArr[1][1] || "0") : "00";

    // noinspection JSIncompatibleTypesComparison
    if (dollars === 0) {
        return "";
    }

    if (dollars.length > 15) {
        return "Number too large";
    }

    let wordString = "";
    let i = 0;
    while (dollars > 0) {
        let chunk = parseInt(dollars.slice(-3));
        dollars = dollars.slice(0, -3);

        let chunkWords = "";
        if (chunk > 99) {
            chunkWords += ones[Math.floor(chunk / 100)] + " hundred ";
            chunk %= 100;
        }
        if (chunk > 9 && chunk < 20) {
            chunkWords += teens[chunk - 10] + " ";
            chunk = 0;
        }
        if (chunk > 19 || chunk === 10) {
            chunkWords += tens[Math.floor(chunk / 10)] + " ";
            chunk %= 10;
        }
        if (chunk > 0) {
            chunkWords += ones[chunk] + " ";
        }

        if (chunkWords !== "") {
            wordString = chunkWords + thousands[i] + " " + wordString;
        }
        i++;
    }

    if (cents !== "00") {
        wordString += " and " + cents + "/100";
    }

    wordString += " dollars";

    return wordString;
};

/**
 * @param {number} cents
 * @returns {string}
 */
export const convertCentsToDollars = cents => {
    if (cents < 0) {
        return "0";
    }
    let dollars = Number(cents) / 100;

    if (!dollars) {
        dollars = 0;
    }

    return dollars.toFixed(2);
};

/**
 * @param {number} value
 * @returns {string}
 */
export const presentNumber = value => {
    return value === 0 ? "" : value.toFixed(2);
};

export const capitalizeWordsFirstLetter = str => {
    return str.replace(/\b\w/g, match => match.toUpperCase());
};
