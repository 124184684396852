import { is_valid_email } from "../helpers/validation";

const validateW2Step1BasicInformation = basic => {
    let isValid = true;
    const errors = {};

    const requiredFields = {
        year: "Year",
        maritalStatus: "Marital status",
        yearlyIncomeBeforeTaxes: "Yearly income before taxes",
        stateToBeUsedInTaxCalculations: "State to be used in tax calculations",
        email: "Email",
    };

    Object.entries(requiredFields).forEach(([field, label]) => {
        if (!basic[field]) {
            errors[field] = `${label} is required`;
            isValid = false;
        }
    });

    if (!is_valid_email(basic.email)) {
        errors["email"] = "Email address have to be valid email";
        isValid = false;
    }

    return { isValid, errors };
};

const validateW2Step2CompanyDetails = company => {
    let isValid = true;
    const errors = {};

    const requiredFields = {
        name: "Company name",
        address: "Address",
        city: "City",
        state: "State",
        zipCode: "Zip code",
        ein: "Employer's Identification Number (EIN)",
    };

    Object.entries(requiredFields).forEach(([field, label]) => {
        if (!company[field]) {
            errors[field] = `${label} is required`;
            isValid = false;
        }
    });

    return { isValid, errors };
};

const validateW2Step3EmployeeDetails = employee => {
    let isValid = true;
    const errors = {};

    const requiredFields = {
        name: "Name",
        lastName: "Last name",
        ssn: "Social Security Number",
        address: "Address",
        city: "City",
        state: "State",
        zipCode: "Zip code",
    };

    Object.entries(requiredFields).forEach(([field, label]) => {
        if (!employee[field]) {
            errors[field] = `${label} is required`;
            isValid = false;
        }
    });

    return { isValid, errors };
};

export const validateW2ActiveStepRequiredFields = (step, w2) => {
    switch (step) {
        case 1:
            return validateW2Step1BasicInformation(w2.basic);
        case 2:
            return validateW2Step2CompanyDetails(w2.company);
        case 3:
            return validateW2Step3EmployeeDetails(w2.employee);
        default:
            return { isValid: true, errors: {} };
    }
};

export const formatEIN = numberString => {
    if (!numberString) return numberString;
    const number = numberString.replace(/[^\d]/g, "");
    const numberLength = number.length;
    if (numberLength <= 2) return number;
    return `${number.slice(0, 2)}-${number.slice(2, 9)}`;
};

export const formatSSN = numberString => {
    if (!numberString) return numberString;
    const number = numberString.replace(/[^\d]/g, "");
    const numberLength = number.length;
    if (numberLength <= 3) return number;
    if (numberLength <= 5) return `${number.slice(0, 3)}-${number.slice(3, 5)}`;
    return `${number.slice(0, 3)}-${number.slice(3, 5)}-${number.slice(5, 9)}`;
};

export const formatLocalityName = nameString => {
    if (nameString.length <= 9) {
        return nameString;
    }
    return nameString.substring(0, 9) + ".";
};
