import { useEffect } from "react";

import { useDispatch } from "react-redux";
import { setWindowHeight, setIsDesktop } from "../slices/global/globalSlice";

const useResizeEffect = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const handleResize = () => {
            dispatch(setIsDesktop(window.innerWidth >= 1024));
            dispatch(setWindowHeight(document.documentElement.clientWidth));
        };

        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
};

export default useResizeEffect;
