export const developer_website = "developershihab.com";

export const paymentFrequencyList = [
    "Daily",
    "Weekly (ex: every Friday)",
    "Bi-Weekly (ex: every other Wednesday)",
    "Semi-Monthly (ex: 1st and 15th)",
    "Monthly (ex: 1st of month only)",
    "Quarterly",
    "Semi-Annually",
    "Annually",
];

export const exemptionsList = [
    { value: "0", label: "0" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
];

export const SDIAllowed = {
    CA: true,
    HI: true,
    NJ: true,
    NY: true,
    RI: true,
};
export const sectionCodes = [
    {
        value: "",
        label: "Code",
    },
    {
        value: "A",
        label: "A - Uncollected social security or RRTA tax on tips",
    },
    { value: "B", label: "B - Uncollected Medicare tax on tips" },
    {
        value: "C",
        label: "C - Taxable cost of group-term life insurance over $50,000",
    },
    {
        value: "D",
        label: "D - Elective deferrals under a section 401(k) cash or deferred arrangement (plan)",
    },
    {
        value: "E",
        label: "E - Elective deferrals under a section 403(b) salary reduction agreement",
    },
    {
        value: "F",
        label: "F - Elective deferrals under a section 408(k)(6) salary reduction SEP",
    },
    {
        value: "G",
        label: "G - Elective deferrals and employer contributions (including nonelective deferrals) to any governmental or nongovernmental section 457(b) deferred compensation plan",
    },
    {
        value: "H",
        label: "H - Elective deferrals under section 501(c)(18)(D) tax-exempt organization plan",
    },
    { value: "J", label: "J - Nontaxable sick pay" },
    {
        value: "K",
        label: "K - 20% excise tax on excess golden parachute payments (not applicable to Forms W-2AS, W-2CM, W-2GU, or W-2VI)",
    },
    {
        value: "L",
        label: "L - Substantiated employee business expense reimbursements",
    },
    {
        value: "M",
        label: "M - Uncollected social security or RRTA tax on taxable cost of group-term life insurance over $50,000 (for former employees)",
    },
    {
        value: "N",
        label: "N - Uncollected Medicare tax on taxable cost of group-term life insurance over $50,000 (for former employees)",
    },
    {
        value: "P",
        label: "P - Excludable moving expense reimbursements paid directly to employee",
    },
    { value: "Q", label: "Q - Nontaxable combat pay" },
    { value: "R", label: "R - Employer contributions to an Archer MSA" },
    {
        value: "S",
        label: "S - Employee salary reduction contributions under a section 408(p) SIMPLE plan",
    },
    { value: "T", label: "T - Adoption benefits" },
    {
        value: "V",
        label: "V - Income from the exercise of nonstatutory stock option(s)",
    },
    {
        value: "W",
        label: "W - Employer contributions to a health savings account (HSA)",
    },
    {
        value: "Y",
        label: "Y - Deferrals under a section 409A nonqualified deferred compensation plan",
    },
    {
        value: "Z",
        label: "Z - Income under a nonqualified deferred compensation plan that fails to satisfy section 409A",
    },
    {
        value: "AA",
        label: "AA - Designated Roth contributions under a section 401(k) plan",
    },
    {
        value: "BB",
        label: "BB - Designated Roth contributions under a section 403(b) plan",
    },
    { value: "CC", label: "CC - HIRE exempt wages and tips (2010 only)" },
    {
        value: "DD",
        label: "DD - Cost of employer-sponsored health coverage",
    },
    {
        value: "EE",
        label: "EE - Designated Roth contributions under a governmental section 457(b) plan",
    },
    {
        value: "FF",
        label: "FF - Permitted benefits under a qualified small employer health reimbursement arrangement",
    },
];
