import { W2_CONTEXT } from "../configs/consts";

import storage from "../helpers/storage";
import tracker, { viewedProductTrack } from "../helpers/tracker";

const W2_STEP_1_BASIC = "w2_step1_basic_information";
const W2_STEP_2_COMPANY = "w2_step2_company_details";
const W2_STEP_3_EMPLOYEE = "w2_step3_employee_details";

const storeW2Step1ToLocalStorage = basic => {
    tracker.identify(basic?.email);
    viewedProductTrack(W2_CONTEXT);

    storage.set(W2_STEP_1_BASIC, basic);
};

const storeW2Step2ToLocalStorage = company => {
    storage.set(W2_STEP_2_COMPANY, company);
};

const storeW2Step3ToLocalStorage = employee => {
    tracker.addCustomProperties({
        $first_name: employee.name,
        $last_name: employee.lastName,
        $city: employee.city,
        $region: employee.state,
        $zip: employee.zipCode,
    });

    storage.set(W2_STEP_3_EMPLOYEE, employee);
};

export const getW2Step1FromLocalStorage = () => {
    const {
        year,
        maritalStatus,
        exemptions,
        yearlyIncomeBeforeTaxes,
        stateToBeUsedInTaxCalculations,
        email,
    } = storage.get(W2_STEP_1_BASIC) || {};

    return {
        initYear: Number(year) || 2023,
        initMaritalStatus: maritalStatus || "single",
        initExemptions: Number(exemptions) || 0,
        initYearlyIncomeBeforeTaxes: Number(yearlyIncomeBeforeTaxes) || 80000,
        initStateToBeUsedInTaxCalculations: stateToBeUsedInTaxCalculations || "",
        initEmail: email || "",
    };
};

export const getW2Step2FromLocalStorage = () => {
    const { name, address, city, state, zipCode, ein, stateId } = storage.get(W2_STEP_2_COMPANY) || {};

    return {
        initName: name || "",
        initAddress: address || "",
        initCity: city || "",
        initState: state || "",
        initZipCode: zipCode || "",
        initEin: ein || "",
        initStateId: stateId || "",
    };
};

export const getW2Step3FromLocalStorage = () => {
    const { name, lastName, ssn, address, city, state, zipCode } = storage.get(W2_STEP_3_EMPLOYEE) || {};

    return {
        initEmployeeName: name || "",
        initEmployeeLastName: lastName || "",
        initEmployeeSSN: ssn || "",
        initEmployeeAddress: address || "",
        initEmployeeCity: city || "",
        initEmployeeState: state || "",
        initEmployeeZipCode: zipCode || "",
    };
};

export const storeW2ActiveStepToLocalStorage = (step, w2) => {
    switch (step) {
        case 1:
            return storeW2Step1ToLocalStorage(w2.basic);
        case 2:
            return storeW2Step2ToLocalStorage(w2.company);
        case 3:
            return storeW2Step3ToLocalStorage(w2.employee);
        case 5:
            tracker.startedCheckoutTrack(W2_CONTEXT);
            break;
        default:
            break;
    }
};
