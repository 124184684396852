export const w2InitActiveStep = 1;

export const w2StepsNameInBar = {
    1: "1.Basic Information",
    2: "2.Employer Details",
    3: "3.Employee Details",
    4: "4.Earnings and Taxes",
    5: "5.Preview",
    6: "6.Checkout",
};

export const getW2StepsNameInBar = step => {
    return w2StepsNameInBar[step] || w2StepsNameInBar[1];
};

export const w2MaxStep = [...Object.keys(w2StepsNameInBar)].length;

export const w2MobileStepAboveText = {
    1: `Step 1 of ${w2MaxStep}`,
    2: `Step 2 of ${w2MaxStep}`,
    3: `Step 3 of ${w2MaxStep}`,
};

export const w2StepLoadingMessages = {
    1: `Loading Step 1 of ${w2MaxStep}`,
    2: `Loading Step 2 of ${w2MaxStep}`,
    3: `Loading Step 3 of ${w2MaxStep}`,
    4: `Loading Step 4 of ${w2MaxStep}`,
    5: "All done! Time to preview",
    6: "Generating your W2",
};

export const w2TemplateBGImage = {
    2021: "https://www.paystubhero.com/wp-content/uploads/2024/01/w2-2021-employee-w1500.03cc9a52.png",
    2022: "https://www.paystubhero.com/wp-content/uploads/2024/01/w2-2022-employee-w1500.1aff1f47.png",
    2023: "https://www.paystubhero.com/wp-content/uploads/2024/01/w2-2023-employee-w1500.ca8e97cf.png",
};

export const getW2TemplateBGImage = year => {
    return w2TemplateBGImage[year] || w2TemplateBGImage[2022];
};

export const w2Years = [...Object.keys(w2TemplateBGImage)].map(y => ({ label: y, value: y }));
