export const ten99MISCStepsNameInBar = {
    1: "1.Basic Information",
    2: "2.Payer",
    3: "3.Recipient",
    4: "4.Payments & Taxes",
    5: "5.Income & tax",
    6: "6.Preview",
    7: "7.Checkout",
};

export const getTen99MISCStepsNameInBar = step => {
    return ten99MISCStepsNameInBar[step] || ten99MISCStepsNameInBar[1];
};

export const ten99MISCMaxStep = [...Object.keys(ten99MISCStepsNameInBar)].length;

export const ten99MISCMobileStepAboveText = {
    1: `Step 1 of ${ten99MISCMaxStep}`,
    2: `Step 2 of ${ten99MISCMaxStep}`,
    3: `Step 3 of ${ten99MISCMaxStep}`,
};

export const ten99MISCStepLoadingMessages = {
    1: `Loading Step 1 of ${ten99MISCMaxStep}`,
    2: `Loading Step 2 of ${ten99MISCMaxStep}`,
    3: `Loading Step 3 of ${ten99MISCMaxStep}`,
    4: `Loading Step 4 of ${ten99MISCMaxStep}`,
    5: `Loading Step 5 of ${ten99MISCMaxStep}`,
    6: "All done! Time to preview",
    7: "Generating your 1099",
};

export const ten99MISCYears = [
    { label: "2021", value: "2021" },
    { label: "2022", value: "2022" },
    { label: "2023", value: "2023" },
];

export const ten99MISCInitActiveStep = 1;
