// https://taxfoundation.org/state-income-tax-rates-2023/

export default {
    AL: {
        single: {
            brackets: [
                { limit: 500, rate: 0.02 },
                { limit: 3000, rate: 0.04 },
                { limit: Infinity, rate: 0.05 },
            ],
            standardDeduction: 3000,
        },
        married: {
            brackets: [
                { limit: 1000, rate: 0.02 },
                { limit: 6000, rate: 0.04 },
                { limit: Infinity, rate: 0.05 },
            ],
            standardDeduction: 8500,
        },
    },
    AZ: {
        single: {
            brackets: [{ limit: Infinity, rate: 0.025 }],
            standardDeduction: 13850,
        },
        married: {
            brackets: [{ limit: Infinity, rate: 0.025 }],
            standardDeduction: 27700,
        },
    },
    AR: {
        single: {
            brackets: [
                { limit: 4300, rate: 0.02 },
                { limit: 8500, rate: 0.04 },
                { limit: Infinity, rate: 0.049 },
            ],
            standardDeduction: 2270,
        },
        married: {
            brackets: [
                { limit: 4300, rate: 0.02 },
                { limit: 8500, rate: 0.04 },
                { limit: Infinity, rate: 0.049 },
            ],
            standardDeduction: 4540,
        },
    },
    CA: {
        single: {
            brackets: [
                { limit: 10099, rate: 0.01 },
                { limit: 23942, rate: 0.02 },
                { limit: 37788, rate: 0.04 },
                { limit: 52455, rate: 0.06 },
                { limit: 66295, rate: 0.08 },
                { limit: 338639, rate: 0.093 },
                { limit: 406364, rate: 0.103 },
                { limit: 677275, rate: 0.113 },
                { limit: 1000000, rate: 0.123 },
                { limit: Infinity, rate: 0.133 },
            ],
            standardDeduction: 5202,
        },
        married: {
            brackets: [
                { limit: 20198, rate: 0.01 },
                { limit: 47884, rate: 0.02 },
                { limit: 75576, rate: 0.04 },
                { limit: 104910, rate: 0.06 },
                { limit: 132590, rate: 0.08 },
                { limit: 677278, rate: 0.093 },
                { limit: 812728, rate: 0.103 },
                { limit: 1000000, rate: 0.113 },
                { limit: 1354550, rate: 0.123 },
                { limit: Infinity, rate: 0.133 },
            ],
            standardDeduction: 10404,
        },
    },
    CO: {
        single: {
            brackets: [{ limit: Infinity, rate: 0.044 }],
            standardDeduction: 13850,
        },
        married: {
            brackets: [{ limit: Infinity, rate: 0.044 }],
            standardDeduction: 27700,
        },
    },
    CT: {
        single: {
            brackets: [
                { limit: 10000, rate: 0.03 },
                { limit: 50000, rate: 0.05 },
                { limit: 100000, rate: 0.055 },
                { limit: 200000, rate: 0.06 },
                { limit: 250000, rate: 0.065 },
                { limit: 500000, rate: 0.069 },
                { limit: Infinity, rate: 0.0699 },
            ],
            standardDeduction: 0,
        },
        married: {
            brackets: [
                { limit: 20000, rate: 0.03 },
                { limit: 100000, rate: 0.05 },
                { limit: 200000, rate: 0.055 },
                { limit: 400000, rate: 0.06 },
                { limit: 500000, rate: 0.065 },
                { limit: 1000000, rate: 0.069 },
                { limit: Infinity, rate: 0.0699 },
            ],
            standardDeduction: 0,
        },
    },
    DE: {
        single: {
            brackets: [
                { limit: 2000, rate: 0 },
                { limit: 50000, rate: 0.022 },
                { limit: 10000, rate: 0.039 },
                { limit: 20000, rate: 0.048 },
                { limit: 25000, rate: 0.052 },
                { limit: 60000, rate: 0.0555 },
                { limit: Infinity, rate: 0.066 },
            ],
            standardDeduction: 3250,
        },
        married: {
            brackets: [
                { limit: 2000, rate: 0 },
                { limit: 50000, rate: 0.022 },
                { limit: 10000, rate: 0.039 },
                { limit: 20000, rate: 0.048 },
                { limit: 25000, rate: 0.052 },
                { limit: 60000, rate: 0.0555 },
                { limit: Infinity, rate: 0.066 },
            ],
            standardDeduction: 6500,
        },
    },
    GA: {
        single: {
            brackets: [
                { limit: 750, rate: 0.01 },
                { limit: 2250, rate: 0.02 },
                { limit: 3750, rate: 0.03 },
                { limit: 5250, rate: 0.04 },
                { limit: 7000, rate: 0.05 },
                { limit: Infinity, rate: 0.0575 },
            ],
            standardDeduction: 5400,
        },
        married: {
            brackets: [
                { limit: 1000, rate: 0.01 },
                { limit: 3000, rate: 0.02 },
                { limit: 5000, rate: 0.03 },
                { limit: 7000, rate: 0.04 },
                { limit: 10000, rate: 0.05 },
                { limit: Infinity, rate: 0.0575 },
            ],
            standardDeduction: 7100,
        },
    },
    HI: {
        single: {
            brackets: [
                { limit: 2400, rate: 0.014 },
                { limit: 4800, rate: 0.032 },
                { limit: 9600, rate: 0.055 },
                { limit: 14400, rate: 0.064 },
                { limit: 19200, rate: 0.068 },
                { limit: 24000, rate: 0.072 },
                { limit: 36000, rate: 0.076 },
                { limit: 48000, rate: 0.079 },
                { limit: 150000, rate: 0.0825 },
                { limit: 175000, rate: 0.09 },
                { limit: 200000, rate: 0.1 },
                { limit: Infinity, rate: 0.11 },
            ],
            standardDeduction: 2200,
        },
        married: {
            brackets: [
                { limit: 4800, rate: 0.014 },
                { limit: 9600, rate: 0.032 },
                { limit: 19200, rate: 0.055 },
                { limit: 28800, rate: 0.064 },
                { limit: 38400, rate: 0.068 },
                { limit: 48000, rate: 0.072 },
                { limit: 72000, rate: 0.076 },
                { limit: 96000, rate: 0.079 },
                { limit: 300000, rate: 0.0825 },
                { limit: 350000, rate: 0.09 },
                { limit: 400000, rate: 0.1 },
                { limit: Infinity, rate: 0.11 },
            ],
            standardDeduction: 4400,
        },
    },
    ID: {
        single: {
            brackets: [{ limit: Infinity, rate: 0.058 }],
            standardDeduction: 13850,
        },
        married: {
            brackets: [{ limit: Infinity, rate: 0.058 }],
            standardDeduction: 27700,
        },
    },
    IL: {
        single: {
            brackets: [{ limit: Infinity, rate: 0.0495 }],
            standardDeduction: 0,
        },
        married: {
            brackets: [{ limit: Infinity, rate: 0.0495 }],
            standardDeduction: 0,
        },
    },
    IN: {
        single: {
            brackets: [{ limit: Infinity, rate: 0.0315 }],
            standardDeduction: 0,
        },
        married: {
            brackets: [{ limit: Infinity, rate: 0.0315 }],
            standardDeduction: 0,
        },
    },
    IA: {
        single: {
            brackets: [
                { limit: 6000, rate: 0.044 },
                { limit: 30000, rate: 0.0482 },
                { limit: 75000, rate: 0.057 },
                { limit: Infinity, rate: 0.06 },
            ],
            standardDeduction: 0,
        },
        married: {
            brackets: [
                { limit: 12000, rate: 0.044 },
                { limit: 60000, rate: 0.0482 },
                { limit: 150000, rate: 0.057 },
                { limit: Infinity, rate: 0.06 },
            ],
            standardDeduction: 0,
        },
    },
    KS: {
        single: {
            brackets: [
                { limit: 15000, rate: 0.031 },
                { limit: 30000, rate: 0.0525 },
                { limit: Infinity, rate: 0.057 },
            ],
            standardDeduction: 3500,
        },
        married: {
            brackets: [
                { limit: 30000, rate: 0.031 },
                { limit: 60000, rate: 0.0525 },
                { limit: Infinity, rate: 0.057 },
            ],
            standardDeduction: 8000,
        },
    },
    KY: {
        single: {
            brackets: [{ limit: Infinity, rate: 0.045 }],
            standardDeduction: 2770,
        },
        married: {
            brackets: [{ limit: Infinity, rate: 0.045 }],
            standardDeduction: 5540,
        },
    },
    LA: {
        single: {
            brackets: [
                { limit: 12500, rate: 0.0185 },
                { limit: 50000, rate: 0.035 },
                { limit: Infinity, rate: 0.0425 },
            ],
            standardDeduction: 0,
        },
        married: {
            brackets: [
                { limit: 25000, rate: 0.0185 },
                { limit: 100000, rate: 0.035 },
                { limit: Infinity, rate: 0.0425 },
            ],
            standardDeduction: 0,
        },
    },
    ME: {
        single: {
            brackets: [
                { limit: 24500, rate: 0.058 },
                { limit: 58050, rate: 0.0675 },
                { limit: Infinity, rate: 0.0715 },
            ],
            standardDeduction: 13850,
        },
        married: {
            brackets: [
                { limit: 49050, rate: 0.058 },
                { limit: 116100, rate: 0.0675 },
                { limit: Infinity, rate: 0.0715 },
            ],
            standardDeduction: 27700,
        },
    },
    MD: {
        single: {
            brackets: [
                { limit: 1000, rate: 0.02 },
                { limit: 2000, rate: 0.03 },
                { limit: 3000, rate: 0.04 },
                { limit: 100000, rate: 0.0475 },
                { limit: 125000, rate: 0.05 },
                { limit: 150000, rate: 0.0525 },
                { limit: 250000, rate: 0.055 },
                { limit: Infinity, rate: 0.0575 },
            ],
            standardDeduction: 2400,
        },
        married: {
            brackets: [
                { limit: 1000, rate: 0.02 },
                { limit: 2000, rate: 0.03 },
                { limit: 3000, rate: 0.04 },
                { limit: 150000, rate: 0.0475 },
                { limit: 175000, rate: 0.05 },
                { limit: 225000, rate: 0.0525 },
                { limit: 300000, rate: 0.055 },
                { limit: Infinity, rate: 0.0575 },
            ],
            standardDeduction: 4850,
        },
    },
    MA: {
        single: {
            brackets: [
                { limit: 1000000, rate: 0.05 },
                { limit: Infinity, rate: 0.09 },
            ],
            standardDeduction: 0,
        },
        married: {
            brackets: [
                { limit: 1000000, rate: 0.05 },
                { limit: Infinity, rate: 0.09 },
            ],
            standardDeduction: 0,
        },
    },
    MI: {
        single: {
            brackets: [{ limit: Infinity, rate: 0.0425 }],
            standardDeduction: 0,
        },
        married: {
            brackets: [{ limit: Infinity, rate: 0.0425 }],
            standardDeduction: 0,
        },
    },
    MN: {
        single: {
            brackets: [
                { limit: 30070, rate: 0.0535 },
                { limit: 98760, rate: 0.068 },
                { limit: 183340, rate: 0.0785 },
                { limit: Infinity, rate: 0.0985 },
            ],
            standardDeduction: 13825,
        },
        married: {
            brackets: [
                { limit: 43950, rate: 0.0535 },
                { limit: 174610, rate: 0.068 },
                { limit: 304970, rate: 0.0785 },
                { limit: Infinity, rate: 0.0985 },
            ],
            standardDeduction: 27650,
        },
    },
    MS: {
        single: {
            brackets: [
                { limit: 10000, rate: 0 },
                { limit: Infinity, rate: 0.05 },
            ],
            standardDeduction: 2300,
        },
        married: {
            brackets: [
                { limit: 10000, rate: 0 },
                { limit: Infinity, rate: 0.05 },
            ],
            standardDeduction: 4600,
        },
    },
    MO: {
        single: {
            brackets: [
                { limit: 1121, rate: 0 },
                { limit: 2242, rate: 0.02 },
                { limit: 3363, rate: 0.025 },
                { limit: 4484, rate: 0.03 },
                { limit: 5605, rate: 0.035 },
                { limit: 6726, rate: 0.04 },
                { limit: 7847, rate: 0.045 },
                { limit: Infinity, rate: 0.0495 },
            ],
            standardDeduction: 13850,
        },
        married: {
            brackets: [
                { limit: 1121, rate: 0 },
                { limit: 2242, rate: 0.02 },
                { limit: 3363, rate: 0.025 },
                { limit: 4484, rate: 0.03 },
                { limit: 5605, rate: 0.035 },
                { limit: 6726, rate: 0.04 },
                { limit: 7847, rate: 0.045 },
                { limit: Infinity, rate: 0.0495 },
            ],
            standardDeduction: 27700,
        },
    },
    MT: {
        single: {
            brackets: [
                { limit: 3600, rate: 0.01 },
                { limit: 6300, rate: 0.02 },
                { limit: 9700, rate: 0.03 },
                { limit: 13000, rate: 0.04 },
                { limit: 16800, rate: 0.05 },
                { limit: 21600, rate: 0.06 },
                { limit: Infinity, rate: 0.0675 },
            ],
            standardDeduction: 5540,
        },
        married: {
            brackets: [
                { limit: 3700, rate: 0.0246 },
                { limit: 22170, rate: 0.0351 },
                { limit: 35730, rate: 0.0501 },
                { limit: Infinity, rate: 0.0664 },
            ],
            standardDeduction: 11080,
        },
    },
    NE: {
        single: {
            brackets: [
                { limit: 3700, rate: 0.0246 },
                { limit: 22170, rate: 0.0351 },
                { limit: 35730, rate: 0.0501 },
                { limit: Infinity, rate: 0.0664 },
            ],
            standardDeduction: 7900,
        },
        married: {
            brackets: [
                { limit: 7390, rate: 0.0246 },
                { limit: 44350, rate: 0.0351 },
                { limit: 71460, rate: 0.0501 },
                { limit: Infinity, rate: 0.0664 },
            ],
            standardDeduction: 15800,
        },
    },
    NJ: {
        single: {
            brackets: [
                { limit: 20000, rate: 0.014 },
                { limit: 35000, rate: 0.0175 },
                { limit: 40000, rate: 0.035 },
                { limit: 75000, rate: 0.05525 },
                { limit: 500000, rate: 0.0637 },
                { limit: 1000000, rate: 0.0897 },
                { limit: Infinity, rate: 0.1075 },
            ],
            standardDeduction: 0,
        },
        married: {
            brackets: [
                { limit: 20000, rate: 0.014 },
                { limit: 50000, rate: 0.0175 },
                { limit: 70000, rate: 0.035 },
                { limit: 80000, rate: 0.05525 },
                { limit: 150000, rate: 0.0637 },
                { limit: 500000, rate: 0.0897 },
                { limit: Infinity, rate: 0.1075 },
            ],
            standardDeduction: 0,
        },
    },
    NM: {
        single: {
            brackets: [
                { limit: 5500, rate: 0.017 },
                { limit: 11000, rate: 0.032 },
                { limit: 16000, rate: 0.047 },
                { limit: 210000, rate: 0.049 },
                { limit: Infinity, rate: 0.059 },
            ],
            standardDeduction: 13850,
        },
        married: {
            brackets: [
                { limit: 8000, rate: 0.017 },
                { limit: 16000, rate: 0.032 },
                { limit: 24000, rate: 0.047 },
                { limit: 315000, rate: 0.049 },
                { limit: Infinity, rate: 0.059 },
            ],
            standardDeduction: 27700,
        },
    },
    NY: {
        single: {
            brackets: [
                { limit: 8500, rate: 0.04 },
                { limit: 11700, rate: 0.045 },
                { limit: 13900, rate: 0.0525 },
                { limit: 80650, rate: 0.055 },
                { limit: 215400, rate: 0.06 },
                { limit: 1077550, rate: 0.0685 },
                { limit: 5000000, rate: 0.0965 },
                { limit: 25000000, rate: 0.103 },
                { limit: Infinity, rate: 0.109 },
            ],
            standardDeduction: 8000,
        },
        married: {
            brackets: [
                { limit: 17150, rate: 0.04 },
                { limit: 23600, rate: 0.045 },
                { limit: 27900, rate: 0.0525 },
                { limit: 161550, rate: 0.055 },
                { limit: 323200, rate: 0.06 },
                { limit: 2155350, rate: 0.0685 },
                { limit: 5000000, rate: 0.0965 },
                { limit: 25000000, rate: 0.103 },
                { limit: Infinity, rate: 0.109 },
            ],
            standardDeduction: 16050,
        },
    },
    NC: {
        single: {
            brackets: [{ limit: Infinity, rate: 0.0475 }],
            standardDeduction: 12750,
        },
        married: {
            brackets: [{ limit: Infinity, rate: 0.0475 }],
            standardDeduction: 25500,
        },
    },
    ND: {
        single: {
            brackets: [
                { limit: 41775, rate: 0.011 },
                { limit: 101050, rate: 0.0204 },
                { limit: 210825, rate: 0.0227 },
                { limit: 458350, rate: 0.0264 },
                { limit: Infinity, rate: 0.029 },
            ],
            standardDeduction: 13850,
        },
        married: {
            brackets: [
                { limit: 69700, rate: 0.011 },
                { limit: 168450, rate: 0.0204 },
                { limit: 256650, rate: 0.0227 },
                { limit: 458350, rate: 0.0264 },
                { limit: Infinity, rate: 0.029 },
            ],
            standardDeduction: 27700,
        },
    },
    OH: {
        single: {
            brackets: [
                { limit: 26050, rate: 0 },
                { limit: 46100, rate: 0.02765 },
                { limit: 92150, rate: 0.03226 },
                { limit: 115300, rate: 0.03688 },
                { limit: Infinity, rate: 0.0399 },
            ],
            standardDeduction: 0,
        },
        married: {
            brackets: [
                { limit: 26050, rate: 0 },
                { limit: 46100, rate: 0.02765 },
                { limit: 92150, rate: 0.03226 },
                { limit: 115300, rate: 0.03688 },
                { limit: Infinity, rate: 0.0399 },
            ],
            standardDeduction: 0,
        },
    },
    OK: {
        single: {
            brackets: [
                { limit: 1000, rate: 0.0025 },
                { limit: 2500, rate: 0.0075 },
                { limit: 3750, rate: 0.0175 },
                { limit: 4900, rate: 0.0275 },
                { limit: 7200, rate: 0.0375 },
                { limit: Infinity, rate: 0.0475 },
            ],
            standardDeduction: 6350,
        },
        married: {
            brackets: [
                { limit: 2000, rate: 0.0025 },
                { limit: 5000, rate: 0.0075 },
                { limit: 7500, rate: 0.0175 },
                { limit: 9800, rate: 0.0275 },
                { limit: 12200, rate: 0.0375 },
                { limit: Infinity, rate: 0.0475 },
            ],
            standardDeduction: 12700,
        },
    },
    OR: {
        single: {
            brackets: [
                { limit: 4050, rate: 0.0475 },
                { limit: 10200, rate: 0.0675 },
                { limit: 125000, rate: 0.0875 },
                { limit: Infinity, rate: 0.099 },
            ],
            standardDeduction: 2605,
        },
        married: {
            brackets: [
                { limit: 8100, rate: 0.0475 },
                { limit: 20400, rate: 0.0675 },
                { limit: 250000, rate: 0.0875 },
                { limit: Infinity, rate: 0.099 },
            ],
            standardDeduction: 5210,
        },
    },
    PA: {
        single: {
            brackets: [{ limit: Infinity, rate: 0.0307 }],
            standardDeduction: 0,
        },
        married: {
            brackets: [{ limit: Infinity, rate: 0.0307 }],
            standardDeduction: 0,
        },
    },
    RI: {
        single: {
            brackets: [
                { limit: 68200, rate: 0.0375 },
                { limit: 155050, rate: 0.0475 },
                { limit: Infinity, rate: 0.0599 },
            ],
            standardDeduction: 10000,
        },
        married: {
            brackets: [
                { limit: 68200, rate: 0.0375 },
                { limit: 155050, rate: 0.0475 },
                { limit: Infinity, rate: 0.0599 },
            ],
            standardDeduction: 20050,
        },
    },
    SC: {
        single: {
            brackets: [
                { limit: 3200, rate: 0 },
                { limit: 16040, rate: 0.03 },
                { limit: Infinity, rate: 0.065 },
            ],
            standardDeduction: 13850,
        },
        married: {
            brackets: [
                { limit: 3200, rate: 0 },
                { limit: 16040, rate: 0.03 },
                { limit: Infinity, rate: 0.065 },
            ],
            standardDeduction: 27700,
        },
    },
    UT: {
        single: {
            brackets: [{ limit: Infinity, rate: 0.0485 }],
            standardDeduction: 831,
        },
        married: {
            brackets: [{ limit: Infinity, rate: 0.0485 }],
            standardDeduction: 1662,
        },
    },
    VT: {
        single: {
            brackets: [
                { limit: 42150, rate: 0.0335 },
                { limit: 102200, rate: 0.066 },
                { limit: 213150, rate: 0.076 },
                { limit: Infinity, rate: 0.0875 },
            ],
            standardDeduction: 6500,
        },
        married: {
            brackets: [
                { limit: 70450, rate: 0.0335 },
                { limit: 170300, rate: 0.066 },
                { limit: 259500, rate: 0.076 },
                { limit: Infinity, rate: 0.0875 },
            ],
            standardDeduction: 13050,
        },
    },
    VA: {
        single: {
            brackets: [
                { limit: 3000, rate: 0.02 },
                { limit: 5000, rate: 0.03 },
                { limit: 17000, rate: 0.05 },
                { limit: Infinity, rate: 0.0575 },
            ],
            standardDeduction: 8000,
        },
        married: {
            brackets: [
                { limit: 3000, rate: 0.02 },
                { limit: 5000, rate: 0.03 },
                { limit: 17000, rate: 0.05 },
                { limit: Infinity, rate: 0.0575 },
            ],
            standardDeduction: 16000,
        },
    },
    WA: {
        single: {
            brackets: [{ limit: Infinity, rate: 0.07 }],
            standardDeduction: 250000,
        },
        married: {
            brackets: [{ limit: Infinity, rate: 0.07 }],
            standardDeduction: 250000,
        },
    },
    WV: {
        single: {
            brackets: [
                { limit: 10000, rate: 0.03 },
                { limit: 25000, rate: 0.04 },
                { limit: 40000, rate: 0.045 },
                { limit: 60000, rate: 0.06 },
                { limit: Infinity, rate: 0.065 },
            ],
            standardDeduction: 0,
        },
        married: {
            brackets: [
                { limit: 10000, rate: 0.03 },
                { limit: 25000, rate: 0.04 },
                { limit: 40000, rate: 0.045 },
                { limit: 60000, rate: 0.06 },
                { limit: Infinity, rate: 0.065 },
            ],
            standardDeduction: 0,
        },
    },
    WI: {
        single: {
            brackets: [
                { limit: 13810, rate: 0.0354 },
                { limit: 27630, rate: 0.0465 },
                { limit: 304170, rate: 0.053 },
                { limit: Infinity, rate: 0.0765 },
            ],
            standardDeduction: 12760,
        },
        married: {
            brackets: [
                { limit: 18420, rate: 0.0354 },
                { limit: 36840, rate: 0.0465 },
                { limit: 405550, rate: 0.053 },
                { limit: Infinity, rate: 0.0765 },
            ],
            standardDeduction: 23620,
        },
    },
    "D.C.": {
        single: {
            brackets: [
                { limit: 10000, rate: 0.04 },
                { limit: 40000, rate: 0.06 },
                { limit: 60000, rate: 0.065 },
                { limit: 250000, rate: 0.085 },
                { limit: 500000, rate: 0.0925 },
                { limit: 1000000, rate: 0.0975 },
                { limit: Infinity, rate: 0.1075 },
            ],
            standardDeduction: 13850,
        },
        married: {
            brackets: [
                { limit: 10000, rate: 0.04 },
                { limit: 40000, rate: 0.06 },
                { limit: 60000, rate: 0.065 },
                { limit: 250000, rate: 0.085 },
                { limit: 500000, rate: 0.0925 },
                { limit: 1000000, rate: 0.0975 },
                { limit: Infinity, rate: 0.1075 },
            ],
            standardDeduction: 27700,
        },
    },
    WY: {
        single: {
            brackets: [{ limit: Infinity, rate: 0 }],
            standardDeduction: 0,
        },
        married: {
            brackets: [{ limit: Infinity, rate: 0 }],
            standardDeduction: 0,
        },
    },
};
